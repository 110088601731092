import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/trend.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface SponsorTrendProp {
    className?: string;
}

const SponsorTrend: React.FC<SponsorTrendProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 1, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsorTrend, className)}
            title="趨勢科技"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    趨勢科技為網路資安全球領導廠商，逾三十年來致力「建立一個安全的資訊交換世界」，藉著資安專業、全球威脅研究以及持續不斷的創新，趨勢科技橫跨雲端、網路、裝置及端點的網路資安平台，隨時守護著全球
                    50 多萬家企業機構及 2.5 億以上的一般使用者。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="https://www.trendmicro.com/zh_tw/forHome/edu.html?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '趨勢科技 --- 了解更多',
                            });
                        }}
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(1601) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={1601}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default SponsorTrend;
