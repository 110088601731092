import { useState, useEffect } from 'react';
import { useCookie } from 'react-use';

import classnames from 'classnames';

import styles from './index.css';

export interface CookiePolicyProp {
    className?: string;
}

const CookiePolicy: React.FC<CookiePolicyProp> = ({ className }) => {
    const [value, update] = useCookie('policy');

    return !value ? (
        <div className={classnames(styles.cookiePolicy, className)}>
            <div className={styles.top}>
                1.
                為提供您更多優質的內容，本網站使用cookies分析技術。若繼續閱覽本網站內容，即表示您同意我們使用
                cookies，關於更多cookies資訊請閱讀我們的
                <a href="https://member.cwg.tw/privacy-policy" target="_blank" rel="noreferrer">
                    「隱私權政策」
                </a>
                。<br />
                2. 若您以Facebook臉書帳號登入參與【2021親子天下教育創新國際年會-永續X教育
                線上互動展】之積分抽獎活動者，即代表同意本活動之
                <a href="https://cplink.co/BTtAwJNI" target="_blank" rel="noreferrer">
                    「活動辦法說明」
                </a>
                。
            </div>
            <div
                className={styles.button}
                onClick={() => {
                    update(true);
                }}
                tabIndex={0}
                onKeyPress={() => {}}
                role="button"
            >
                我知道了
            </div>
        </div>
    ) : null;
};

export default CookiePolicy;
