// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__curved-angle___cZICL {\n    position: relative;\n    overflow: visible\n}\n.index__curved-angle___cZICL > svg {\n        position: absolute;\n        left: 0;\n        top: 0;\n        overflow: visible\n    }\n.index__curved-angle___cZICL > svg.index__right-top___15m_L {\n            -webkit-transform: rotate(180deg);\n                    transform: rotate(180deg);\n        }\n.index__curved-angle___cZICL > svg.index__right-bottom___19Ngw {\n            -webkit-transform: rotate(270deg);\n                    transform: rotate(270deg);\n        }\n.index__curved-angle___cZICL > svg.index__left-top___1p0qc {\n            -webkit-transform: rotate(90deg);\n                    transform: rotate(90deg);\n        }\n.index__curved-angle___cZICL > svg.index__left-bottom___5QSzF {\n        }\n.index__curved-angle___cZICL > svg path {\n            -webkit-transition: 0.3s ease;\n            transition: 0.3s ease;\n        }\n", "",{"version":3,"sources":["webpack://./src/components/atoms/CurvedAngle/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;AA2BJ;AAzBI;QACI,kBAAkB;QAClB,OAAO;QACP,MAAM;QACN;IAoBJ;AAlBI;YACI,iCAAyB;oBAAzB,yBAAyB;QAC7B;AAEA;YACI,iCAAyB;oBAAzB,yBAAyB;QAC7B;AAEA;YACI,gCAAwB;oBAAxB,wBAAwB;QAC5B;AAEA;QACA;AAEA;YACI,6BAAqB;YAArB,qBAAqB;QACzB","sourcesContent":[".curved-angle {\n    position: relative;\n    overflow: visible;\n\n    & > svg {\n        position: absolute;\n        left: 0;\n        top: 0;\n        overflow: visible;\n\n        &.right-top {\n            transform: rotate(180deg);\n        }\n\n        &.right-bottom {\n            transform: rotate(270deg);\n        }\n\n        &.left-top {\n            transform: rotate(90deg);\n        }\n\n        &.left-bottom {\n        }\n\n        & path {\n            transition: 0.3s ease;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"curved-angle": "index__curved-angle___cZICL",
	"curvedAngle": "index__curved-angle___cZICL",
	"right-top": "index__right-top___15m_L",
	"rightTop": "index__right-top___15m_L",
	"right-bottom": "index__right-bottom___19Ngw",
	"rightBottom": "index__right-bottom___19Ngw",
	"left-top": "index__left-top___1p0qc",
	"leftTop": "index__left-top___1p0qc",
	"left-bottom": "index__left-bottom___5QSzF",
	"leftBottom": "index__left-bottom___5QSzF"
};
export default ___CSS_LOADER_EXPORT___;
