import { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import ScrollContainer from 'react-indiana-drag-scroll';

import { useMedia } from 'utils/hooks';

import { useEvent } from 'models/user';

import ModalBaseLarge from 'components/organisms/ModalBaseLarge';
import CurvedButton from 'components/atoms/CurvedButton';
import ButtonPoint from 'components/atoms/ButtonPoint';
import ButtonShare from 'components/atoms/ButtonShare';

import { eventData } from 'data/events';
import type { EventData } from 'data/events/types';

import styles from './index.css';

type TimeType = 'live' | 'upcoming' | 'past';

const { SELF_HOST_ENDPOINT } = process.env;
export interface EventContentProp {
    className?: string;
}

const EventContent: React.FC<EventContentProp> = ({ className }) => {
    const { id = '3' } = useParams<{ id: string }>();
    const history = useHistory();

    const [data, setData] = useState<EventData | null>(null);
    const [pageActive, setPageActive] = useState(true);

    const [{ events }] = useEvent();

    const media = useMedia();

    const [subtitle, setSubtitle] = useState('');
    const [timeType, setTimeType] = useState<TimeType>('past');

    const [activeTab, setActiveTab] = useState<'' | 'en' | 'cn'>('');

    const routeInTown = useRouteMatch({
        path: '/town/events/:id',
        exact: true,
    });

    const getEventTimeType = useCallback((_data: EventData) => {
        if (_data) {
            const now = DateTime.now();
            const [monthStr, dayStr] = _data.date.split('/');
            const [startHourStr, startMinuteStr] = _data.startTime.split(':');
            const [endHourStr, endMinuteStr] = _data.endTime.split(':');

            const month = parseInt(monthStr, 10);
            const day = parseInt(dayStr, 10);
            const startHour = parseInt(startHourStr, 10);
            const startMinute = parseInt(startMinuteStr, 10);
            const endHour = parseInt(endHourStr, 10);
            const endMinute = parseInt(endMinuteStr, 10);

            const startDatetime = DateTime.fromObject(
                {
                    year: 2021,
                    month,
                    day,
                    hour: startHour,
                    minute: startMinute,
                },
                { zone: 'UTC+8' },
            );

            const endDatetime = DateTime.fromObject(
                {
                    year: 2021,
                    month,
                    day,
                    hour: endHour,
                    minute: endMinute,
                },
                { zone: 'UTC+8' },
            );

            if (now < startDatetime) {
                return 'upcoming';
            }

            if (now >= startDatetime && now <= endDatetime) {
                return 'live';
            }

            return 'past';
        }

        return 'past';
    }, []);

    const onClickQuestionare = useCallback(() => {
        if (data) {
            window.open(data.questionnaire, '_blank');

            window.dataLayer.push({
                event: 'clicklink',
                eventlable: `${data.title} 填寫問券`,
            });
        }
    }, [data]);

    useEffect(() => {
        if (!id || Object.keys(eventData).indexOf(id) === -1) {
            history.push('/');
        } else {
            const nextData = eventData[id];

            const nextSpeakers: string[] = [];
            nextData?.agenda.forEach(({ speaker }) => {
                speaker?.forEach(({ name }) => {
                    if (nextSpeakers.indexOf(name.split('（')[0]) === -1) {
                        nextSpeakers.push(name.split('（')[0]);
                    }
                });
            });

            // remove duplicate
            nextSpeakers.filter((item, pos) => {
                return nextSpeakers.indexOf(item) === pos;
            });

            const slicedSpeakers = nextSpeakers.slice(0, 4);

            setData(nextData);
            setSubtitle(`${slicedSpeakers.join('、')}${nextSpeakers.length > 4 ? '...' : ''}`);
            setTimeType(getEventTimeType(nextData));
        }
    }, []);

    useEffect(() => {
        if (data && data.youtubeEnId) {
            setActiveTab('en');
        } else {
            setActiveTab('');
        }
    }, [data]);

    return data ? (
        <ModalBaseLarge
            className={classnames(styles.eventContent, className)}
            childrenClassName={styles.modalContainer}
            onCloseFinish={() => {
                if (routeInTown) {
                    history.push('/town/events');
                } else {
                    history.push('/town');
                }
            }}
            active={pageActive}
            maskOpacity={0.6}
            hasCloseBtn={!!routeInTown}
        >
            <div className={styles.top}>
                <div className={styles.title}>{data.contentTitle || data.title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>

            {data.youtubeEnId && (
                <div className={styles.tabSection}>
                    <div className={styles.container}>
                        <div
                            className={classnames(
                                styles.tab,
                                styles.en,
                                activeTab === 'en' && styles.active,
                            )}
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => {}}
                            onClick={() => {
                                setActiveTab('en');
                            }}
                        >
                            現場直播
                        </div>
                        <div
                            className={classnames(
                                styles.tab,
                                styles.cn,
                                activeTab === 'cn' && styles.active,
                            )}
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => {}}
                            onClick={() => {
                                setActiveTab('cn');
                            }}
                        >
                            同步口譯(中)直播
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.videoSection}>
                {data.category === 'live' && (timeType === 'upcoming' || timeType === 'live') && (
                    <div className={styles.live}>
                        <div className={styles.video}>
                            <div className={styles.youtubeWrapper}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${
                                        activeTab === 'en' ? data.youtubeEnId : data.youtubeLiveId
                                    }`}
                                    title="YouTube live stream"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            {timeType === 'live' && events.indexOf(200 + parseInt(id, 10)) === -1 && (
                                <div className={styles.action}>
                                    <ButtonPoint
                                        className={styles.btn}
                                        points={500}
                                        text="觀看領取積分"
                                        eventId={200 + parseInt(id, 10)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.chat}>
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/live_chat?v=${
                                    activeTab === 'en' ? data.youtubeEnId : data.youtubeLiveId
                                }&embed_domain=${SELF_HOST_ENDPOINT?.replace(
                                    'https://',
                                    '',
                                )}&dark_theme=1`}
                                frameBorder="0"
                                title="YouTube live chat"
                            />
                        </div>
                    </div>
                )}
                {data.category === 'live' && timeType === 'past' && (
                    <div className={styles.video}>
                        <div className={styles.youtubeWrapper}>
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${
                                    activeTab === 'en' ? data.youtubeEnId : data.youtubeLiveId
                                }`}
                                title="YouTube live stream"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                        {events.indexOf(201 + parseInt(id, 10)) === -1 && (
                            <div className={styles.action}>
                                <ButtonPoint
                                    className={styles.btn}
                                    points={500}
                                    text="觀看領取積分"
                                    eventId={201 + parseInt(id, 10)}
                                />
                            </div>
                        )}
                    </div>
                )}

                {data.category !== 'live' && (timeType === 'upcoming' || !data.youtubeVideoId) && (
                    <div className={styles.empty}>節目首播尚未開始</div>
                )}
                {data.category !== 'live' && !(timeType === 'upcoming' || !data.youtubeVideoId) && (
                    <div className={styles.video}>
                        <div className={styles.youtubeWrapper}>
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${data.youtubeLiveId}`}
                                title="YouTube live stream"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                        {events.indexOf(2012 + parseInt(id, 10)) === -1 && (
                            <div className={styles.action}>
                                <ButtonPoint
                                    className={styles.btn}
                                    points={500}
                                    text="觀看領取積分"
                                    eventId={2012 + parseInt(id, 10)}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={styles.mainContent}>
                {media === 'desktop' && (
                    <div className={styles.imageWrapper}>
                        {data.speakerImages.map((src, index) => (
                            <div key={index} className={styles.image}>
                                <img src={src} alt="speaker" />
                            </div>
                        ))}
                    </div>
                )}
                <div className={styles.detail}>
                    <div className={styles.title}>{data.contentTitle || data.title}</div>
                    {data.sponsor && <div className={styles.sponsor}>{data.sponsor}</div>}
                    <div className={styles.intro}>
                        {data.note && (
                            <>
                                {data.note}
                                <br />
                                <br />
                            </>
                        )}
                        <>{data.intro}</>
                    </div>
                    {/* {media === 'phone' && (
                        <div className={styles.imageSlider}>
                            <Swiper slidesPerView={2} centeredSlides spaceBetween={24}>
                                {data.speakerImages.map((src, index) => (
                                    <SwiperSlide key={index} className={styles.slide}>
                                        <div key={src} className={styles.image}>
                                            <img src={src} alt="speaker" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )} */}
                    {media === 'phone' && (
                        <ScrollContainer nativeMobileScroll={false} className={styles.drag}>
                            <div
                                className={styles.imageFakeSlider}
                                style={{
                                    width: `calc(${data.speakerImages.length * 40 + 40}vw + ${
                                        data.speakerImages.length * 12
                                    }px + 48px + 17px)`,
                                }}
                            >
                                {data.speakerImages.map((src, index) => (
                                    <div key={src} className={styles.image}>
                                        <img src={src} alt="speaker" />
                                    </div>
                                ))}
                            </div>
                        </ScrollContainer>
                    )}
                    <div className={styles.action}>
                        <ButtonShare
                            className={styles.btn}
                            text="分享節目"
                            points={500}
                            eventId={id === '1' ? 209 : 2010 + parseInt(id, 10) - 2}
                            shareUrl={`${SELF_HOST_ENDPOINT}/events/${id}`}
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: `分享節目 ${data.title}`,
                                });
                            }}
                        />
                        {data.questionnaire && (
                            <ButtonPoint
                                className={styles.btn}
                                points={500}
                                text="填寫問券"
                                eventId={2019 + parseInt(id, 10)}
                                onClick={onClickQuestionare}
                            />
                        )}
                        <div className={styles.note}>累積積分，扭蛋領大獎</div>
                    </div>
                    <div className={styles.agenda}>
                        <div className={styles.title}>精彩議程</div>
                        <div className={styles.table}>
                            {data.agenda.map(({ title, startTime, endTime, speaker }, index) => (
                                <div key={index} className={styles.item}>
                                    <div className={styles.time}>
                                        {data.category === 'live'
                                            ? `${startTime}-${endTime}`
                                            : `${
                                                  ((parseInt(startTime.split(':')[0], 10) -
                                                      parseInt(endTime.split(':')[0])) *
                                                      60 +
                                                      parseInt(startTime.split(':')[1]) -
                                                      parseInt(endTime.split(':')[1])) *
                                                  -1
                                              }min`}
                                    </div>
                                    <div className={styles.detail}>
                                        <div className={styles.eventName}>{title}</div>
                                        {speaker && speaker.length > 0 && (
                                            <div className={styles.speakerWrapper}>
                                                {speaker?.map(({ name, intro }, index) => (
                                                    <div key={index} className={styles.speaker}>
                                                        <span className={styles.name}>{name}</span>
                                                        <span className={styles.intro}>
                                                            {intro}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <CurvedButton
                className={styles.backBtn}
                size="small"
                theme="gray"
                onClick={() => setPageActive(false)}
            >
                {routeInTown ? '返回' : '返回城市'}
            </CurvedButton>
        </ModalBaseLarge>
    ) : null;
};

export default EventContent;
