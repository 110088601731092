export const color = (colorCode: string) => {
    const { palette } = process.env;

    // @ts-ignore
    return palette[colorCode] || colorCode;
};

export const numberWithCommas = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
