import { createReducer } from '@reduxjs/toolkit';

import {
    setFirstVisit,
    setAssetsLoaded,
    setLoading,
    setHideUI,
    setEnteringView,
    startTransition,
    endTransition,
    setRedirectPath,
    clearRedirectPath,
    getRank,
    setSoundOn,
    setMapReady,
} from './actions';

import type { EnteringView, AssetType } from './types';

export interface State {
    firstVisit: boolean;
    loading: boolean;
    redirectPath: string;
    hideUI: boolean;
    assetsLoaded: Record<AssetType, boolean>;
    entering: {
        view: EnteringView;
    };
    transition: {
        active: boolean;
        nextPath: string | null;
    };
    rankDataList: any[];
    solveEvent: {
        progressType: 0 | 20 | 40 | 60 | 100;
        solve20: boolean;
        solve40: boolean;
        solve60: boolean;
        solve80: boolean;
        solve100: boolean;
    };
    soundOn: boolean;
    mapReady: boolean;
}

const initialState: State = {
    firstVisit: false,
    redirectPath: '',
    loading: true,
    hideUI: false,
    assetsLoaded: {
        main: false,
    },
    entering: {
        view: 'loading',
    },
    transition: {
        active: false,
        nextPath: null,
    },
    rankDataList: [],
    solveEvent: {
        progressType: 0,
        solve20: false,
        solve40: false,
        solve60: false,
        solve80: false,
        solve100: false,
    },
    soundOn: true,
    mapReady: false,
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setFirstVisit, (state, action) => {
            state.firstVisit = action.payload;
        })
        .addCase(setAssetsLoaded, (state, action) => {
            state.assetsLoaded[action.payload.type] = true;
        })
        .addCase(setLoading, (state, action) => {
            state.loading = action.payload;
        })
        .addCase(setHideUI, (state, action) => {
            state.hideUI = action.payload;
        })
        .addCase(setEnteringView, (state, action) => {
            state.entering.view = action.payload;
        })
        .addCase(startTransition, (state, action) => {
            state.transition.active = true;
            state.loading = true;
            state.transition.nextPath = action.payload.nextPath || null;
        })
        .addCase(endTransition, (state) => {
            state.transition.active = false;
            state.transition.nextPath = null;
        })
        .addCase(setRedirectPath, (state, action) => {
            state.redirectPath = action.payload;
        })
        .addCase(clearRedirectPath, (state) => {
            state.redirectPath = '';
        })
        .addCase(getRank.fulfilled, (state, action) => {
            state.rankDataList = action.payload.ranks;
        })
        .addCase(setSoundOn, (state, action) => {
            state.soundOn = action.payload;
        })
        .addCase(setMapReady, (state, action) => {
            state.mapReady = action.payload;
        });
});

export default reducer;
