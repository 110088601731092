import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import { useOpenBuilding } from 'utils/hooks';

import image1 from 'images/mainTown/launch-now-1.png';
import image2 from 'images/mainTown/launch-now-2.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface LaunchNowProp {
    className?: string;
}

const LaunchNow: React.FC<LaunchNowProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 7);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.launchNow, className)}
            title="翻轉教育發射基地"
            enTitle="Launch Now"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image1} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    2021年11月「翻轉教育」以全新的面貌迎接新的讀者
                    我們深信：「沒有不會教的老師，也沒有教不會的孩子」，只有「還在摸索方法的老師」和「等待被照亮的孩子」。
                    <br />
                    <br />
                    全新的會員服務、教學資源下載、專欄作家觀點、深度專題、社群小聚分享……，幫助新手老師節省摸索的冤枉路；幫助資深老師分享所學，激盪更多教學火花；匯集所有相信教育改變的力量，為孩子一起共創更有希望的每一天。
                </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image2} alt="2" />
                </div>
                <div className={articleStyles.content}>
                    「翻轉教育」網站，以「連結改變教育的力量」為創始初衷，致力成為「華文圈最大的創新教學專業與資源分享平台」，作為幫助第一線教育工作者快速回應時代變化，拓展新知、實踐所知的最佳指南。
                </div>
                <br />
                <br />
                <a
                    className={articleStyles.link}
                    href="https://flipedu.parenting.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        window.dataLayer.push({
                            event: 'clicklink',
                            eventlable: '翻轉教育發射基地 --- 前往翻轉教育網站',
                        });
                    }}
                >
                    前往翻轉教育網站
                </a>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.title}>SDGs 永續教育 X 108課綱教學設計資源包</div>
                <div className={articleStyles.content}>
                    當永續成為未來學習的重要目標，「學習」就不再只是解答課本裡的題目，而是學著解決真實世界的難題。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="https://edu.parenting.com.tw/event/flipedu_teachingresources-478?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '翻轉教育發射基地 --- 前往下載教案資源包',
                            });
                        }}
                    >
                        前往下載教案資源包
                    </a>
                </div>
            </div>
            {events.indexOf(701) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={500}
                        text="領取積分"
                        eventId={701}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default LaunchNow;
