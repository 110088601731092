import { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import Lottie from 'lottie-react';
import { useHistory } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { useTransition, useLoading } from 'models/mainSite';

import type { LottieRefCurrentProps } from 'lottie-react';

import startLottie from './transition_one.json';
import endLottie from './transition_two.json';
import logoLottie from './logo.json';

import styles from './index.css';

export interface TransitionLayout {
    className?: string;
    active?: boolean;
    loading?: boolean;
    onStartEnd?: () => void;
    onComplete?: () => void;
}

export const TransitionLayout: React.FC<TransitionLayout> = ({
    className,
    active,
    loading,
    onStartEnd = () => {},
    onComplete = () => {},
}) => {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    const startRef = useRef<LottieRefCurrentProps | null>(null);
    const endRef = useRef<LottieRefCurrentProps | null>(null);
    const logoRef = useRef<LottieRefCurrentProps | null>(null);

    const [state, setState] = useState('end');
    const [showLoading, setShowLoading] = useState(false);
    const loadingShowingTimeout = useRef<number | null>(null);

    const onStartComplete = useCallback(() => {
        if (loading) {
            setState('loading');
        } else {
            setState('end');
        }

        onStartEnd();
    }, [loading]);

    const onEndComplete = useCallback(() => {
        setState('complete');
        onComplete();
    }, [onComplete]);

    useEffect(() => {
        return () => {
            if (loadingShowingTimeout.current) {
                window.clearTimeout(loadingShowingTimeout.current);
                loadingShowingTimeout.current = null;
            }
        };
    }, []);

    useEffect(() => {}, [width, height]);

    useEffect(() => {
        if (active) {
            setState('start');
        }
    }, [active]);

    useEffect(() => {
        if (active && !loading && state === 'loading') {
            setState('end');
        }
    }, [active, state, loading]);

    useEffect(() => {
        switch (state) {
            case 'start':
                if (startRef.current) {
                    startRef.current.goToAndPlay(0);

                    if (loadingShowingTimeout.current) {
                        window.clearTimeout(loadingShowingTimeout.current);
                        loadingShowingTimeout.current = null;
                    }

                    setShowLoading(false);
                    loadingShowingTimeout.current = window.setTimeout(() => {
                        if (logoRef.current) {
                            logoRef.current.goToAndPlay(0);
                        }

                        setShowLoading(true);
                        loadingShowingTimeout.current = null;
                    }, 650);
                }
                break;
            case 'loading':
                break;
            case 'end':
                if (logoRef.current && endRef.current) {
                    setShowLoading(false);

                    logoRef.current.stop();
                    endRef.current.goToAndPlay(0);
                }
                break;
            default:
                break;
        }
    }, [state]);

    return (
        <div ref={ref} className={classnames(styles.transition, !active && styles.hide, className)}>
            <div className={classnames(styles.loading, !showLoading && styles.hide)}>
                <Lottie
                    lottieRef={logoRef}
                    className={styles.logo}
                    animationData={logoLottie}
                    autoplay={false}
                />
            </div>
            <div
                className={classnames(state !== 'start' && styles.hide, styles.start)}
                style={{
                    transform: `scale(1, ${(height / 1080) * 1920})`,
                }}
            >
                <Lottie
                    lottieRef={startRef}
                    animationData={startLottie}
                    autoplay={false}
                    loop={false}
                    onComplete={onStartComplete}
                />
            </div>
            <div
                className={classnames(state !== 'end' && styles.hide, styles.end)}
                style={{
                    transform: `scale(1, ${(height / 1080) * 1920})`,
                }}
            >
                <Lottie
                    lottieRef={endRef}
                    animationData={endLottie}
                    autoplay={false}
                    loop={false}
                    onComplete={onEndComplete}
                />
            </div>
        </div>
    );
};

export interface Transition {
    className?: string;
}

export const Transition: React.FC<Transition> = ({ className }) => {
    const [{ active, nextPath }, { endTransition }] = useTransition();
    const [loading] = useLoading();
    const history = useHistory();

    return (
        <TransitionLayout
            className={className}
            active={active}
            loading={loading}
            onStartEnd={() => {
                if (nextPath) {
                    history.push(nextPath);
                }
            }}
            onComplete={() => {
                endTransition();
            }}
        />
    );
};
export default Transition;
