import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useTransition } from 'models/mainSite';
import { useUserData } from 'models/user';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import ArrowBtn from 'components/atoms/ArrowBtn';
import CurvedButton from 'components/atoms/CurvedButton';

import recommand from 'images/icons/recommand.png';

import cloudgate from 'images/common/recommand-cloudgate.png';
import futureLive from 'images/common/recommand-future-live.png';
import goodNeighbor from 'images/common/recommand-good-neighbor.png';
import heyson from 'images/common/recommand-heyson.png';
import hsbc from 'images/common/recommand-hsbc.png';
import impactHub from 'images/common/recommand-impact-hub.png';
import kumon from 'images/common/recommand-kumon.png';
import lunchNow from 'images/common/recommand-lunch-now.png';
import popWow from 'images/common/recommand-pop-wow.png';
import seasonArt from 'images/common/recommand-season-art.png';
import speedup from 'images/common/recommand-speed-up.png';
import startup from 'images/common/recommand-startup.png';
import startupIntro from 'images/common/recommend-startup-intro.png';

import styles from './index.css';

const buildingData = {
    cloudgate: {
        src: cloudgate,
        name: '雲門舞集舞蹈教室',
        path: '/town/sponsor/cgds',
    },
    futureLive: {
        src: futureLive,
        name: '永續未來媒體中心 Future LIVE',
        path: '/future-live',
    },
    goodNeighbor: {
        src: goodNeighbor,
        name: '好鄰居超人學校',
        path: '/town/sponsor/goodneighbors',
    },
    heyson: {
        src: heyson,
        name: '黑松教育基金會',
        path: '/town/sponsor/heysong',
    },
    hsbc: {
        src: hsbc,
        name: 'HSBC 滙豐',
        path: '/hsbc',
    },
    impactHub: {
        src: impactHub,
        name: '續想家 Taiwan2030',
        path: '/impact-hub',
    },
    kumon: {
        src: kumon,
        name: 'KUMON公文式教育',
        path: '/town/sponsor/kumon',
    },
    lunchNow: {
        src: lunchNow,
        name: '翻轉教育發射基地 Launch Now',
        path: '/town/launch-now',
    },
    popWow: {
        src: popWow,
        name: '未來產生器 Pop Wow!',
        path: '/pop-wow',
    },
    seasonArt: {
        src: seasonArt,
        name: '四季藝術',
        path: '/season-art',
    },
    speedup: {
        src: speedup,
        name: '未來加速會展中心 Speed up',
        path: '/town/events',
    },
    startup: {
        src: startup,
        name: 'Startup Island TAIWAN',
        path: '/startup',
    },
    startupIntro: {
        src: startupIntro,
        name: 'Startup Island TAIWAN 介紹',
        path: '/startup/intro',
    },
};

const characterRecommandMap = {
    4: ['speedup', 'popWow', 'impactHub', 'futureLive', 'seasonArt', 'goodNeighbor'],
    1: ['startupIntro', 'startup', 'speedup', 'popWow', 'impactHub', 'futureLive'],
    3: ['speedup', 'popWow', 'futureLive', 'lunchNow', 'seasonArt', 'cloudgate'],
    5: ['speedup', 'popWow', 'impactHub', 'futureLive', 'hsbc', 'heyson'],
    2: ['speedup', 'popWow', 'futureLive', 'impactHub', 'hsbc', 'kumon'],
};

export interface ModalRecommandLayoutProp {
    className?: string;
    characterType: 1 | 2 | 3 | 4 | 5;
    onCloseFinish: () => void;
}

export const ModalRecommandLayout: React.FC<ModalRecommandLayoutProp> = ({
    className,
    characterType,
    onCloseFinish = () => {},
}) => {
    const history = useHistory();
    const [, { startTransition }] = useTransition();

    const [active, setActive] = useState(true);
    const [page, setPage] = useState(1);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalRecommand, className)}
            active={active}
            icon={recommand}
            title="推薦參觀"
            bottomBtn
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.intro}>
                <div className={styles.image}>
                    <img
                        src={buildingData[characterRecommandMap[characterType][page - 1]].src}
                        alt="building"
                    />
                </div>
                <CurvedButton
                    className={styles.btn}
                    childrenClassName={styles.children}
                    size="xs"
                    theme="blue"
                    onClick={() => {
                        const nowTown = history.location.pathname.split('/')[1];

                        const nextPath =
                            buildingData[characterRecommandMap[characterType][page - 1]].path;
                        const nextTown = nextPath.split('/')[1];

                        if (nowTown === nextTown) {
                            history.push(nextPath);
                        } else {
                            startTransition({ nextPath });
                        }

                        setActive(false);
                    }}
                >
                    前往
                </CurvedButton>
                <div className={styles.name}>
                    {buildingData[characterRecommandMap[characterType][page - 1]].name}
                </div>
            </div>
            <div className={styles.pagination}>
                {Array.from(
                    { length: characterRecommandMap[characterType].length },
                    (_, i) => i + 1,
                ).map((pageIndex) => (
                    <div
                        key={pageIndex}
                        className={classnames(styles.page, pageIndex <= page && styles.active)}
                        onClick={() => {
                            setPage(pageIndex);
                        }}
                        onKeyPress={() => {}}
                        role="presentation"
                    />
                ))}
            </div>
            <ArrowBtn
                className={classnames(styles.action, styles.prev)}
                type="prev"
                disabled={page === 1}
                onClick={() => {
                    setPage(page - 1);
                }}
            />
            <ArrowBtn
                className={classnames(styles.action, styles.next)}
                type="next"
                disabled={page === characterRecommandMap[characterType].length}
                onClick={() => {
                    setPage(page + 1);
                }}
            />
        </ModalBaseCenter>
    );
};

export interface ModalRecommandProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalRecommand: React.FC<ModalRecommandProp> = ({ className, onCloseFinish = () => {} }) => {
    const [{ characterType }] = useUserData();

    return (
        <ModalRecommandLayout
            className={classnames(styles.modalRecommand, className)}
            characterType={characterType}
            onCloseFinish={onCloseFinish}
        />
    );
};

export default ModalRecommand;
