import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from 'models/auth/reducer';
import mainSiteReducer from 'models/mainSite/reducer';
import userReducer from 'models/user/reducer';
import modalReducer from 'models/modal/reducer';
import mapReducer from 'models/map/reducer';
import messageReducer from 'models/messages/reducer';

import type { History } from 'history';

import type { State as AuthState } from 'models/auth/reducer';
import type { State as MainSiteState } from 'models/mainSite/reducer';
import type { State as UserState } from 'models/user/reducer';
import type { State as ModalState } from 'models/modal/reducer';
import type { State as MapState } from 'models/map/reducer';
import type { State as MessageState } from 'models/messages/reducer';

export interface State {
    auth: AuthState;
    mainSite: MainSiteState;
    user: UserState;
    modal: ModalState;
    map: MapState;
    message: MessageState;
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        mainSite: mainSiteReducer,
        user: userReducer,
        modal: modalReducer,
        map: mapReducer,
        message: messageReducer,
    });

export default createRootReducer;
