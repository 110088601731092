import img1 from 'images/events/4-1.png';
import img2 from 'images/events/4-2.png';
import img3 from 'images/events/4-3.png';
import img4 from 'images/events/4-4.png';
import img5 from 'images/events/4-5.png';
import img6 from 'images/events/4-6.png';
import img7 from 'images/events/4-7.png';
import img8 from 'images/events/4-8.png';
import img9 from 'images/events/4-9.png';

import type { EventData } from './types';

const data: EventData = {
    id: 4,
    category: 'live',
    title: '面向世界，迎向雙語教育與混成教學',
    date: '12/4',
    startTime: '13:30',
    endTime: '16:35',
    intro: '一場疫情，驅動教育現場全面轉型，全世界都在摸索教育的下一步。而台灣近年來最受親師關注的教育關鍵字莫過於「雙語教育」及「混成教學」，迎接下個教育轉型的關鍵十年，我們又會遇到那些全新的可能與挑戰？讓我們一同借鏡國際，在地實踐，讓更好的教育來得更快！',
    youtubeEnId: '7b6aIb7unHM',
    youtubeLiveId: 'jTMQahIEdag',
    youtubeVideoId: 'jTMQahIEdag',
    sponsor: '',
    questionnaire:
        'https://forms.gle/8iHoxdGdn9KQQzc36?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    agenda: [
        {
            title: '開場',
            startTime: '13:30',
            endTime: '13:35',
        },
        {
            title: '專題與談一：培育雙語力，與國際接軌',
            startTime: '13:35',
            endTime: '14:05',
            speaker: [
                {
                    name: '阿滴',
                    intro: '阿滴英文創辦人、開新英文共同創辦人',
                },
                {
                    name: 'Michael',
                    intro: '開新英文共同創辦人',
                },
            ],
        },
        {
            title: '專題與談二：雙語公校加速進場，雙語教育的在地實踐',
            startTime: '14:05',
            endTime: '14:55',
            speaker: [
                {
                    name: '林子斌',
                    intro: '臺灣師範大學副教務長兼教學發展中心主任',
                },
                {
                    name: '蔡淇華',
                    intro: '臺中市惠文高中圖書館主任',
                },
                {
                    name: '林淑媛',
                    intro: '臺北市興雅國中退休教師',
                },
            ],
        },
        {
            title: '中場休息',
            startTime: '14:55',
            endTime: '15:10',
        },
        {
            title: '引言',
            startTime: '15:10',
            endTime: '15:15',
        },
        {
            title: '專題演講：三百名老師開創一萬堂課，成立英國第一所線上學校',
            startTime: '15:15',
            endTime: '15:45',
            speaker: [
                {
                    name: '湯姆・羅斯（Tom Rose）',
                    intro: '英國 Oak National Academy 課程計畫總監',
                },
            ],
        },
        {
            title: '專題與談三：學生為中心，台灣老師打造個人化線上教學課',
            startTime: '15:45',
            endTime: '16:35',
            speaker: [
                {
                    name: '葉士昇',
                    intro: '高雄市民權國小教師',
                },
                {
                    name: '顏椀君',
                    intro: '新北市板橋高中教師',
                },
                {
                    name: '宋睿偉',
                    intro: '新竹市光武國中教師',
                },
            ],
        },
    ],
    speakerImages: [img1, img2, img3, img4, img5, img6, img7, img8, img9],
};

export default data;
