// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__button-round___2pyZ- {\n    display: -webkit-inline-box;\n    display: inline-flex;\n    background-color: #363567;\n    border-radius: 15px;\n    font-size: 12px;\n    padding: 16px 25px 18px;\n    color: #FDF7EC\n}\n\n@media (hover: hover) {\n\n    .index__button-round___2pyZ- {\n        cursor: pointer\n}\n\n        .index__button-round___2pyZ-:hover {\n            background-color: #405BFB;\n        }\n    }\n", "",{"version":3,"sources":["webpack://./src/components/atoms/ButtonRound/index.css"],"names":[],"mappings":"AAAA;IACI,2BAAoB;IAApB,oBAAoB;IACpB,yBAAuC;IACvC,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB;AASJ;;AAPI;;IARJ;QASQ;AAMR;;QAJQ;YACI,yBAAuC;QAC3C;IACJ","sourcesContent":[".button-round {\n    display: inline-flex;\n    background-color: var(--primary-dark-3);\n    border-radius: 15px;\n    font-size: 12px;\n    padding: 16px 25px 18px;\n    color: var(--primary-light);\n\n    @media (hover: hover) {\n        cursor: pointer;\n\n        &:hover {\n            background-color: var(--secondary-blue);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-round": "index__button-round___2pyZ-",
	"buttonRound": "index__button-round___2pyZ-"
};
export default ___CSS_LOADER_EXPORT___;
