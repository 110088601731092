import classnames from 'classnames';
import { useState, useEffect } from 'react';

import { useModal } from 'models/modal';
import { useMessages } from 'models/messages';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import ButtonPoint from 'components/atoms/ButtonPoint';

import prizeData from 'data/prize';
import { characterData } from 'data/character';

import styles from './index.css';

export interface ModalGiftDetailProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalGiftDetail: React.FC<ModalGiftDetailProp> = ({
    className,
    onCloseFinish = () => {},
}) => {
    const [, { sendMessage }] = useMessages();

    const [active, setActive] = useState(true);
    const [value, setValue] = useState('');

    return (
        <ModalBaseCenter
            className={classnames(styles.modalGiftDetail, className)}
            active={active}
            containerClass={styles.modalContainer}
            childrenClassName={styles.children}
            onCloseFinish={onCloseFinish}
            title="訊息留言"
            bottomBtn
        >
            <div className={styles.content}>
                <div className={styles.label}>訊息輸入區</div>
                <textarea
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    placeholder="輸入我的訊息留言"
                >
                    訊息輸入區
                </textarea>

                <ButtonPoint
                    className={classnames(styles.btn, !value && styles.disabled)}
                    points={300}
                    text="送出留言訊息"
                    sendEvent={false}
                    needIcon
                    onClick={() => {
                        sendMessage(value);
                        setActive(false);
                    }}
                />
            </div>
        </ModalBaseCenter>
    );
};

export default ModalGiftDetail;
