import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useUserData, useSolveEvent } from 'models/user';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';
import ButtonPoint from 'components/atoms/ButtonPoint';
import ButtonShare from 'components/atoms/ButtonShare';

import { characterData, themeData } from 'data/character';

import main from 'images/mainTown/modal-good-20.png';

import articleStyles from 'layouts/articleStyles.css';
import styles from './index.css';

const { SELF_HOST_ENDPOINT } = process.env;

export interface ModalRecommandLayoutProp {
    className?: string;
    characterType: number;
    name: string;
    giftCount: number;
    usedTicketCount: number;
    userId: string;
    points: number;
    onCloseFinish: () => void;
}

export const ModalProfileLayout: React.FC<ModalRecommandLayoutProp> = ({
    className,
    userId,
    characterType = 1,
    name = 'name',
    giftCount = 0,
    usedTicketCount = 0,
    points = 0,
    onCloseFinish = () => {},
}) => {
    const [active, setActive] = useState(true);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalProfile, className)}
            active={active}
            title={`${
                characterType < 2800
                    ? characterData[characterType].name
                    : themeData[characterType].name
            } ${name}`}
            subtitle="持續探索城市，尋找散落在各處的永續積分吧！"
            bottomBtn
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.intro}>
                <div className={styles.avatar}>
                    <img
                        src={
                            characterType < 2800
                                ? characterData[characterType].avatar
                                : themeData[characterType].avatar
                        }
                        alt={`character${characterData[characterType]}.id`}
                    />
                </div>
                <div className={styles.data}>
                    <div className={styles.item}>背包禮物數：{giftCount}</div>
                    {/* <div className={styles.item}>兌換過的扭蛋數：{usedTicketCount}</div> */}
                </div>
            </div>
            <div className={styles.action}>
                <div className={styles.btnWrapper}>
                    <ButtonShare
                        className={styles.btn}
                        text="角色分享"
                        points={500}
                        eventId={99902}
                        shareUrl={`${SELF_HOST_ENDPOINT}/share/${characterType}/${points}`}
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '角色分享',
                            });
                        }}
                    />
                </div>
                <div className={styles.btnWrapper}>
                    <ButtonPoint
                        className={styles.btn}
                        points={1000}
                        text="註冊成為親子天下會員"
                        eventId={99901}
                        msg="註冊成功"
                        onClick={() => {
                            window.open(
                                'https://www.parenting.com.tw/login/?srctype=P_conference&targetUrl=http%3A%2F%2Fconference.parenting.com.tw&utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
                                '_blank',
                            );
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '註冊成為親子天下會員',
                            });
                        }}
                    />
                </div>
                <div className={styles.note}>
                    成為親子天下的一員，
                    <br />
                    獲得第一手 #教育元宇宙的所有資訊！
                </div>
            </div>
        </ModalBaseCenter>
    );
};

export interface ModalRecommandProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalProfile: React.FC<ModalRecommandProp> = ({ className, onCloseFinish = () => {} }) => {
    const [{ characterType, activeTheme, name, gifts, redeemPoints, userId, totalPoints }] =
        useUserData();

    return (
        <ModalProfileLayout
            className={classnames(styles.modalRecommand, className)}
            userId={userId}
            characterType={activeTheme > 2800 ? activeTheme : characterType}
            name={name}
            giftCount={gifts.length}
            usedTicketCount={redeemPoints.length}
            points={totalPoints}
            onCloseFinish={onCloseFinish}
        />
    );
};

export default ModalProfile;
