import classnames from 'classnames';
import { useState, useEffect } from 'react';

import ModalBaseLarge from 'components/organisms/ModalBaseLarge';
import CurvedButton from 'components/atoms/CurvedButton';

import { useModal } from 'models/modal';

import Info from 'images/icons/item-info.inline.svg';

import prizeData, { physicalIdList, couponIdList, otherIdList } from 'data/prize';

import styles from './index.css';

export interface ModalGiftListProp {
    className?: string;
    onCloseFinish?: () => void;
}

const ModalGiftList: React.FC<ModalGiftListProp> = ({ className, onCloseFinish = () => {} }) => {
    const [activeTab, setActiveTab] = useState<'physical' | 'coupon' | 'other'>('other');
    const [active, setActive] = useState(true);
    const [, { openModal }] = useModal();

    return (
        <ModalBaseLarge
            active={active}
            className={classnames(styles.modalGiftList, className)}
            hasCloseBtn={false}
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.content}>
                <div className={styles.titleGroup}>
                    <div className={styles.title}>扭轉獎勵</div>
                    {/* <div className={styles.subTilte}>記得填寫收件資訊唷！</div> */}
                </div>
                {/* <div className={styles.tabGroup}>
                    <div
                        className={classnames(
                            styles.tab,
                            activeTab === 'physical' && styles.active,
                        )}
                        onClick={() => {
                            setActiveTab('physical');
                        }}
                    >
                        <div className={styles.name}>實體獎勵</div>
                        <div className={styles.note}>21 項, 共 534 名</div>
                    </div>

                    <div
                        className={classnames(styles.tab, activeTab === 'coupon' && styles.active)}
                        onClick={() => {
                            setActiveTab('coupon');
                        }}
                    >
                        <div className={styles.name}>線上獎勵</div>
                        <div className={styles.note}>3 項, 共 6,500 名</div>
                    </div>
                    <div
                        className={classnames(styles.tab, activeTab === 'other' && styles.active)}
                        onClick={() => {
                            setActiveTab('other');
                        }}
                    >
                        <div className={styles.name}>年會獎勵</div>
                        <div className={styles.note}>4 項</div>
                    </div>
                </div> */}

                <div className={styles.container}>
                    {activeTab === 'physical' &&
                        physicalIdList.map((id) => {
                            const giftData = prizeData[id];

                            return (
                                <div
                                    key={id}
                                    className={classnames(styles.item)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={() => {}}
                                    onClick={() => {
                                        openModal({
                                            type: 'giftDetail',
                                            data: {
                                                giftId: giftData.giftId,
                                                list: true,
                                                // themeId,
                                            },
                                        });
                                    }}
                                >
                                    <Info className={styles.info} onClick={() => {}} />
                                    <div className={styles.image}>
                                        <img src={giftData.image} alt="avatar" />
                                    </div>
                                    <div className={styles.name}>{giftData.name}</div>
                                    {giftData.amount && (
                                        <div className={styles.count}>{giftData.amount}名</div>
                                    )}
                                </div>
                            );
                        })}
                    {activeTab === 'coupon' &&
                        couponIdList.map((id) => {
                            const giftData = prizeData[id];

                            return (
                                <div
                                    key={id}
                                    className={classnames(styles.item)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={() => {}}
                                    onClick={() => {
                                        openModal({
                                            type: 'giftDetail',
                                            data: {
                                                giftId: giftData.giftId,
                                                list: true,
                                                // themeId,
                                            },
                                        });
                                    }}
                                >
                                    <Info
                                        className={styles.info}
                                        onClick={() => {
                                            // openModal({
                                            //     type: 'giftDetail',
                                            //     data: {
                                            //         giftId,
                                            //         themeId,
                                            //     },
                                            // });
                                        }}
                                    />
                                    <div className={styles.image}>
                                        <img src={giftData.image} alt="avatar" />
                                    </div>
                                    <div className={styles.name}>{giftData.name}</div>
                                    {giftData.amount && (
                                        <div className={styles.count}>{giftData.amount}名</div>
                                    )}
                                </div>
                            );
                        })}
                    {activeTab === 'other' &&
                        otherIdList.map((id) => {
                            const giftData = prizeData[id];

                            return (
                                <div
                                    key={id}
                                    className={classnames(styles.item)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={() => {}}
                                    onClick={() => {
                                        openModal({
                                            type: 'giftDetail',
                                            data: {
                                                giftId: giftData.giftId,
                                                list: true,
                                                // themeId,
                                            },
                                        });
                                    }}
                                >
                                    <Info
                                        className={styles.info}
                                        onClick={() => {
                                            // openModal({
                                            //     type: 'giftDetail',
                                            //     data: {
                                            //         giftId,
                                            //         themeId,
                                            //     },
                                            // });
                                        }}
                                    />
                                    <div className={styles.image}>
                                        <img src={giftData.image} alt="avatar" />
                                    </div>
                                    <div className={styles.name}>{giftData.name}</div>
                                    {/* {giftData.amount && (
                                        <div className={styles.count}>{giftData.amount}名</div>
                                    )} */}
                                </div>
                            );
                        })}
                </div>
            </div>
            <CurvedButton
                className={styles.backBtn}
                theme="gray"
                size="small"
                onClick={() => {
                    setActive(false);
                }}
            >
                返回
            </CurvedButton>
        </ModalBaseLarge>
    );
};

export default ModalGiftList;
