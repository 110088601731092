import { useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { useClickAway, useTimeout } from 'react-use';
import { isWindows } from 'react-device-detect';
import { useMedia } from 'utils/hooks';
import useSound from 'use-sound';

import { useSoundOn } from 'models/mainSite';

import CurvedContainer from 'components/atoms/CurvedContainer';
import CurvedButton from 'components/atoms/CurvedButton';

import modalSound from 'audio/modal.mp3';

import styles from './index.css';

export interface ModalBaseCenterProp {
    className?: string;
    topClassName?: string;
    containerClass?: string;
    childrenClassName?: string;
    active?: boolean;
    title?: string;
    subtitle?: string;
    topWithoutPaddingBottom?: boolean;
    icon?: string;
    bottomBtn?: boolean;
    buttonText?: string;
    onCloseFinish?: () => void;
    canClose?: boolean;
    mobileAlignTop?: boolean;
}

const ModalBaseCenter: React.FC<ModalBaseCenterProp> = ({
    className,
    topClassName,
    containerClass,
    childrenClassName,
    title,
    subtitle: Subtitle,
    topWithoutPaddingBottom = false,
    icon,
    active = true,
    bottomBtn = false,
    buttonText = '返回',
    onCloseFinish,
    children,
    canClose = true,
    mobileAlignTop = false,
}) => {
    const [soundOn] = useSoundOn();

    const openTimeline = useRef<gsap.core.Timeline | null>(null);
    const closeTimeline = useRef<gsap.core.Timeline | null>(null);
    const media = useMedia();
    const [isReady, cancel] = useTimeout(1000);

    const modalRef = useRef<HTMLDivElement>(null);
    const maskRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useSound(modalSound, {
        preload: true,
        autoplay: soundOn,
    });

    const setup = useCallback(() => {
        openTimeline.current = gsap
            .timeline({
                paused: true,
            })
            .timeScale(1.5)
            .to(maskRef.current, { opacity: 0.9, delay: 0.5 })
            .to(containerRef.current, { opacity: 1, translateY: 0, delay: 0.5 }, '<');

        closeTimeline.current = gsap
            .timeline({
                paused: true,
                onComplete: onCloseFinish,
            })
            .timeScale(1.5)
            .to(maskRef.current, { opacity: 0 })
            .to(containerRef.current, { opacity: 0, translateY: '80px' }, '<');
    }, []);

    const destroy = useCallback(() => {
        if (openTimeline.current) {
            openTimeline.current.kill();
            openTimeline.current = null;
        }

        if (closeTimeline.current) {
            closeTimeline.current.kill();
            closeTimeline.current = null;
        }
    }, []);

    const open = useCallback(() => {
        if (openTimeline.current) {
            openTimeline.current.play();
        }
    }, []);

    const close = useCallback(() => {
        if (closeTimeline.current) {
            closeTimeline.current.play();
        }
    }, []);

    useClickAway(modalRef, () => {
        if (canClose && isReady) {
            close();
        }
    });

    useEffect(() => {
        setup();

        return destroy;
    }, []);

    useEffect(() => {
        if (active) {
            open();
        } else {
            close();
        }
    }, [active]);

    return (
        <div
            className={classnames(
                styles.modalBaseCenter,
                mobileAlignTop && styles.mobileAlignTop,
                className,
            )}
        >
            <div ref={maskRef} className={styles.mask} role="presentation" />
            <div
                ref={containerRef}
                className={classnames(
                    styles.containerWrapper,
                    bottomBtn && styles.withBottomBtn,
                    isWindows && styles.customScrollBar,
                )}
            >
                <div ref={modalRef}>
                    <CurvedContainer
                        className={classnames(styles.modal, containerClass)}
                        childrenClassName={childrenClassName}
                        borderColor="--primary-dark-1"
                        filledColor="--primary-dark-1"
                        size={media === 'desktop' ? 'lg' : 'md'}
                    >
                        {(title || icon) && (
                            <div
                                className={classnames(
                                    styles.top,
                                    topWithoutPaddingBottom && styles.bottomNone && topClassName,
                                )}
                            >
                                {icon && (
                                    <span className={styles.icon}>
                                        <img src={icon} alt="icon" />
                                    </span>
                                )}
                                {title && <span className={styles.title}>{title}</span>}
                                {Subtitle && (
                                    <span
                                        className={classnames(
                                            styles.subtitle,
                                            icon && styles.withIcon,
                                        )}
                                    >
                                        {typeof Subtitle === 'string' ? Subtitle : <Subtitle />}
                                    </span>
                                )}
                            </div>
                        )}
                        {children}
                    </CurvedContainer>
                    {bottomBtn && (
                        <CurvedButton
                            className={styles.bottomBtn}
                            size="small"
                            theme="gray"
                            onClick={(event) => {
                                close();
                                event.stopPropagation();
                            }}
                        >
                            {buttonText}
                        </CurvedButton>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalBaseCenter;
