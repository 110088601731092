import useRedux from 'utils/hooks/useRedux';

import { openModal, closeModal, openNotification, closeNotification } from './actions';

export const useModal = () =>
    useRedux((state) => state.modal.modal, {
        openModal,
        closeModal,
    });

export const useNotification = () =>
    useRedux((state) => state.modal.notification, {
        openNotification,
        closeNotification,
    });
