import { lazy } from 'react';

import eventsRoutes from './Events';
import twistNowRoutes from './TwistNow';
import launchNowRoutes from './LaunchNow';
import PlayForPowerRoutes from './PlayForPower';
import LineRoutes from './Line';
import TrendRoutes from './Trend';
import KumonRoutes from './Kumon';
import Sponsor5Routes from './Sponsor5';
import Sponsor6Routes from './Sponsor6';
import Sponsor7Routes from './Sponsor7';
import Sponsor1Routes from './Sponsor1';
import Sponsor8Routes from './Sponsor8';
import NextToRoutes from './NextTo';
// import ImpactHubRoutes from '../ImpactHub';
import PopWowRoutes from './PopWow';

export default [
    {
        path: ['/town'],
        component: lazy(() => import(/* webpackChunkName: 'TownRoot' */ './component')),
        routes: [
            ...eventsRoutes,
            ...twistNowRoutes,
            ...launchNowRoutes,
            ...PlayForPowerRoutes,
            ...LineRoutes,
            ...TrendRoutes,
            ...KumonRoutes,
            ...Sponsor5Routes,
            ...Sponsor6Routes,
            ...Sponsor7Routes,
            ...Sponsor1Routes,
            ...Sponsor8Routes,
            ...NextToRoutes,
            // ...ImpactHubRoutes,
            ...PopWowRoutes,
        ],
    },
];
