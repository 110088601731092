import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image1 from 'images/pop-wow/6-1-a.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface SeasonAboutProp {
    className?: string;
}

const popwow: React.FC<SeasonAboutProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    return (
        <ModalBaseRight
            className={classnames(styles.popWow, className)}
            title="未來說明書"
            onCloseFinish={() => {
                history.push('/pop-wow');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image1} alt="main" />
                </div>
                <div className={articleStyles.title} style={{ marginBottom: 0 }}>
                    永續X教育 FUTURE NOW ON AIR
                </div>
                <div className={articleStyles.content}>用一個CLICK讓未來發生</div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.section}>
                    <div className={articleStyles.youtube}>
                        <iframe
                            src="https://www.youtube.com/embed/hbO6ILUIK9A"
                            title="YouTube live stream"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
                <div className={articleStyles.content}>
                    這是一座屬於未來的城市，在浩瀚的星辰大海中，等待被發掘、等待被喚醒、等待每一個行動的觸發，改變城市內的光景，讓我們想要的未來在這裡，真正實現。
                    <br />
                    <br />
                    在這座城市裡，每一個行動都有意義、每一個行動都能產生變化、每一個行動都有可能扭轉未來。
                    <br />
                    <br />
                    我們可能常常覺得那些環境問題、經濟困境、和平正義、人類生存危機…這些云云真的太遠太大，渺小如我們哪能改變什麼，或是微弱如我們，差我們一個也沒關係。但真正可以扭轉改變的力量，也不是突然橫空出世如救世主般降臨。那些巨大的力量，不也正是每個微小到不能再微小的粒子點滴匯聚而成的嗎？只有每個人都不放棄這個0.01的微小力量，每天每天往前推進一點點，那個相距萬里、相隔光年的未來，才有可能越來越近。
                    <br />
                    <br />
                    收到「永續」x「教育」這兩個超大課題，我們想著怎麼讓它們可以用更簡單的方式讓大家理解。永續，是能奔向未來的解方；教育，是教我們能對未來有所準備。而這個未來，不會自己來，而是要透過我們不斷不斷地行動、思考、再行動，並讓我們的動能持續下去產生更多行動、改變，那個「我們想要的未來」才能夠「一起抵達。」
                    <br />
                    <br />
                    於是，就有了這個線上未來永續城市的策展。在這裡面，你每一次的行動（滑鼠點擊CLICK）都能累積屬於未來的永續積分，這些積分就代表你做出的每一次行動與改變，而這些改變後的好，都能回饋到你的身上。同時，在城市中透過積分兌換而來的在
                    {`<`}未來扭轉塔Twist NOW!{`>`}
                    中扭蛋的機會，每一次的扭轉，也都象徵你為這個城市貢獻的那0.01的微小力量，當力量累積到一定數值，就能逐步拯救這個城市原本被破壞的角落-
                    {`<`}給力遊樂園Play for Power{`>`}
                    。遊樂園中原本的垃圾、被砍伐的樹木、被破壞的環境，就能一點一點恢復。參與的人數越多，被修復的範圍就越大，當達到力量累積的終點時，給力遊樂園就真正能恢復到原先的樣貌。
                    <br />
                    <br />
                    這些機制的設定，是希望每一個願意CLICK（點擊）產生行動的人都能透過「玩遊戲」，就能認知到這些行動就如同在永續與教育創新中我們做的每一次嘗試，不都也是要讓我們自己的世界、讓我們下一代的未來能夠更好嗎？
                </div>

                <div className={articleStyles.title} style={{ marginTop: 12 }}>
                    <br />
                    改變，每一個人都可以；
                    <br />
                    行動，每一次都有意義
                </div>
                <div className={articleStyles.content}>- 策展人Curator｜李佳穎 Rita Lee </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.content}>
                    <div className={classnames(articleStyles.title, styles.green)}>
                        永續未來發展團隊 Curating Team{' '}
                    </div>
                    策展人｜李佳穎
                    <br /> <br />
                    策展執行團隊｜陳怡靜、盧以婕、陳翊瑄、林于軒、朱淑資、鄭涵云、林洛安、鄭舒云、林育菁、黃育蓮
                    <br />
                    <br />
                    共同策展/議題策劃｜Impact Hub Taipei <br />
                    陳昱築、張士庭、劉子雲、劉蓁淯
                    <br />
                    <br />
                    共同策展/網站設計｜磨人設計 <br />
                    視覺設計統籌：許家豪 <br />
                    UI/UX 設計：林沫玨 <br />
                    前端工程：蕭宇彤 <br />
                    後端工程：陳祈光 <br />
                    模型設計及製作：劉睿潔 <br />
                    動態體驗設計：張瑋芳、蕭宇彤 <br />
                    聲音設計：陳小律 <br />
                    模型拍攝：溫佑誠
                </div>
            </div>
            {events.indexOf(401) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={500}
                        text="領取積分"
                        eventId={401}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    );
};

export default popwow;
