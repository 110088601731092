import { createAction } from '@reduxjs/toolkit';

import { MapType } from './types';

export const focusMap = createAction<{
    type: MapType;
    x: number;
    y: number;
    xOffset: number;
    yOffset: number;
}>('map/focus');

export const clearMapFocus = createAction<MapType>('map/clearFocus');
