// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n    .index__sponsor-line___3l9jk .index__action___3eyJb {\n        width: 100%;\n        text-align: center\n    }\n.index__sponsor-line___3l9jk .index__action___3eyJb > .index__btn___2p808 {\n            margin-top: -30px;\n            margin-bottom: -60px;\n        }\n", "",{"version":3,"sources":["webpack://./src/layouts/SponsorLine/index.css"],"names":[],"mappings":";IACI;QACI,WAAW;QACX;IAMJ;AAJI;YACI,iBAAiB;YACjB,oBAAoB;QACxB","sourcesContent":[".sponsor-line {\n    & .action {\n        width: 100%;\n        text-align: center;\n\n        & > .btn {\n            margin-top: -30px;\n            margin-bottom: -60px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsor-line": "index__sponsor-line___3l9jk",
	"sponsorLine": "index__sponsor-line___3l9jk",
	"action": "index__action___3eyJb",
	"btn": "index__btn___2p808"
};
export default ___CSS_LOADER_EXPORT___;
