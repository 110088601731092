import { useState, useEffect } from 'react';

import { useMapFocus } from 'models/map';

import allMenuData, { extra as allExtraData } from 'data/menu';

import type { MapType } from 'models/map/types';

const useOpenBuilding = (mapType: MapType, buildingNo: number, isExtraBuilding?: boolean) => {
    const [setup, setSetup] = useState(false);
    const [complete, setComplete] = useState(false);

    const [{ focusing }, { focusMap }] = useMapFocus(mapType);

    useEffect(() => {
        if (!isExtraBuilding) {
            const buildingData = allMenuData[mapType][buildingNo - 1];

            const { position, width, height } = buildingData;

            focusMap({
                x: position[0] + width / 2,
                xOffset: window.innerWidth / 2,
                y: position[1] + height / 2,
                yOffset: window.innerHeight / 2,
            });
        } else {
            const buildingData = allExtraData[mapType][buildingNo - 1];

            const { position, width, height } = buildingData;

            focusMap({
                x: position[0] + width / 2,
                xOffset: window.innerWidth / 2,
                y: position[1] + height / 2,
                yOffset: window.innerHeight / 2,
            });
        }

        setSetup(true);
    }, []);

    useEffect(() => {
        if (!focusing && setup) {
            setComplete(true);
        }
    }, [focusing, setup]);

    return complete;
};

export default useOpenBuilding;
