import buildingLunchNow from 'images/mainTown/building-lunch-now.png';
import buildingTwistNow from 'images/mainTown/building-twist-now.png';
import buildingSpeedUp from 'images/mainTown/building-speed-up.png';
import buildingPowerUp from 'images/mainTown/building-power-up.png';
import buildingPopWow from 'images/mainTown/building-pop-wow.png';
import buildingImpactHubTaipei from 'images/mainTown/building-impact-hub-taipei.png';
import buildingFuture from 'images/mainTown/building-future.png';
import buildingNextTo from 'images/mainTown/building-next-to.png';
import buildingStartUpIsland from 'images/mainTown/building-startup-island.png';
import buildingSeasonArt from 'images/mainTown/building-season-art.png';
import sponsorHsbc from 'images/mainTown/sponsor-hsbc.png';
import playground0 from 'images/mainTown/playground-0.png';
import sponsorJector from 'images/mainTown/sponsor-jector.png';
import sponsorLine from 'images/mainTown/sponsor-line.png';
import sponsorTrend from 'images/mainTown/sponsor-trend.png';
import sponsorKumon from 'images/mainTown/sponsor-kumon.png';
import sponsorCloudGate from 'images/mainTown/sponsor-cloudgate.png';
import sponsorHeysong from 'images/mainTown/sponsor-heysong.png';
import sponsorGoodNeighbor from 'images/mainTown/sponsor-good-neighbor.png';
import sponsor8 from 'images/mainTown/sponsor8.png';
import CEO from 'images/mainTown/CEO.png';

import type { MenuItem } from './types';

const data: MenuItem[] = [
    {
        // index 0
        // no 1
        path: '/pop-wow',
        leave: true,
        // path: '/town/pop-wow',
        // leave: false,
        title: '未來產生器',
        enTitle: 'Pop Wow!',
        src: buildingPopWow,
        width: 354,
        height: 354,
        position: [1630, 998],
        anchorType: 'titleMd',
        anchorPosition: [0, 100],
    },
    {
        // index 1
        // no 2
        path: '/town/events',
        leave: false,
        title: '未來加速會展中心',
        enTitle: 'Speed Up',
        src: buildingSpeedUp,
        width: 322,
        height: 498,
        position: [1090, 257],
        anchorType: 'titleLg',
        anchorPosition: [40, 80],
    },
    {
        // index 2
        // no 3
        path: '/future-live',
        leave: true,
        title: '永續未來媒體中心',
        enTitle: 'Future LIVE',
        src: buildingFuture,
        width: 297,
        height: 253,
        position: [2082, 749],
        anchorType: 'titleLg',
        anchorPosition: [0, 40],
        // link: 'https://edu.parenting.com.tw/event/SDGs-450?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    },
    {
        // index 3
        // no 4
        path: '/town/twist-now',
        leave: false,
        title: '未來扭轉塔',
        enTitle: 'Twist Now!',
        src: buildingTwistNow,
        width: 361,
        height: 400,
        position: [1369, 647],
        anchorType: 'titleMd',
        anchorPosition: [40, 80],
    },
    {
        // index 4
        // no 5
        path: '/impact-hub',
        leave: true,
        // path: '/town/impact-hub',
        // leave: false,
        title: '續想家',
        enTitle: 'Taiwan2030',
        src: buildingImpactHubTaipei,
        width: 246,
        height: 339,
        position: [1933, 877],
        anchorType: 'titleMd',
        anchorPosition: [0, 40],
    },
    {
        // index 5
        // no 6
        path: '/startup',
        leave: true,
        enTitle: 'Startup Island TAIWAN',
        src: buildingStartUpIsland,
        width: 375,
        height: 463,
        position: [1718, 363],
        anchorType: 'titleMd',
        anchorPosition: [0, 80],
    },
    {
        // index 6
        // no 7
        path: '/town/launch-now',
        leave: false,
        title: '翻轉教育發射基地',
        enTitle: 'Launch Now',
        src: buildingLunchNow,
        width: 200,
        height: 239,
        position: [1464, 239],
        anchorType: 'titleLg',
        anchorPosition: [0, 40],
    },
    {
        // index 7
        // no 8
        path: '/town/play-for-power',
        leave: false,
        title: '給力遊樂場',
        enTitle: 'Play for Power',
        src: playground0,
        width: 178,
        height: 229,
        position: [590, 1002],
        anchorType: 'titleMd',
        anchorPosition: [0, 60],
    },
    {
        // index 8
        // no 9
        path: '/power-up',
        leave: true,
        title: '永續燃料補給站',
        enTitle: 'Power Up',
        src: buildingPowerUp,
        width: 381,
        height: 382,
        position: [1050, 896],
        anchorType: 'titleLg',
        anchorPosition: [-100, 60],
        // link: 'https://shopping.parenting.com.tw/campaigns/teacher/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    },
    {
        // index 9
        // no 10
        path: '/town/next-to',
        leave: false,
        title: '明天轉運站',
        enTitle: 'Next to',
        src: buildingNextTo,
        width: 306,
        height: 395,
        position: [767, 687],
        anchorType: 'titleMd',
        anchorPosition: [20, 60],
    },
    {
        // index 10
        // no 11
        path: '/season-art',
        leave: true,
        title: '四季藝術',
        src: buildingSeasonArt,
        width: 256,
        height: 282,
        position: [1451, 1157],
        anchorType: 'titleSm',
        anchorPosition: [-20, 40],
    },
    {
        // index 11
        // no 12
        path: '/hsbc',
        leave: true,
        title: '滙豐希望閱讀添翼計畫',
        src: sponsorHsbc,
        width: 102,
        height: 170,
        position: [2157, 562],
        anchorType: 'titleLg',
        anchorPosition: [0, 20],
    },
    {
        // 9
        title: '親子天下永續未來號',
        path: '/town/npc',
        leave: false,
        src: CEO,
        width: 140,
        height: 129,
        position: [1396, 454],
        anchorType: 'titleLg',
        anchorPosition: [0, 10],
    },
];

export default data;

export const extraData: MenuItem[] = [
    {
        // 1
        title: '趨勢科技',
        path: '/town/sponsor/trendmirco',
        leave: false,
        src: sponsorTrend,
        width: 122,
        height: 184,
        position: [1760, 783],
        anchorType: 'dotYellow',
        anchorPosition: [0, 20],
    },
    {
        // 2
        title: 'LINE',
        path: '/town/sponsor/line',
        leave: false,
        src: sponsorLine,
        width: 119,
        height: 180,
        position: [1427, 1039],
        anchorType: 'dotYellow',
        anchorPosition: [0, 20],
    },
    {
        // 3
        title: '黑松教育基金會',
        path: '/town/sponsor/heysong',
        leave: false,
        src: sponsorHeysong,
        width: 108,
        height: 172,
        position: [845, 1113],
        anchorType: 'dotYellow',
        anchorPosition: [0, 20],
    },
    {
        // 4
        title: 'KUMON公文式教育',
        path: '/town/sponsor/kumon',
        leave: false,
        src: sponsorKumon,
        width: 109,
        height: 105,
        position: [850, 457],
        anchorType: 'dotYellow',
        anchorPosition: [0, 10],
    },
    {
        // 5
        title: '雲門舞集舞蹈教室',
        path: '/town/sponsor/cgds',
        leave: false,
        src: sponsorCloudGate,
        width: 109,
        height: 165,
        position: [1991, 1186],
        anchorType: 'dotYellow',
        anchorPosition: [0, 10],
    },
    {
        // 6
        title: '好鄰居超人學校',
        path: '/town/sponsor/goodneighbors',
        leave: false,
        src: sponsorGoodNeighbor,
        width: 115,
        height: 97,
        position: [1692, 1455],
        anchorType: 'dotYellow',
        anchorPosition: [0, 10],
    },

    {
        // 7
        title: '陽光伏特家',
        path: '/town/sponsor/8',
        leave: false,
        src: sponsor8,
        width: 276,
        height: 177,
        position: [2182, 1227],
        anchorType: 'dotYellow',
        anchorPosition: [0, 0],
    },
    {
        // 8
        title: '傑可達數位',
        path: '/town/sponsor/jector',
        leave: false,
        src: sponsorJector,
        width: 98,
        height: 154,
        position: [1271, 844],
        anchorType: 'dotYellow',
        anchorPosition: [0, 10],
    },
];
