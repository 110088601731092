import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/kumon.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface SponsorKumonProp {
    className?: string;
}

const SponsorKumon: React.FC<SponsorKumonProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    // const openComplete = useOpenBuilding('main', 4, true);
    const [isSetup, setIsSetup] = useState(false);
    // const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    // useEffect(() => {
    //     if (openComplete && isSetup) {
    //         setIsReady(true);
    //     }
    // }, [openComplete, isSetup]);

    return isSetup ? (
        <ModalBaseRight
            className={classnames(styles.sponsorTrend, className)}
            title="KUMON"
            enTitle="公文式教育"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    KUMON公文式教育風行全球五十七個國家和地區，是經過驗證確實能幫助孩子激發潛能的學習法。透過專業的編序性教材，以及個人別的學習方式，讓每個孩子都能夠學習最適合自己的內容和進度，並在學習的過程中奠定扎實的計算、理解、閱讀力，同時培養出高度的自學力，以及適應未來的生存力。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="https://www.kumon-km.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: 'kumon --- 了解更多',
                            });
                        }}
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(1701) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={1701}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default SponsorKumon;
