import img1 from 'images/events/1-1.png';
import img2 from 'images/events/1-2.png';
import img3 from 'images/events/1-3.png';
import img4 from 'images/events/1-4.png';
import img5 from 'images/events/1-5.png';
import img6 from 'images/events/1-6.png';
import img7 from 'images/events/1-7.png';
import img8 from 'images/events/1-8.png';
import img9 from 'images/events/1-9.png';
import img10 from 'images/events/1-10.png';
import img11 from 'images/events/1-11.png';
import img12 from 'images/events/1-12.png';
import img13 from 'images/events/1-13.png';
import img14 from 'images/events/1-14.png';
import img15 from 'images/events/1-15.png';

import type { EventData } from './types';

const data: EventData = {
    id: 1,
    category: 'live',
    title: '永續 x 教育 實踐 SDGs 培養改變世界的行動者',
    date: '11/30',
    startTime: '09:15',
    endTime: '16:30',
    intro: '在世界重啟的關鍵時刻，「跨域」將是改變的起點，「共好」則是行動的開始。當未知與變化成為常態，我們該如何為下個世代找尋另一種可能？面對教與學的快速變革，又有哪些典範模式值得借鏡？從理念到實踐，帶您掌握科技教育關鍵字，迎向 2030 永續願景，培養改變世界的行動者！',
    youtubeEnId: 'PNRm9W2A9Pk',
    youtubeLiveId: 'FPyRj-N31Lc',
    youtubeVideoId: 'FPyRj-N31Lc',
    sponsor: `本活動指導單位，國家發展委員會、Startup Island TAIWAN\n策略夥伴 LINE台灣 共同倡議`,
    questionnaire:
        'https://forms.gle/VFEG6ZTnwfNHLET5A?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    agenda: [
        {
            title: '開場&貴賓致詞',
            startTime: '09:15',
            endTime: '09:30',
        },
        {
            title: '專題演講一：2030，培養改變世界的行動者',
            startTime: '09:30',
            endTime: '10:10',
            speaker: [
                {
                    name: '陳建仁',
                    intro: '中研院基因體研究中心特聘研究員',
                },
                {
                    name: '王宣茹',
                    intro: '中原大學室內設計系學生',
                },
            ],
        },
        {
            title: '專題與談一：跨域、專題，從真實世界學習',
            startTime: '10:10',
            endTime: '10:50',
            speaker: [
                {
                    name: '李吉仁',
                    intro: '誠致教育基金會副董事長',
                },
                {
                    name: '安妮・布魯克（Annie Brock）',
                    intro: '《成長性思維學習指南》作者',
                },
                {
                    name: '羅伯・李奧登（Rob Riordan） ',
                    intro: '美國 High Tech High 共同創辦人',
                },
            ],
        },
        {
            title: '休息',
            startTime: '10:50',
            endTime: '10:55',
        },
        {
            title: '專題與談二：跨域合作，企業助攻教育',
            startTime: '10:55',
            endTime: '11:40',
            speaker: [
                {
                    name: '呂冠緯',
                    intro: '均一平台教育基金會董事長暨執行長',
                },
                {
                    name: '馬汀・奎爾（Martin Creel）',
                    intro: '前 Discovery Education 首席學術長',
                },
                {
                    name: '陳立人',
                    intro: 'LINE台灣董事總經理',
                },
            ],
        },
        {
            title: '親子天下教育生態圈報告',
            startTime: '11:40',
            endTime: '11:50',
            speaker: [
                {
                    name: '陳雅慧',
                    intro: '親子天下總編輯',
                },
            ],
        },
        {
            title: '中場休息',
            startTime: '11:50',
            endTime: '14:20',
        },
        {
            title: '開場引言',
            startTime: '14:20',
            endTime: '14:25',
        },
        {
            title: '專題與談三：未來的人才與未來的學習',
            startTime: '14:25',
            endTime: '15:05',
            speaker: [
                {
                    name: '葉丙成',
                    intro: 'BoniOInc. / PaGamO 執行長',
                },
                {
                    name: '廖怡雯',
                    intro: '綠藤生機共同創辦人暨營運長',
                },
                {
                    name: '趙捷平',
                    intro: 'AmazingTalker 創辦人兼執行長',
                },
            ],
        },
        {
            title: '專題演講二：遊戲式學習，提升動機和創造力',
            startTime: '15:05',
            endTime: '15:45',
            speaker: [
                {
                    name: '賈斯汀・愛德華茲（Justin Edwards）',
                    intro: 'Minecraft 教育版全球學習課程設計總監',
                },
            ],
        },
        {
            title: '休息',
            startTime: '15:45',
            endTime: '15:50',
        },
        {
            title: '【專題與談四】SDGs 永續課程設計資源',
            startTime: '15:50',
            endTime: '16:30',
            speaker: [
                {
                    name: '許芯瑋',
                    intro: 'DFC臺灣發起人',
                },
                {
                    name: '碧荷拉・梅斯卡能（Pihla Meskanen）',
                    intro: '芬蘭Arkki兒童與青少年建築學校創辦人',
                },
                {
                    name: '藍偉瑩',
                    intro: '瑩光教育協會創辦人',
                },
            ],
        },
    ],
    speakerImages: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
        img13,
        img14,
        img15,
    ],
};

export default data;
