import classnames from 'classnames';

import CurvedContainer from 'components/atoms/CurvedContainer';
import ModalBaseCenter from 'components/organisms/ModalBaseCenter';

import LikeOutline from 'images/icons/heart-outline.inline.svg';

import { characterData, themeData } from 'data/character';
import { useMessages } from 'models/messages';
import { useModal } from 'models/modal';

import styles from './index.css';

export interface ModalMessageDetailProp {
    className?: string;
    characterType: number;
    name: string;
    content: string;
    time: string;
    likes: number;
    liked?: boolean;
    onLiked?: (nextLiked: boolean) => void;
    onClick?: () => void;
    onCloseFinish?: () => void;
}

export const ModalMessageDetailLayout: React.FC<ModalMessageDetailProp> = ({
    className,
    characterType,
    name,
    content,
    time,
    likes = 0,
    liked,
    onLiked = () => {},
    onCloseFinish = () => {},
}) => {
    return (
        <ModalBaseCenter
            className={classnames(styles.modalMessageDetail, className)}
            containerClass={styles.modal}
            childrenClassName={styles.children}
            bottomBtn
            onCloseFinish={onCloseFinish}
        >
            <CurvedContainer
                className={styles.container}
                borderColor="--accent-lightgreen"
                filledColor="rgba(2, 233, 184, 0.3)"
                transparent
                size="md"
            >
                <div
                    className={classnames(styles.likeGroup, liked && styles.liked)}
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => {}}
                    onClick={(event) => {
                        onLiked(!liked);
                        event.stopPropagation();
                    }}
                >
                    <LikeOutline />
                    {liked && <div className={styles.count}>{likes}</div>}
                </div>
                <div className={styles.top}>
                    <div className={styles.avatar}>
                        <img
                            src={
                                characterType < 2800
                                    ? characterData[characterType].avatar
                                    : themeData[characterType].avatar
                            }
                            alt={`character${characterData[characterType]}.id`}
                        />
                    </div>
                    <div className={styles.text}>
                        <div className={styles.titleGroup}>
                            <div className={styles.character}>
                                {characterType < 2800
                                    ? characterData[characterType].name
                                    : themeData[characterType].name}
                            </div>
                            <div className={styles.name}>{name}</div>
                        </div>
                        <div className={styles.time}>{time}</div>
                    </div>
                </div>
                <div className={styles.content}>{content}</div>
            </CurvedContainer>
        </ModalBaseCenter>
    );
};

const ModalMessageDetail: React.FC<ModalMessageDetailProp> = ({
    className,
    onCloseFinish = () => {},
}) => {
    const [
        {
            messagesDetail: {
                data: { messageId },
            },
        },
        { openModal },
    ] = useModal();
    const [{ messagesData }, { likeMessage }] = useMessages();

    return (
        <ModalMessageDetailLayout
            characterType={messagesData[messageId].authorCharacterType}
            name={messagesData[messageId].author}
            content={messagesData[messageId].content}
            time={messagesData[messageId].createTime}
            likes={messagesData[messageId].likes}
            liked={messagesData[messageId].liked}
            onLiked={() => {
                likeMessage({
                    messageId,
                    like: messagesData[messageId].liked,
                });
            }}
            onCloseFinish={onCloseFinish}
        />
    );
};

export default ModalMessageDetail;
