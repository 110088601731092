import img1 from 'images/events/2-1.png';
import img2 from 'images/events/2-2.png';
import img3 from 'images/events/2-3.png';
import img4 from 'images/events/2-4.png';
import img5 from 'images/events/2-5.png';
import img6 from 'images/events/2-6.png';

import type { EventData } from './types';

const data: EventData = {
    id: 2,
    category: 'live',
    title: '台灣數位教育元年啟動，2030 教育新未來',
    date: '12/4',
    startTime: '09:30',
    endTime: '11:35',
    intro: '今年五月中旬的大停課，帶給教育現場全面性的衝擊，在全台親師生努力適應線上教學之際，世界各國已經過一年多遠距教學的操練，其中又有哪些學習與反思？後疫情時代，數位與遠距已不再是疫情下的備案，而是未來教育的必要裝備，迎接混成學習新常態，讓我們大膽想像教育的未來風景！',
    youtubeEnId: 'gTYyHZe0aNM',
    youtubeLiveId: 'kCyHqpIF4_U',
    youtubeVideoId: 'kCyHqpIF4_U',
    sponsor: '本場論壇由策略夥伴 趨勢科技 共同倡議',
    questionnaire:
        'https://forms.gle/9vTdcSCNrx9GW8QL9?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    agenda: [
        {
            title: '開場',
            startTime: '09:30',
            endTime: '09:40',
        },
        {
            title: '專題演講：實踐教育的未來風景',
            startTime: '09:40',
            endTime: '10:15',
            speaker: [
                {
                    name: '史提潘・梅西泰里恩（Stepan Mekhitarian）',
                    intro: '美國加州學區創新、評量長',
                },
            ],
        },
        {
            title: '專題與談：虛實混成學習新常態',
            startTime: '10:15',
            endTime: '11:35',
            speaker: [
                {
                    name: '郭伯臣',
                    intro: '教育部資科司司長',
                },
                {
                    name: '葉丙成',
                    intro: 'BoniOInc. / PaGamO 執行長',
                },
                {
                    name: '呂冠緯',
                    intro: '均一平台教育基金會董事長暨執行長',
                },
                {
                    name: '陳逸文',
                    intro: 'LearnMode 學習吧產品總監',
                },
                {
                    name: '朱芳薇',
                    intro: '趨勢科技兒少網安及數位素養資深講師',
                },
            ],
        },
    ],
    speakerImages: [img1, img2, img3, img4, img5, img6],
};

export default data;
