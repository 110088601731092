import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useModal } from 'models/modal';
import { useUserData } from 'models/user';
import { useTransition } from 'models/mainSite';

import { useMedia } from 'utils/hooks';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';

import ticketIcon from 'images/icons/coupon.png';
import couponGif from 'images/icons/coupon-anime.gif';

import articleStyles from 'layouts/articleStyles.css';

import styles from './index.css';

interface Gift {
    giftId: number;
    themeId: number;
}

export interface ModalClosetLayoutProp {
    className?: string;
    availableTicketCount: number;
    onCloseFinish?: () => void;
}

export const ModalClosetLayout: React.FC<ModalClosetLayoutProp> = ({
    className,
    onCloseFinish = () => {},
    availableTicketCount = 0,
}) => {
    const [active, setActive] = useState(true);
    const history = useHistory();
    const [, { startTransition }] = useTransition();

    return (
        <ModalBaseCenter
            className={classnames(styles.modalCloset, className)}
            icon={ticketIcon}
            title="我的扭蛋券"
            bottomBtn
            active={active}
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.content}>
                <img className={styles.coupon} src={couponGif} alt="icon" />
                <div className={styles.title}>扭蛋券 x{availableTicketCount}</div>
                <div className={classnames(styles.content, styles.ticketContent)}>
                    這不是普通的扭蛋券，這是可以扭轉未來的神奇力量！
                </div>
            </div>
            <div className={styles.btnWrapper}>
                <Button3D
                    className={styles.btn}
                    onClick={() => {
                        const nowTown = history.location.pathname.split('/')[1];
                        const nextTown = 'town';

                        if (nowTown === nextTown) {
                            history.push('/town/twist-now');
                        } else {
                            startTransition({ nextPath: '/town/twist-now' });
                        }

                        setActive(false);
                    }}
                >
                    前往【未來扭轉塔】使用
                </Button3D>
            </div>
        </ModalBaseCenter>
    );
};

export interface ModalClosetProp {
    className?: string;
    onCloseFinish?: () => void;
}

const ModalCloset: React.FC<ModalClosetProp> = ({ className, onCloseFinish }) => {
    const [{ availableTicketCount }, { getUserData }] = useUserData();

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <ModalClosetLayout
            className={className}
            onCloseFinish={onCloseFinish}
            availableTicketCount={availableTicketCount}
        />
    );
};

export default ModalCloset;
