import { useState, useEffect, useRef, useCallback } from 'react';

interface AnimeStep {
    animeType?: string;
    delay: number;
    flip: boolean;
    xDir: 1 | -1;
    yDir: 1 | -1;
    distance: number;
}

const useAnime = ({
    speed = 1,
    data = [],
    resetPos = 0,
}: {
    speed: number;
    data: AnimeStep[];
    resetPos: number;
}) => {
    const requestRef = useRef<number | null>();

    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [flip, setFlip] = useState(data[0].flip);
    const [paused, setPaused] = useState(true);
    const [animeType, setAnimeType] = useState(data[0].animeType);

    const animeRef = useRef({
        speed: 1,
        index: 0,
        flip: false,
        timeLeft: 0,
        distanceLeft: 0,
        x: 0,
        y: 0,
    });

    const animate = useCallback(() => {
        if (data.length > 0) {
            const nowData = data[animeRef.current.index];
            if (animeRef.current.timeLeft > 0) {
                animeRef.current.timeLeft -= 1;
            } else if (animeRef.current.distanceLeft > 0) {
                setPaused(false);
                setFlip(data[animeRef.current.index].flip);
                setAnimeType(data[animeRef.current.index].animeType);

                const nextOffsetX = animeRef.current.x + nowData.xDir * animeRef.current.speed;
                const nextOffsetY =
                    animeRef.current.y +
                    nowData.yDir * Math.sin(Math.PI / 6) * animeRef.current.speed;

                setOffsetX(nextOffsetX);
                animeRef.current.x = nextOffsetX;

                setOffsetY(nextOffsetY);
                animeRef.current.y = nextOffsetY;

                animeRef.current.distanceLeft -= animeRef.current.speed;
            } else if (animeRef.current.index < data.length - 1) {
                setPaused(true);

                animeRef.current.index += 1;
                animeRef.current.timeLeft = data[animeRef.current.index].delay;
                animeRef.current.distanceLeft = data[animeRef.current.index].distance;
            } else {
                setPaused(true);

                animeRef.current.index = 0;
                animeRef.current.timeLeft = data[animeRef.current.index].delay;
                animeRef.current.distanceLeft = data[animeRef.current.index].distance - resetPos;

                setOffsetX(resetPos * data[animeRef.current.index].xDir);
                animeRef.current.x = resetPos * data[animeRef.current.index].xDir;

                setOffsetY(resetPos * Math.sin(Math.PI / 6) * data[animeRef.current.index].yDir);
                animeRef.current.y =
                    resetPos * Math.sin(Math.PI / 6) * data[animeRef.current.index].yDir;

                setFlip(data[animeRef.current.index].flip);
                setAnimeType(data[animeRef.current.index].animeType);
            }

            // Change the state according to the animation
            requestRef.current = requestAnimationFrame(animate);
        }
    }, [data]);

    const play = useCallback(() => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        animate();
    }, []);

    useEffect(() => {
        if (data.length) {
            animeRef.current = {
                speed,
                index: 0,
                flip: data[0].flip,
                timeLeft: data[0].delay,
                distanceLeft: data[0].distance,
                x: 0,
                y: 0,
            };
        }
    }, [data]);

    useEffect(() => {
        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    return {
        play,
        offsetX,
        offsetY,
        flip,
        paused,
        animeType,
    };
};

export default useAnime;
