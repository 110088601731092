import img1 from 'images/events/11-1.png';

import type { EventData } from './types';

const data: EventData = {
    id: 11,
    category: 'workshop',
    title: 'Play with SDGs  一起來當生活藝術家',
    contentTitle: 'Play with SDGs  一起來當生活藝術家',
    date: '12/5',
    startTime: '14:00',
    endTime: '16:30',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '藝術教育如何與永續議題結合？永續在藝術上的表現，除了透過裝置藝術呈現人類浪費資源和環境破壞的關係，還有哪些表達方式？此工作坊將引導參與者透過觀察生活中的植物，和生活產生美感的連結，用藝術創作形式重現在家裡，用紙改造讓環境變美麗！',
    youtubeLiveId: 'il_JwbZfrsE',
    youtubeVideoId: 'il_JwbZfrsE',
    sponsor: '本場活動由策略夥伴 四季藝術兒童教育機構 共同倡議',
    agenda: [
        {
            title: '開場',
            startTime: '14:00',
            endTime: '14:15',
        },
        {
            title: '簡報說明',
            startTime: '14:15',
            endTime: '14:45',
            speaker: [
                {
                    name: '姚智凱',
                    intro: '四季藝術兒童教育機構藝術教師',
                },
            ],
        },
        {
            title: '休息時間',
            startTime: '14:45',
            endTime: '14:55',
        },
        {
            title: '實作引導',
            startTime: '14:55',
            endTime: '15:15',
        },
        {
            title: '實作時間',
            startTime: '15:15',
            endTime: '16:05',
        },
        {
            title: '【成果分享】',
            startTime: '16:05',
            endTime: '16:30',
        },
    ],
    speakerImages: [img1],
};

export default data;
