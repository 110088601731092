// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__progress___on_VX {\n    position: relative;\n    width: 100%;\n    height: 4px\n}\n.index__progress___on_VX > .index__value___1Hoj4 {\n        position: absolute;\n        height: 100%;\n        -webkit-transition: width 0.2s linear;\n        transition: width 0.2s linear;\n        background-color: #02E9B8;\n        opacity: 1;\n    }\n.index__progress___on_VX > .index__track___3vzoh {\n        position: relative;\n        width: 100%;\n        height: 100%;\n        background-color: #02E9B8;\n        opacity: 0.3;\n    }\n", "",{"version":3,"sources":["webpack://./src/components/atoms/Progress/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX;AAiBJ;AAfI;QACI,kBAAkB;QAClB,YAAY;QACZ,qCAA6B;QAA7B,6BAA6B;QAC7B,yBAA0C;QAC1C,UAAU;IACd;AAEA;QACI,kBAAkB;QAClB,WAAW;QACX,YAAY;QACZ,yBAA0C;QAC1C,YAAY;IAChB","sourcesContent":[".progress {\n    position: relative;\n    width: 100%;\n    height: 4px;\n\n    & > .value {\n        position: absolute;\n        height: 100%;\n        transition: width 0.2s linear;\n        background-color: var(--accent-lightgreen);\n        opacity: 1;\n    }\n\n    & > .track {\n        position: relative;\n        width: 100%;\n        height: 100%;\n        background-color: var(--accent-lightgreen);\n        opacity: 0.3;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "index__progress___on_VX",
	"value": "index__value___1Hoj4",
	"track": "index__track___3vzoh"
};
export default ___CSS_LOADER_EXPORT___;
