import classnames from 'classnames';

import type { MouseEvent } from 'react';

// import { color } from 'utils/helper';

import Live from 'images/icons/live.inline.svg';
import Info from 'images/icons/info.inline.svg';
import Calendar from 'images/icons/calendar-add.inline.svg';

import styles from './index.css';

export interface EventCardProp {
    className?: string;
    timeType: 'live' | 'upcoming' | 'past';
    eventType: 'live' | 'shortTalk' | 'workshop';
    month: string;
    day: string;
    title: string;
    startTime: string;
    endTime: string;
    intro: string;
    speakers?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const EventCard: React.FC<EventCardProp> = ({
    className,
    timeType,
    eventType,
    month,
    day,
    title,
    startTime,
    endTime,
    intro,
    speakers = '',
    onClick = () => {},
}) => {
    return (
        <div
            className={classnames(styles.eventCard, styles[eventType], className)}
            role="presentation"
            onClick={onClick}
        >
            {timeType !== 'past' && (
                <div className={styles.date}>
                    <div className={styles.month}>{month} 月</div>
                    <div className={styles.day}>{day}</div>
                </div>
            )}
            <div className={styles.main}>
                <div className={styles.title}>{title}</div>
                {timeType !== 'past' ? (
                    <div className={styles.time}>
                        {startTime} - {endTime}
                    </div>
                ) : (
                    <div className={styles.speakers}>{speakers}</div>
                )}
            </div>
            <div
                className={classnames(
                    styles.action,
                    timeType === 'live' && styles.live,
                    timeType === 'upcoming' && styles.calendar,
                )}
                role="button"
                tabIndex={0}
                onKeyPress={() => {}}
                onClick={(event) => {
                    if (timeType === 'upcoming') {
                        const [startHour, startMinute] = startTime.split(':');
                        const [endHour, endMinute] = endTime.split(':');

                        const calendarLink = `http://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=2021${month}${
                            day.length === 1 ? `0${day}` : day
                        }T${startHour.length === 1 ? `0${startHour}` : startHour}${
                            startMinute.length === 1 ? `0${startMinute}` : startMinute
                        }00/2021${month}${day.length === 1 ? `0${day}` : day}T${
                            endHour.length === 1 ? `0${endHour}` : endHour
                        }${
                            endMinute.length === 1 ? `0${endMinute}` : endMinute
                        }00&ctz=Asia/Taipei&details=${intro}`;

                        window.open(encodeURI(calendarLink));

                        event.stopPropagation();
                    }
                }}
            >
                {timeType === 'live' && eventType === 'live' && <Live />}
                {timeType === 'upcoming' && <Calendar />}
                {(timeType === 'past' || eventType !== 'live') && <Info />}
            </div>
        </div>
    );
};

export default EventCard;
