import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMedia } from 'utils/hooks';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';
import { useTransition, useRankData } from 'models/mainSite';

import CurvedContainer from 'components/atoms/CurvedContainer';
import Progress from 'components/atoms/Progress';
import ArrowBtn from 'components/atoms/ArrowBtn';

import { characterData, themeData } from 'data/character';
import rankPrize from 'data/rankPrize';

import { numberWithCommas } from 'utils/helper';
import { useModal } from 'models/modal';

import icon from 'images/icons/rank.png';
import Info from 'images/icons/item-info.inline.svg';

import styles from './index.css';

export interface ModalExploreInfoProp {
    className?: string;
    active?: boolean;
    onCloseFinish?: () => void;
}

const ModalExploreInfo: React.FC<ModalExploreInfoProp> = ({ className, onCloseFinish }) => {
    const history = useHistory();
    const [, { startTransition }] = useTransition();
    const media = useMedia();
    const [
        {
            giftDetail: { active: giftDetailActive },
        },
        // { openModal },
    ] = useModal();

    const [rankList, { getRank }] = useRankData();

    const [page, setPage] = useState(1);
    const [active, setActive] = useState(true);
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    // const activeTheme = 1;
    // const characterType = 1;

    useEffect(() => {
        getRank();

        return () => {
            if (swiper) {
                swiper.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [media, swiper]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalRank, className)}
            containerClass={classnames(styles.modal)}
            active={active}
            bottomBtn
            icon={icon}
            title="積分排行榜"
            subtitle="永續積分每 4 小時更新一次，排名獎勵活動已於 12/19 結束"
            onCloseFinish={onCloseFinish}
            canClose={!giftDetailActive}
        >
            <div className={styles.main}>
                <div className={styles.content}>
                    {rankList.length > 0 && (
                        <Swiper
                            slidesPerView={1}
                            onSwiper={(s) => setSwiper(s)}
                            onSlideChange={(e) => {
                                setPage(e.activeIndex + 1);
                            }}
                            breakpoints={{
                                900: {
                                    slidesPerView: 2,
                                },
                            }}
                        >
                            {Array.from({ length: 10 }, (_, i) => i + 1).map((page) => {
                                return (
                                    <SwiperSlide key={page} className={styles.slide}>
                                        <div className={styles.group}>
                                            {rankList
                                                .slice((page - 1) * 3, page * 3)
                                                .map(({ rank, characterType, points, name }) => {
                                                    return (
                                                        <CurvedContainer
                                                            key={rank}
                                                            className={styles.cardContainer}
                                                            size="sm"
                                                            borderColor="--accent-lightgreen"
                                                            filledColor="rgba(2, 233, 184, 0.3)"
                                                            transparent
                                                        >
                                                            <div className={styles.rankItem}>
                                                                <div className={styles.rank}>
                                                                    {rank}
                                                                </div>
                                                                <div className={styles.avatar}>
                                                                    <img
                                                                        src={
                                                                            characterType > 2800
                                                                                ? themeData[
                                                                                      characterType
                                                                                  ].avatar
                                                                                : characterData[
                                                                                      characterType
                                                                                  ].avatar
                                                                        }
                                                                        alt="avatar"
                                                                    />
                                                                </div>
                                                                <div className={styles.nameGroup}>
                                                                    <div className={styles.name}>
                                                                        {name}
                                                                    </div>
                                                                    <div className={styles.score}>
                                                                        {numberWithCommas(points)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CurvedContainer>
                                                    );
                                                })}
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                </div>
                <Progress
                    className={styles.progress}
                    value={page}
                    max={media === 'desktop' ? 9 : 10}
                />
                <Button3D
                    className={styles.btn}
                    spaceTop={36}
                    spaceBottom={41}
                    onClick={() => {
                        if (history.location.pathname.includes('/town')) {
                            setActive(false);
                            history.push('/town');
                        } else {
                            setActive(false);
                            startTransition({ nextPath: '/town' });
                        }
                    }}
                >
                    前往探索未來城市
                </Button3D>
                <ArrowBtn
                    className={classnames(styles.action, styles.prev)}
                    disabled={page <= 1}
                    type="prev"
                    onClick={() => {
                        if (swiper) {
                            swiper.slidePrev();
                        }
                    }}
                />
                <ArrowBtn
                    className={classnames(styles.action, styles.next)}
                    disabled={media === 'desktop' ? page >= 9 : page >= 10}
                    type="next"
                    onClick={() => {
                        if (swiper) {
                            swiper.slideNext();
                        }
                    }}
                />
            </div>
        </ModalBaseCenter>
    );
};

export default ModalExploreInfo;
