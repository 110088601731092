import classnames from 'classnames';
import { useState, useEffect } from 'react';

import { useModal } from 'models/modal';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';

import prizeData from 'data/prize';
import { characterData } from 'data/character';

import styles from './index.css';

export interface ModalGiftDetailProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalGiftDetail: React.FC<ModalGiftDetailProp> = ({
    className,
    onCloseFinish = () => {},
}) => {
    const [active, setActive] = useState(true);
    const [data, setData] = useState(undefined);

    const [
        {
            giftNew: {
                data: { giftId, themeId, code },
            },
        },
    ] = useModal();

    // const giftId = 27;
    // const themeId = 9;

    useEffect(() => {
        if (giftId === 28 && themeId && themeId < 2800) {
            setData({
                image: characterData[themeId].avatar,
                name: characterData[themeId].name,
                note: characterData[themeId].introTitle,
                intro: characterData[themeId].intro,
            });
        } else if (giftId === 25 || giftId === 27) {
            setData(prizeData[giftId].themes[themeId]);
        } else if (giftId) {
            setData(prizeData[giftId]);
        } else {
            setData(undefined);
        }
    }, [giftId, themeId]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalGiftDetail, className)}
            childrenClassName={styles.children}
            active={active}
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.content}>
                <div className={styles.title}>扭蛋結果</div>
                <div className={styles.subtitle}>恭喜！獲得了</div>
                {data && (
                    <div className={styles.giftContent}>
                        <div className={styles.image}>
                            <img src={data.image} alt="preview" />
                        </div>
                        <div className={styles.name}>{data.name}</div>
                    </div>
                )}
                <Button3D
                    spaceTop={20}
                    spaceBottom={10}
                    onClick={() => {
                        setActive(false);
                    }}
                >
                    放入背包
                </Button3D>
            </div>
        </ModalBaseCenter>
    );
};

export default ModalGiftDetail;
