import img1 from 'images/events/6-1.png';
import img2 from 'images/events/6-2.png';
import img3 from 'images/events/6-3.png';

import type { EventData } from './types';

const data: EventData = {
    id: 6,
    category: 'shortTalk',
    title: '未來在等待的人才：用創造力讓永續發生',
    contentTitle: '未來在等待的人才：用創造力讓永續發生',
    date: '12/1',
    startTime: '20:00',
    endTime: '21:00',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '未來最需要的能力不是任何一種知識，而是一種能夠快速應對變化並嘗試改變的能力-創造力。但創造力從來都不是隨機迸發的閃光，而是一個可以通過思維步驟刻意練習的能力。DFC 臺灣，通過設計思考四步驟，在今年以 SDGs (聯合國永續發展目標）為題，帶領師生用創造力回應真實世界困境。分享中，將通過參與 DFC 挑戰行動的兩組孩子實踐案例，帶觀眾們一窺永續議題如何落實教育現場並產生實踐。',
    youtubeLiveId: 'D0Kj4dYOY-0',
    youtubeVideoId: 'D0Kj4dYOY-0',
    sponsor: '',
    agenda: [
        {
            title: '開場引言',
            startTime: '20:00',
            endTime: '20:05',
        },
        {
            title: '做夢工廠・製造永續解方，陪伴孩子成為主動關心世界的人',
            startTime: '20:05',
            endTime: '20:15',
            speaker: [
                {
                    name: '許芯瑋',
                    intro: 'DFC 臺灣發起人',
                },
            ],
        },
        {
            title: '第十一屆 DFC 挑戰分享季案例分享：走入蔬適圈，減少碳足跡',
            startTime: '20:15',
            endTime: '20:25',
            speaker: [
                {
                    name: '高雄市正義國小專案團隊',
                    intro: '',
                },
            ],
        },
        {
            title: '第十一屆 DFC 挑戰分享季案例分享：天作之和，鯨天一起去看好',
            startTime: '20:25',
            endTime: '20:35',
            speaker: [
                {
                    name: '花蓮縣宜昌國小專案團隊',
                    intro: '',
                },
            ],
        },
        {
            title: 'QA 時間',
            startTime: '20:35',
            endTime: '21:00',
        },
    ],
    speakerImages: [img1, img2, img3],
};

export default data;
