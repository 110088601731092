import { useCallback } from 'react';
import classnames from 'classnames';

import { useEvent } from 'models/user';
import { useModal } from 'models/modal';
import { useAuth } from 'models/auth';

import ButtonPoint from 'components/atoms/ButtonPoint';

import styles from './index.css';

export interface ButtonShareProp {
    className?: string;
    text: string;
    points: number;
    eventId: number;
    msg?: string;
    shareUrl: string;
    onClick?: () => void;
}

const { FACEBOOK_ID } = process.env;

const ButtonShare: React.FC<ButtonShareProp> = ({
    className,
    text,
    points,
    eventId,
    msg = '分享成功',
    shareUrl,
    onClick: onClickProp = () => {},
}) => {
    const [{ loginType }] = useAuth();
    const [{ events }, { getPoint }] = useEvent();

    const onClick = useCallback(() => {
        if (!window.isFbSDKInit) {
            window.FB.init({
                appId: FACEBOOK_ID,
                xfbml: true, // parse social plugins on this page
                version: 'v12.0', // use graph api version 12
            });
            window.isFbSDKInit = true;
        }

        window.FB.ui(
            {
                method: 'share',
                href: shareUrl,
            },
            (response) => {
                if (response && !response.error_message) {
                    if (loginType === 'facebook') {
                        if (eventId && events.indexOf(eventId) === -1) {
                            getPoint({
                                points,
                                eventId,
                                msg: `${msg || '分享成功'}，積分 +${points}`,
                            });
                        }

                        if (!eventId) {
                            getPoint({ points, msg: `${msg || '分享成功'}，積分 +${points}` });
                        }
                    }
                }
            },
        );
        onClickProp();
    }, [shareUrl, onClickProp]);

    return (
        <ButtonPoint
            className={className}
            text={text}
            points={points}
            eventId={eventId}
            msg={msg}
            sendEvent={false}
            onClick={onClick}
        />
    );
};

export default ButtonShare;
