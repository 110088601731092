// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__loading-text___1JNV_ {\n    display: -webkit-box;\n    display: flex;\n    position: absolute;\n    -webkit-box-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n            align-items: center;\n    width: 100%;\n    height: 100%;\n    color: #FDF7EC;\n    font-size: 15px;\n    line-height: 18px\n}\n.index__loading-text___1JNV_ > span {\n        opacity: 0;\n    }\n.index__loading-text___1JNV_ > .index__dot1___c-nWE {\n        margin-left: 0.3em;\n    }\n", "",{"version":3,"sources":["webpack://./src/components/atoms/LoadingText/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,aAAa;IACb,kBAAkB;IAClB,wBAAuB;YAAvB,uBAAuB;IACvB,yBAAmB;YAAnB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,cAA2B;IAC3B,eAAe;IACf;AASJ;AAPI;QACI,UAAU;IACd;AAEA;QACI,kBAAkB;IACtB","sourcesContent":[".loading-text {\n    display: flex;\n    position: absolute;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    color: var(--primary-light);\n    font-size: 15px;\n    line-height: 18px;\n\n    & > span {\n        opacity: 0;\n    }\n\n    & > .dot1 {\n        margin-left: 0.3em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-text": "index__loading-text___1JNV_",
	"loadingText": "index__loading-text___1JNV_",
	"dot1": "index__dot1___c-nWE"
};
export default ___CSS_LOADER_EXPORT___;
