import img1 from 'images/events/3-1.png';
import img2 from 'images/events/3-2.png';
import img3 from 'images/events/3-3.png';
import img4 from 'images/events/3-4.png';

import type { EventData } from './types';

const data: EventData = {
    id: 3,
    category: 'live',
    title: '108 課綱的關鍵素養──提問、探究與實作力',
    date: '12/4',
    startTime: '09:40',
    endTime: '11:30',
    intro: '「探究與實作」是因應 108 課綱而生的新型態課程，也是許多老師在進行素養課程設計與教學的最大痛點。面對沒有標準答案的探究實作，第一線的教師又該怎麼教？怎麼學？怎麼考？四位教育工作者，完全解析課程設計與教學重點，啟動孩子探究與提問力，給孩子帶得走的能力！',
    youtubeLiveId: 'scBHzwJV6FA',
    youtubeVideoId: 'scBHzwJV6FA',
    sponsor: '',
    questionnaire:
        'https://forms.gle/QthPn14xHdPtza8TA?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    agenda: [
        {
            title: '開場',
            startTime: '09:40',
            endTime: '09:50',
        },
        {
            title: '專題與談一：閱讀素養X提問力',
            startTime: '09:50',
            endTime: '10:40',
            speaker: [
                {
                    name: '黃國珍',
                    intro: '品學堂執行長、《閱讀理解學習誌》總編輯',
                },
                {
                    name: '陳珮汝',
                    intro: '臺北市興雅國中國文與閱讀教師',
                },
            ],
        },
        {
            title: '專題與談二：科學素養X探究與實作',
            startTime: '10:40',
            endTime: '11:30',
            speaker: [
                {
                    name: '何興中',
                    intro: '臺南一中物理教師',
                },
                {
                    name: '陳香吟',
                    intro: '新北市光華國小教師',
                },
            ],
        },
    ],
    speakerImages: [img1, img2, img3, img4],
};

export default data;
