import img1 from 'images/events/8-1.png';
import img2 from 'images/events/8-2.png';

import type { EventData } from './types';

const data: EventData = {
    id: 8,
    category: 'shortTalk',
    title: '讓問題有感且可見，創新藝術教育中的 SDGs 實踐',
    contentTitle: '讓問題有感且可見，創新藝術教育中的 SDGs 實踐',
    date: '12/3',
    startTime: '20:00',
    endTime: '21:00',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '藝術教育不再只是傳統的賞析與繪畫，而是一個帶領各年齡段孩子對變化產生感受並用藝術進行回應的歷程。藝術更是一種將數據、資訊可視化的最佳途徑。在藝術教育中，如何將與生活經驗距離遙遠的SDGs議題融入教學，並帶領孩子用藝術行動回應真實世界問題？講座邀請曾多次於全美藝術教育協會大會NAEA、聯合國教科文組織藝術教育學會大會 InSEA 進行專題發表的吳雨致老師，來分享其獨特的藝術教育中的 SDGs 教育實踐案例。',
    youtubeLiveId: 'QwFEyxdUk8Q',
    youtubeVideoId: 'QwFEyxdUk8Q',
    sponsor: '',
    agenda: [
        {
            title: '開場引言',
            startTime: '20:00',
            endTime: '20:05',
        },
        {
            title: '專題演講 - 創新藝術教育中的 SDGs 實踐案例分享',
            startTime: '20:05',
            endTime: '20:25',
            speaker: [
                {
                    name: '吳雨致',
                    intro: '前精中國際兒童教育研究院副院長',
                },
            ],
        },
        {
            title: '專題與談 - 如何帶領孩子用藝術行動回應真實世界問題',
            startTime: '20:25',
            endTime: '20:45',
            speaker: [
                {
                    name: '李佳穎',
                    intro: '親子天下營運中心品牌策展部資深經理',
                },
                {
                    name: '吳雨致',
                    intro: '前精中國際兒童教育研究院副院長',
                },
                {
                    name: '楊芳宜',
                    intro: '藝術家、廢青不廢創辦人',
                },
            ],
        },
        {
            title: 'QA 時間',
            startTime: '20:45',
            endTime: '21:00',
        },
    ],
    speakerImages: [img1, img2],
};

export default data;
