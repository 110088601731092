import useRedux from 'utils/hooks/useRedux';

import { focusMap, clearMapFocus } from './actions';

import { MapType } from './types';

export const useMapFocus = (type: MapType) =>
    useRedux((state) => state.map[type].focus, {
        focusMap: (data: { x: number; y: number; xOffset: number; yOffset: number }) =>
            focusMap({ type, ...data }),
        clearMapFocus: () => clearMapFocus(type),
    });
