import classnames from 'classnames';

import styles from './index.css';

export interface ButtonRoundProp {
    className?: string;
    text?: string;
    onClick?: () => void;
}

const ButtonRound: React.FC<ButtonRoundProp> = ({ className, text, onClick = () => {} }) => (
    <div
        className={classnames(styles.buttonRound, className)}
        onClick={onClick}
        onKeyPress={() => {}}
        tabIndex={0}
        role="button"
    >
        {text}
    </div>
);

export default ButtonRound;
