import image_6_1 from 'images/pop-wow/6-1.png';
import image_6_2 from 'images/pop-wow/6-2.png';
import image_6_3 from 'images/pop-wow/6-3.png';

export default [
    {
        no: 1,
        layer: 2,
        path: '/pop-wow/intro',
        leave: false,
        src: image_6_1,

        title: '未來說明書',
        enTitle: '',
        width: 98,
        height: 135,
        position: [1390, 1287],
        anchorType: 'dotOrange',

        anchorPosition: [0, -100],
    },
    {
        no: 2,
        layer: 3,
        path: '/pop-wow/faq',
        leave: false,
        src: image_6_2,

        title: '永續教育FAQ',
        enTitle: '',
        width: 506,
        height: 408,
        position: [457, 750],
        anchorType: 'dotOrange',

        anchorPosition: [-20, -70],
    },
    {
        no: 3,
        layer: 4,
        path: '/pop-wow/quide',
        leave: false,
        src: image_6_3,

        title: '未來產生行動指南',
        enTitle: '',
        width: 307,
        height: 253,
        position: [2097, 892],
        anchorType: 'dotOrange',

        anchorPosition: [-25, -50],
    },
];
