import main, { extraData as mainExtra } from './main';
import impactHub from './impactHub';
import startup from './startup';
import seasonart from './seasonart';
import hsbc from './hsbc';
import popWow from './popWow';
import powerUp from './powerUp';
import futureLive from './futureLive';

export default {
    main,
    impactHub,
    startup,
    seasonart,
    hsbc,
    popWow,
    powerUp,
    futureLive,
};

export const extra = {
    main: mainExtra,
    impactHub: [],
    startup: [],
    seasonart: [],
    hsbc: [],
    popWow: [],
    powerUp: [],
    futureLive: [],
};
