import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import Button3D from 'components/atoms/Button3D';
import ButtonPoint from 'components/atoms/ButtonPoint';

import line from 'images/mainTown/line.png';

import { useOpenBuilding } from 'utils/hooks';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface SponsorLineProp {
    className?: string;
}

const SponsorLine: React.FC<SponsorLineProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 2, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const [state, setState] = useState(true);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsorLine, className)}
            active={state}
            title="LINE"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={line} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    LINE以「拉近你我的距離」為企業使命，透過多元行動服務，致力將人們、資訊與社會緊密連結。自2011年6月以通訊軟體的型態推出，現更推展其多元全球生態系，並將觸角延伸至人工智慧、金融科技等領域，積極實現「智慧入口」的願景，2021年3月，LINE與日本網路科技集團Z
                    Holdings正式整併，未來也將持續提供用戶最即時、便利的行動生活。
                </div>
            </div>

            <div className={articleStyles.btnWrapper}>
                <Button3D
                    className={articleStyles.button3D}
                    onClick={() => {
                        setState(false);
                        history.push('/town/events/1');
                    }}
                >
                    觀看 LINE 共同倡議節目
                </Button3D>
                {events.indexOf(1501) === -1 && (
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={1501}
                        msg=""
                    />
                )}
            </div>
        </ModalBaseRight>
    ) : null;
};

export default SponsorLine;
