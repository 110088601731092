import { createAction } from '@reduxjs/toolkit';

import type { ModalType, NotificationType } from './types';

export const openModal =
    createAction<{ type: ModalType; data?: { [key: string]: any } }>('modal/openModal');

export const closeModal = createAction<ModalType>('modal/closeModal');

export const openNotification =
    createAction<{ type: NotificationType; message: string }>('modal/openNotification');

export const closeNotification = createAction<NotificationType>('modal/closeNotification');
