import image1 from 'images/gifts/prize_1.png';
import image2 from 'images/gifts/prize_2.png';
import image3 from 'images/gifts/prize_3.png';
import image4 from 'images/gifts/prize_4.png';
import image5 from 'images/gifts/prize_5.png';
import image8 from 'images/gifts/prize_8.png';
import image9 from 'images/gifts/prize_9.png';
import image10 from 'images/gifts/prize_10.png';
import image11 from 'images/gifts/prize_11.png';
import image12 from 'images/gifts/prize_12.png';
import image13 from 'images/gifts/prize_13.png';
import image14 from 'images/gifts/prize_14.png';
import image15 from 'images/gifts/prize_15.png';
import image16 from 'images/gifts/prize_16.png';
import image17 from 'images/gifts/prize_17.png';
import image18 from 'images/gifts/prize_18.png';
import image19 from 'images/gifts/prize_19.png';
import image20 from 'images/gifts/prize_20.png';
import image21 from 'images/gifts/prize_21.png';
import image22 from 'images/gifts/prize_22.png';
import image23 from 'images/gifts/prize_23.png';
import image24 from 'images/gifts/prize_24.png';
import image25 from 'images/gifts/prize_25.png';
import image26 from 'images/gifts/prize_26.png';
import image27 from 'images/gifts/prize_27.png';
import image28 from 'images/gifts/prize_28.png';
import image29 from 'images/gifts/glg_1.png';
import image30 from 'images/gifts/glg_2.png';
import image31 from 'images/gifts/glg_3.png';
import image32 from 'images/gifts/glg_4.png';
import image33 from 'images/gifts/glg_5.png';
import image34 from 'images/gifts/glg_6.png';
import image35 from 'images/gifts/glg_7.png';
import image36 from 'images/gifts/glg_8.png';
import image37 from 'images/gifts/glg_9.png';
import image38 from 'images/gifts/glg_10.png';
import image39 from 'images/gifts/glg_11.png';
import image40 from 'images/gifts/glg_12.png';
import image41 from 'images/gifts/glg_13.png';
import image42 from 'images/gifts/glg_14.png';
import image43 from 'images/gifts/glg_15.png';
import image44 from 'images/gifts/glg_16.png';
import image45 from 'images/gifts/glg_17.png';
import image46 from 'images/gifts/skin_1.png';
import image47 from 'images/gifts/skin_2.png';
import image48 from 'images/gifts/skin_3.png';
import image49 from 'images/gifts/skin_4.png';
import image50 from 'images/gifts/skin_5.png';
import image51 from 'images/gifts/skin_6.png';
import image52 from 'images/gifts/skin_7.png';
import image53 from 'images/gifts/skin_8.png';
import image54 from 'images/gifts/skin_9.png';
import image55 from 'images/gifts/skin_10.png';

type PriceType = `physical` | `coupon` | `other`;

const data = {
    1: {
        giftId: 1,
        type: `physical`,
        name: `「萬華好Way」復甦禮盒`,
        themeCount: 1,
        amount: 2,
        price: 1250,
        image: image1,
        intro: `經歷了一場史無前例的疾病風暴，讓萬華在地的經濟受到了嚴重衝擊。原先既有的習慣被打亂了，然而居住於此的民眾依然堅守崗位，展現萬華堅韌的生命力。有鑑於此，在地青年、廖俊裕（廖小子）設計師、桔禾創意、Impact Hub Taipei組成團隊，同時也和萬華在地8家老店與青年創業店家協力，打造一份集合萬華優質店家產品的「萬華好Way」禮盒！`,
        themes: {},
    },
    2: {
        giftId: 2,
        type: `physical`,
        name: `【綠藤生機】活萃修護精華露`,
        themeCount: 1,
        amount: 10,
        price: 1080,
        image: image2,
        intro: `是綠藤的第1款水相精華，歷經 550 個日子的研發後面世，即便三次改版，依然是最受綠藤熟客歡迎的產品。不只三度登上國際時裝秀，也是國際綠色美妝金獎的得主。以青花椰苗活萃，打造超越植萃的抗老解答。多些熟悉帶來的持續穩定，少些暗沉與細紋。深入了解來自台灣的國際保養成分，揮別多餘的黏膩。`,
        themes: {},
    },
    3: {
        giftId: 3,
        type: `physical`,
        name: `新光三越禮券$500`,
        themeCount: 1,
        amount: 20,
        price: 500,
        image: image3,
        intro: `新光三越$500元禮券乙張。*本禮券使用規範依新光三越百貨公告內容為主。`,
        themes: {},
    },
    4: {
        giftId: 4,
        type: `physical`,
        name: `【親子天下】雜誌乙本`,
        themeCount: 1,
        amount: 300,
        price: 220,
        image: image4,
        intro: `一本專為家長、教師設計的教育教養生活指南。 2008年8月創刊，為所有關心下一代未來的父母，與所有積極於成長學習的教師、教育界意見領袖們，以最貼近生活經驗的角度，提供更完整、精緻的教育與教養深度報導。 *雜誌期數依實際寄出的為主。`,
        themes: {},
    },
    5: {
        giftId: 5,
        type: `physical`,
        name: `【親子天下】教育年會永續環保好禮組`,
        themeCount: 1,
        amount: 110,
        price: 250,
        image: image5,
        intro: `親子天下教育年會永續環保好禮組，好禮四送二，陪伴您一起迎接2030年美麗永續新世界。
        *永續環保好禮（手機架、杯套、水壺、環保吸管）4送2，依實際寄出為主。`,
        themes: {},
    },
    6: {
        giftId: 6,
        type: `physical`,
        name: `【親子天下】教育年會永續環保好禮組`,
        themeCount: 1,
        amount: 110,
        price: 250,
        image: image5,
        intro: `親子天下教育年會永續環保好禮組，好禮四送二，陪伴您一起迎接2030年美麗永續新世界。
        *永續環保好禮（手機架、杯套、水壺、環保吸管）4送2，依實際寄出為主。`,
        themes: {},
    },

    7: {
        giftId: 7,
        type: `physical`,
        name: `【親子天下】教育年會永續環保好禮組`,
        themeCount: 1,
        amount: 110,
        price: 250,
        image: image5,
        intro: `親子天下教育年會永續環保好禮組，好禮四送二，陪伴您一起迎接2030年美麗永續新世界。
        *永續環保好禮（手機架、杯套、水壺、環保吸管）4送2，依實際寄出為主。`,
        themes: {},
    },

    8: {
        giftId: 8,
        type: `physical`,
        name: `《公民可以很有事》`,
        themeCount: 1,
        amount: 5,
        price: 380,
        image: image8,
        intro: `作者：志祺七七
        時事觀察網紅志祺七七攜懶人包始祖團隊帶你從發現問題，到發揮影響力。
        5大面向×16個精選議題，告訴你如何思考生活周遭各項議題、如何理解他人、如何幫助自己認識這個世界，以及，給邁向未來的你一點有用的成長建議，迎接更具獨立思考的自己吧！`,
        themes: {},
    },

    9: {
        giftId: 9,
        type: `physical`,
        name: `《雙語教育》破除考科思維的20堂雙語課`,
        themeCount: 1,
        amount: 5,
        price: 380,
        image: image9,
        intro: `作者：林子斌、張錦弘
        2030年雙語國家政策上路，究竟中小學雙語教育該怎麼教？ 
        雙語教育師資培育舵手林子斌，理解臺灣第一線教師的困難與挑戰，融合自身國際與本土經驗，提出台灣適用台灣的雙語教育策略及「沃土模式」方法，協助校長有效打造雙語校園、引導教師明確發展課程，與營造家庭的雙語環境。`,
        themes: {},
    },

    10: {
        giftId: 10,
        type: `physical`,
        name: `《線上教學力》`,
        themeCount: 1,
        amount: 5,
        price: 420,
        image: image10,
        intro: `親子天下與台大教授葉丙成共同選編。
        第一本為台灣老師編寫的線上教學實用指引書，透過20位老師的不藏私線上教學經驗分享，從幼兒到高中生課堂，國數社自到體育和美術課，打造有溫度、樂學習的線上教室，超前部署包含混成教育新未來。`,
        themes: {},
    },

    11: {
        giftId: 11,
        type: `physical`,
        name: `《提問力》`,
        themeCount: 1,
        amount: 5,
        price: 420,
        image: image11,
        intro: `作者：藍偉瑩
        「探究」與「素養」不僅是孩子學習的關鍵字，父母與老師更要成為啟動孩子思考的提問者！善用提問，促進聆聽、對話與思考，發現與看見「關鍵理解」。透過提問，能優化解決問題，激發創意潛能，建立互動關係，驅動更好的改變！
        是一本資深教育工作者與師資培育者，針對教育與教養現場的「提問力」深刻體悟之作。`,
        themes: {},
    },

    12: {
        giftId: 12,
        type: `physical`,
        name: `《挺身而教》`,
        themeCount: 1,
        amount: 5,
        price: 420,
        image: image12,
        intro: `作者：全球教師獎得主｜安卓亞．札非拉庫 
        在倫敦的貧民區校園，網路霸凌、幫派暴力、飢餓與匱乏，弱勢學生正面臨政府不願處理的最大危機……。身為一名藝術教師，安卓亞決定發揮所長來幫助這些孩子，打引導他們邁向光明，成功翻轉人生！`,
        themes: {},
    },

    13: {
        giftId: 13,
        type: `physical`,
        name: `雲門教室律動桌遊冒險遊戲盒`,
        themeCount: 1,
        amount: 3,
        price: 650,
        image: image13,
        intro: `「律動桌遊－飛越池塘奔向海洋冒險遊戲盒」讓布卡蛙陪著你，一起從池塘出發，跳入大海探險。透過4種身體能力的挑戰，讓孩子一邊玩，一邊啟動身體的精力，想像力，控制力與平衡感，玩出更多對身體學習的好奇！`,
        themes: {},
    },

    14: {
        giftId: 14,
        type: `physical`,
        name: `雲門教室大青蛙動動樂遊戲組`,
        themeCount: 1,
        amount: 2,
        price: 990,
        image: image14,
        intro: `「大青蛙動動樂身體尋寶遊戲」讓你不只是坐著玩樂，更要打開身體，盡情體驗探索！ 透過精心編想的「動動卡」「愛心卡」「驚奇卡」，彷若抽到大富翁的「機會」「命運」一樣，邊玩，身體一邊接受更多探索與創意的挑戰！`,
        themes: {},
    },

    15: {
        giftId: 15,
        type: `physical`,
        name: `雲門教室布卡蛙口罩親子套組`,
        themeCount: 1,
        amount: 10,
        price: 370,
        image: image15,
        intro: `愛動、愛笑，又愛跳舞的布卡蛙，腿一蹬，身一伸，在口罩上跳舞，陪伴大家每個日常。戴口罩、勤洗手，安心動身體，提升免疫力！
        成人口罩尺寸：17.5x9.5cm／兒童口罩尺寸：14.5x9.5cm 
        *花色依實際寄出為主`,
        themes: {},
    },
    16: {
        giftId: 16,
        type: `physical`,
        name: `3M x賴馬防蟎抱枕（愛哭公主款）`,
        themeCount: 1,
        amount: 6,
        price: 899,
        image: image16,
        intro: `🔶使人印象深刻的賴馬情緒故事系列，讓愛哭公主陪孩子走過情緒風暴～
        🔶透氣性佳，極細纖維孔，乾爽不悶熱
        🔶四季皆可使用
        🔶材質親膚、抱枕大小適中
        🔶賴馬原創故事圖案`,
        themes: {},
    },

    17: {
        giftId: 17,
        type: `physical`,
        name: `3M x賴馬防蟎抱枕（生氣王子款）`,
        themeCount: 1,
        amount: 6,
        price: 899,
        image: image17,
        intro: `🔶使人印象深刻的賴馬情緒故事系列，讓生氣王子陪孩子走過情緒風暴～
        🔶透氣性佳，極細纖維孔，乾爽不悶熱
        🔶四季皆可使用
        🔶材質親膚、抱枕大小適中
        🔶賴馬原創故事圖案`,
        themes: {},
    },

    18: {
        giftId: 18,
        type: `physical`,
        name: `節電小丸子定時器`,
        themeCount: 1,
        amount: 2,
        price: 499,
        image: image18,
        intro: `DOMI綠然為減緩全球暖化，獨家研發「節電小丸子」一款為上班族及辦公室設計的節電定時器，定時功能容易，符合您的生活使用型態，是個一裝即忘 (Plug and forget)的節電好幫手，讓節能生活真真正正走進每個人的家中，讓科技更貼近環境與人們需求，節能很簡單，購買就是回饋社會，節電小丸子讓你輕鬆智慧管理家中電器使用，最簡單智慧的能源管理大師。`,
        themes: {},
    },

    19: {
        giftId: 19,
        type: `physical`,
        name: `兒童能源教育繪本《吃電怪獸在我家》`,
        themeCount: 1,
        amount: 2,
        price: 280,
        image: image19,
        intro: `我們發現繪本是和孩子溝通很好的工具，但現在環境與永續能源的輔助教材卻非常少。 所以，我們規劃了綠能小園丁系列繪本 ，希望能透過孩子的想像力，創造減碳行動力！ 繪本 『吃電怪獸在我家』 ，讓可愛活潑的主角  帶著整個家庭學習節電吧！`,
        themes: {},
    },

    20: {
        giftId: 20,
        type: `physical`,
        name: `SDGs行動手冊`,
        themeCount: 1,
        amount: 30,
        price: 200,
        image: image20,
        intro: `孩子要的永續未來，就從現在開始打造！
        這本手冊收錄2022年度行事曆，節錄國際重要節日及名人佳句，重點摘要SDGs 17項目標精華、個人與家庭行動的check list，同時精選學習資源，透過網站、課程、旅遊、電影推薦等，一起共學、共遊、共賞、共感，希望幫助大人小孩，快速掌握SDGs的永續概念，也能在生活中行動實踐，打造永續新未來！`,
        themes: {},
    },

    21: {
        giftId: 21,
        type: `physical`,
        name: `蔡司專業光學清潔禮盒`,
        themeCount: 1,
        amount: 6,
        price: 800,
        image: image21,
        intro: `全方位防護後疫情新生活，快速清潔鏡片，有效告別霧氣！蔡司專業光學清潔禮盒包含 (1) 專業光學拭鏡紙(50入) (2) 專業光學防霧噴霧組 (3) 蔡司限量款鑰匙圈，可長效72小時與溫差30度內有效防霧，讓清潔、抗菌(99.9%)、防霧一次搞定`,
        themes: {},
    },

    22: {
        giftId: 22,
        type: `coupon`,
        name: `「親子天下「有聲故事書App」免費聽1個月`,
        themeCount: 1,
        amount: 1000,
        price: 149,
        image: image22,
        link: {
            text: '「親子天下有聲書」兌換頁',
            url: 'https://storyapp.parenting.com.tw/payment/month?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `榮獲2020金鼎獎 數位創新獎
        優質故事無限暢聽！提供各類故事帶孩子探索世界，從小培養專注能力。 
        
        恭喜獲得「親子天下有聲書」免費聽1個月
        您的兌換代碼為【oooooo】
        兌換期限至2021/12/20。
        兌換方式：
        1. 進入「親子天下有聲書」兌換頁
        2. 登入您的親子天下會員
        3. 點選方案畫面中桃紅「使用優惠代碼」按鈕，輸入中獎優惠代碼
        * 操作如有任何問題，請見FAQ得獎相關說明`,
        themes: {},
    },

    23: {
        giftId: 23,
        type: `coupon`,
        name: `親子天下「線上學校」100元折扣金`,
        themeCount: 1,
        amount: 1000,
        price: 100,
        image: image23,
        link: {
            text: '「親子天下線上學校」',
            url: 'https://elearning.parenting.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `「親子天下線上學校」友善易吸收的影音課程，不限載具，提供家庭和學校各種學習資源。

        恭喜獲得「親子天下線上學校」$100元折價券
        您的折價券代碼為【oooooo】
        兌換期限至2021/12/20。
        兌換方式：
        1. 進入「親子天下線上學校」選擇欲購買課程
        2. 點擊【立即購買】至付款資訊頁
        3. 點擊綠色【使用折價券】按鈕，輸入折扣序號完成折價
        * 操作如有任何問題，請見FAQ得獎相關說明`,
        themes: {},
    },

    24: {
        giftId: 24,
        type: `coupon`,
        name: `親子天下「Shopping－教育年會 $150元折扣碼`,
        themeCount: 1,
        amount: 4500,
        price: 150,
        image: image24,
        link: {
            text: '*教育創新國際年會限定專屬優惠店',
            url: 'https://shopping.parenting.com.tw/campaigns/teacher/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `親子天下Shopping【教育創新國際年會限定專屬優惠店】中消費滿千可折$150元。
        結帳輸入折扣碼【oooooo】即可抵用，使用期限至2022/1/31止。`,
        themes: {},
    },
    25: {
        giftId: 25,
        type: `other`,
        name: `Good Life Goals`,
        themeCount: 85,
        amount: 0,
        price: 0,
        image: image25,
        intro: `共 85 個美好生活目標任務卡
        一起在日常生活中實踐，讓世界變得更好！`,
        themes: {
            1: {
                themeId: 1,
                name: `Good Life Goals-1`,
                image: image29,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG1 在你能力所及範圍內分享和贈與】 `,
            },
            2: {
                themeId: 1,
                name: `Good Life Goals-2`,
                image: image29,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG1 了解國內外導致貧窮困境的原因】`,
            },
            3: {
                themeId: 1,
                name: `Good Life Goals-3`,
                image: image29,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG1 從薪酬公平的企業購買商品】`,
            },
            4: {
                themeId: 4,
                name: `Good Life Goals-4`,
                image: image29,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG1 負責任的儲蓄、借貸與投資】`,
            },
            5: {
                themeId: 5,
                name: `Good Life Goals-5`,
                image: image29,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG1 讓所有人都能獲得合理的薪酬與工作機會】`,
            },
            6: {
                themeId: 6,
                name: `Good Life Goals-6`,
                image: image30,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG2 購買當地、當季及符合公平貿易條件的食物】`,
            },
            7: {
                themeId: 7,
                name: `Good Life Goals-7`,
                image: image30,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG2 了解如何耕種農作、養殖水產及生產食品】`,
            },
            8: {
                themeId: 8,
                name: `Good Life Goals-8`,
                image: image30,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG2 多食用水果與蔬菜】`,
            },
            9: {
                themeId: 9,
                name: `Good Life Goals-9`,
                image: image30,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG2 讓兒童、老人及孕婦能夠吃得健康】`,
            },
            10: {
                themeId: 10,
                name: `Good Life Goals-10`,
                image: image30,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG2 要求世界各國朝向零飢餓發展】`,
            },
            11: {
                themeId: 11,
                name: `Good Life Goals-11`,
                image: image31,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG3 了解並分享保持健康的方法】`,
            },
            12: {
                themeId: 12,
                name: `Good Life Goals-12`,
                image: image31,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG3 重視心理健康與幸福】`,
            },
            13: {
                themeId: 13,
                name: `Good Life Goals-13`,
                image: image31,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG3 勤洗手並養成規律運動的習慣】`,
            },
            14: {
                themeId: 14,
                name: `Good Life Goals-14`,
                image: image31,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG3 注意交通用路安全】`,
            },
            15: {
                themeId: 15,
                name: `Good Life Goals-15`,
                image: image31,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG3 全民醫療照護普及與疫苗施打的權益】`,
            },
            16: {
                themeId: 16,
                name: `Good Life Goals-16`,
                image: image32,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG4 保持終身學習的態度】`,
            },
            17: {
                themeId: 17,
                name: `Good Life Goals-17`,
                image: image32,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG4 保持終身學習的態度教育孩童友善待人】`,
            },
            18: {
                themeId: 18,
                name: `Good Life Goals-18`,
                image: image32,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG4 保持終身學習的態度幫助男童與女童持續就學】`,
            },
            19: {
                themeId: 19,
                name: `Good Life Goals-19`,
                image: image32,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG4 保持終身學習的態度支持教師並保持學校開放】`,
            },
            20: {
                themeId: 20,
                name: `Good Life Goals-20`,
                image: image32,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG4 保持終身學習的態度捍衛所有人的受教權】`,
            },
            21: {
                themeId: 21,
                name: `Good Life Goals-21`,
                image: image33,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG5 學習並分享防止性別歧視的方法】`,
            },
            22: {
                themeId: 22,
                name: `Good Life Goals-22`,
                image: image33,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG5 讓孩童學會平等待人】`,
            },
            23: {
                themeId: 23,
                name: `Good Life Goals-23`,
                image: image33,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG5 尊重每一位關愛家庭的人】`,
            },
            24: {
                themeId: 24,
                name: `Good Life Goals-24`,
                image: image33,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG5 捍衛婦女的生育權益】`,
            },
            25: {
                themeId: 25,
                name: `Good Life Goals-25`,
                image: image33,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG5 反對針對婦女和女童的暴力】`,
            },
            26: {
                themeId: 26,
                name: `Good Life Goals-26`,
                image: image34,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG6 認識乾淨水源的重要性】`,
            },
            27: {
                themeId: 27,
                name: `Good Life Goals-27`,
                image: image34,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG6 刷牙和清洗時注意節約用水】`,
            },
            28: {
                themeId: 28,
                name: `Good Life Goals-28`,
                image: image34,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG6 不隨意將垃圾及有毒物質沖入下水道】`,
            },
            29: {
                themeId: 29,
                name: `Good Life Goals-29`,
                image: image34,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG6 漏水不分大小，見到就馬上維修】`,
            },
            30: {
                themeId: 30,
                name: `Good Life Goals-30`,
                image: image34,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG6 捍衛所有人使用乾淨水資源及廁所的權利】`,
            },
            31: {
                themeId: 31,
                name: `Good Life Goals-31`,
                image: image35,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG7 了解自己所使用的能源來自何處】`,
            },
            32: {
                themeId: 32,
                name: `Good Life Goals-32`,
                image: image35,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG7 在你能力所及的範圍內節約能源】`,
            },
            33: {
                themeId: 33,
                name: `Good Life Goals-33`,
                image: image35,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG7 使用可再生能源取暖、照明與供電】`,
            },
            34: {
                themeId: 34,
                name: `Good Life Goals-34`,
                image: image35,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG7 從使用可再生能源的企業購買產品】`,
            },
            35: {
                themeId: 35,
                name: `Good Life Goals-35`,
                image: image35,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG7 要求所有人使用乾淨且可負擔的能源】`,
            },
            36: {
                themeId: 36,
                name: `Good Life Goals-36`,
                image: image36,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG8 支持本地的企業】`,
            },
            37: {
                themeId: 37,
                name: `Good Life Goals-37`,
                image: image36,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG8 學習家庭理財的技能】`,
            },
            38: {
                themeId: 38,
                name: `Good Life Goals-38`,
                image: image36,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG8 要求安全的工作環境】`,
            },
            39: {
                themeId: 39,
                name: `Good Life Goals-39`,
                image: image36,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG8 確保所購買之產品沒有於生產過程中剝削】`,
            },
            40: {
                themeId: 40,
                name: `Good Life Goals-40`,
                image: image36,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG8 捍衛所有人的工作權益】`,
            },
            41: {
                themeId: 41,
                name: `Good Life Goals-41`,
                image: image37,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG9 做一位聰明且友善的網路使用者】`,
            },
            42: {
                themeId: 42,
                name: `Good Life Goals-42`,
                image: image37,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG9 了解自身國家的發展規劃】`,
            },
            43: {
                themeId: 43,
                name: `Good Life Goals-43`,
                image: image37,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG9 支持造福人民且保護地球的建設】`,
            },
            44: {
                themeId: 44,
                name: `Good Life Goals-44`,
                image: image37,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG9 歡迎所有讓世界更美好的創新行動】`,
            },
            45: {
                themeId: 45,
                name: `Good Life Goals-45`,
                image: image37,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG9 讓所有人都能共享國家發展所帶來的福祉】`,
            },
            46: {
                themeId: 46,
                name: `Good Life Goals-46`,
                image: image38,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG10 保護並接納弱勢團體】`,
            },
            47: {
                themeId: 47,
                name: `Good Life Goals-47`,
                image: image38,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG10 抱持開放的心態，並傾聽與謙卑學習】`,
            },
            48: {
                themeId: 48,
                name: `Good Life Goals-48`,
                image: image38,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG10 支持領導者推動減少不平等的決策】`,
            },
            49: {
                themeId: 49,
                name: `Good Life Goals-49`,
                image: image38,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG10 從依法納稅且公平待人的企業購買產品】`,
            },
            50: {
                themeId: 50,
                name: `Good Life Goals-50`,
                image: image38,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG10 捍衛自身與他人的權利】`,
            },
            51: {
                themeId: 51,
                name: `Good Life Goals-51`,
                image: image39,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG11 要求安全且優質的公共交通建設】`,
            },
            52: {
                themeId: 52,
                name: `Good Life Goals-52`,
                image: image39,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG11 了解並參與在地的決策】`,
            },
            53: {
                themeId: 53,
                name: `Good Life Goals-53`,
                image: image39,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG11 隨時為災害及緊急狀況做好準備】`,
            },
            54: {
                themeId: 54,
                name: `Good Life Goals-54`,
                image: image39,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG11 結識友好鄰里，開放歡迎新居民】`,
            },
            55: {
                themeId: 55,
                name: `Good Life Goals-55`,
                image: image39,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG11 保護當地樹木、野生動植物和自然環境】`,
            },
            56: {
                themeId: 56,
                name: `Good Life Goals-56`,
                image: image40,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG12 培養重複使用、修理、回收、分享與借用的習慣】`,
            },
            57: {
                themeId: 57,
                name: `Good Life Goals-57`,
                image: image40,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG12 了解永續發展】`,
            },
            58: {
                themeId: 58,
                name: `Good Life Goals-58`,
                image: image40,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG12 減少食物浪費，並善用剩餘食物】`,
            },
            59: {
                themeId: 59,
                name: `Good Life Goals-59`,
                image: image40,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG12 累績朋友與經驗，而非囤積不必要的物品】`,
            },
            60: {
                themeId: 60,
                name: `Good Life Goals-60`,
                image: image40,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG12 要求企業尊重人與地球環境】`,
            },
            61: {
                themeId: 61,
                name: `Good Life Goals-61`,
                image: image41,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG13 步行或騎乘腳踏車，取代開車】`,
            },
            62: {
                themeId: 62,
                name: `Good Life Goals-62`,
                image: image41,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG13 了解氣候變遷的解決方法】`,
            },
            63: {
                themeId: 63,
                name: `Good Life Goals-63`,
                image: image41,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG13 呼籲各國多使用可再生能源】`,
            },
            64: {
                themeId: 64,
                name: `Good Life Goals-64`,
                image: image41,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG13 多吃蔬果少吃肉】`,
            },
            65: {
                themeId: 65,
                name: `Good Life Goals-65`,
                image: image41,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG13 要求領導者立即採取應對氣候變化的果斷措施】`,
            },
            66: {
                themeId: 66,
                name: `Good Life Goals-66`,
                image: image42,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG14 了解海洋生物】`,
            },
            67: {
                themeId: 67,
                name: `Good Life Goals-67`,
                image: image42,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG14 隨時謹記垃圾終將流入水中】`,
            },
            68: {
                themeId: 68,
                name: `Good Life Goals-68`,
                image: image42,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！
                
                【SDG14 拒絕不必要的塑膠製品】`,
            },
            69: {
                themeId: 69,
                name: `Good Life Goals-69`,
                image: image42,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！

                【SDG14 購買永續的魚類和海鮮水產】`,
            },
            70: {
                themeId: 70,
                name: `Good Life Goals-70`,
                image: image42,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG14 要求領導者為海洋污染採取具體行動】`,
            },
            71: {
                themeId: 71,
                name: `Good Life Goals-71`,
                image: image43,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG15 保護當地原生的動植物】`,
            },
            72: {
                themeId: 72,
                name: `Good Life Goals-72`,
                image: image43,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG15 發現大自然的奧妙】`,
            },
            73: {
                themeId: 73,
                name: `Good Life Goals-73`,
                image: image43,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG15 拒絕購買瀕危野生動植物製品】`,
            },
            74: {
                themeId: 74,
                name: `Good Life Goals-74`,
                image: image43,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG15 支持保護與修復大自然的企業】`,
            },
            75: {
                themeId: 75,
                name: `Good Life Goals-75`,
                image: image43,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG15 為遭受威脅的森林和自然環境發聲】`,
            },
            76: {
                themeId: 76,
                name: `Good Life Goals-76`,
                image: image44,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG16 了解並妥善運用自身享有的權利】`,
            },
            77: {
                themeId: 77,
                name: `Good Life Goals-77`,
                image: image44,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG16 抵制權力腐敗與濫用】`,
            },
            78: {
                themeId: 78,
                name: `Good Life Goals-78`,
                image: image44,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG16 待人和善且包容】`,
            },
            79: {
                themeId: 79,
                name: `Good Life Goals-79`,
                image: image44,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG16 支持社會幫扶機構】`,
            },
            80: {
                themeId: 80,
                name: `Good Life Goals-80`,
                image: image44,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！ 
                
                【SDG16 懂得主持公道與捍衛和平】`,
            },
            81: {
                themeId: 81,
                name: `Good Life Goals-81`,
                image: image45,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！                
                
                【SDG17 了解並分享永續發展目標】`,
            },
            82: {
                themeId: 82,
                name: `Good Life Goals-82`,
                image: image45,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！                
                
                【SDG17 支持共創永續發展的合作夥伴】`,
            },
            83: {
                themeId: 83,
                name: `Good Life Goals-83`,
                image: image45,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！                
                
                【SDG17 慶祝我們已經取得的進步】`,
            },
            84: {
                themeId: 84,
                name: `Good Life Goals-84`,
                image: image45,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！                
                
                【SDG17 自願參與社區的事物】`,
            },
            85: {
                themeId: 85,
                name: `Good Life Goals-85`,
                image: image45,
                intro: `領取你的美好生活目標
                一起在日常生活中實踐，讓世界變得更好！                
                
                【SDG17 共同為實現更美好的明天努力】`,
            },
        },
    },
    26: {
        giftId: 26,
        type: `other`,
        name: `SDGs x 108課綱教學設計資源包 `,
        themeCount: 1,
        amount: 0,
        price: 0,
        image: image26,
        link: {
            text: '教學設計資源包',
            url: 'https://edu.parenting.com.tw/event/flipedu_teachingresources-478?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `親子天下嚴選「SDGs永續教育Ｘ108課綱」教學設計資源包 
        為您準各式主題教學資源，提供您源源不絕的教學靈感與最實用工具！`,
        themes: {},
    },
    27: {
        giftId: 27,
        type: `other`,
        name: `永續夥伴共好行動`,
        themeCount: 11,
        amount: 0,
        price: 0,
        image: image27,
        intro: `美好行動目標任務卡
        永續夥伴們與您一起在日常生活中實踐永續美好行動，讓世界變得更好！ `,
        themes: {
            1: {
                themeId: 1,
                name: `STARTUP ISLANDＸ共好行動`,
                image: image27,
                intro: `STARTUP！啟動跨界的創新能量

                出發吧！向未知探索，讓創新融入基因，讓永續成為日常！`,
            },
            2: {
                themeId: 2,
                name: `趨勢科技Ｘ共好行動`,
                image: image27,
                intro: `紮根數位素養，從數位原住民到數位公民

                跟孩子聊聊網路的隱私界線，以及有哪些專業的網路安全工具，可以幫助我們聰明辨別網路危機。`,
            },
            3: {
                themeId: 3,
                name: `LINE台灣Ｘ共好行動`,
                image: image27,
                intro: `科技助攻教育，打造安全數位環境

                在資訊爆炸的數位世代，培養孩子的思辨力是當務之急！和孩子聊聊假訊息為什麼會出現，以及如何破解它吧！`,
            },
            4: {
                themeId: 4,
                name: `HSBC滙豐(台灣)Ｘ共好行動`,
                image: image27,
                intro: `理財教育，裝備孩子想像未來的可能

                和孩子一起擬定壓歲錢計畫吧！透過建立理財素養，讓孩子都能成為夢想的實踐家。`,
            },
            5: {
                themeId: 5,
                name: `四季藝術Ｘ共好行動`,
                image: image27,
                intro: `創客教育，培養改變世界的行動者

                以藝術美學統整孩子的學習，透過設計思考，動手作解決問題的教育方案。`,
            },
            6: {
                themeId: 6,
                name: `KUMONＸ共好行動`,
                image: image27,
                intro: `自主學習力，激發無限可能

                透過學習為孩子奠定扎實的能力、提升自學力，讓他們擁有解決問題的能力與自信，進而擁有無限選擇的未來。`,
            },
            7: {
                themeId: 7,
                name: `雲門舞集舞蹈教室Ｘ共好行動`,
                image: image27,
                intro: `陪伴孩子透過身體認識自己，擁抱世界的多元

                一起為孩子創造情境與想像，透過身體律動探索、表達屬於自己的獨特，建立身心平衡狀態。`,
            },
            8: {
                themeId: 2,
                name: `黑松教育基金會Ｘ共好行動`,
                image: image27,
                intro: `透過教育力量，拉近孩子與土地的距離

                嘿，別忘了好好認識孕育我們的土地！從愛自己、愛別人到愛地球，讓孩子成為愛護環境、守護土地的重要力量。`,
            },
            9: {
                themeId: 9,
                name: `台灣好鄰居協會Ｘ共好行動`,
                image: image27,
                intro: `認識兒童權利，一「童」守護我們的孩子

                孩子是整個社會的重要資產，帶孩子認識自己的權利，懂得保護自己並尊重別人`,
            },
            10: {
                themeId: 10,
                name: `陽光伏特家Ｘ共好行動`,
                image: image27,
                intro: `親身參與太陽能，比關燈更積極的能源行動

                帶孩子認識屋頂上的太陽能吧！攜手參與能源行動，讓我們從能源的消費者變成生產者，也為孩子打造更永續的未來。`,
            },
            11: {
                themeId: 11,
                name: `綠藤生機Ｘ共好行動`,
                image: image27,
                intro: `認識與環境共好的保養主張

                肌膚的保養和環境，真的是二選一嗎？帶孩子一起討論，生活中存在著哪些「太多」」一起從每日的保養，練習「減法」生活學。`,
            },
        },
    },

    28: {
        giftId: 28,
        type: `other`,
        name: `永續x教育 探索新角色`,
        themeCount: 10,
        amount: 0,
        price: 0,
        image: image28,
        intro: `轉換一個樣貌來探索這個城市吧！替換上後別忘了到「明天轉運站」留言，讓大家多看看你！`,
        themes: {
            2801: {
                themeId: 2801,
                name: `未來創新小金童`,
                image: image46,
                note: '閃亮亮變身！',
                intro: `我整個人都金光閃閃～`,
            },
            2802: {
                themeId: 2802,
                name: `未來永續偶像`,
                image: image47,
                note: '萬人迷94我！',
                intro: `是可愛又迷人的...正派角色！`,
            },
            2803: {
                themeId: 2803,
                name: `永續模範生`,
                image: image48,
                note: '城市新星就此誕生！',
                intro: `我就棒 ʕ •ᴥ•ʔ`,
            },
            2804: {
                themeId: 2804,
                name: `城市創新官CIO`,
                image: image49,
                note: '永續未來城的榮耀！',
                intro: `對我來說，輕輕鬆鬆～`,
            },
            2805: {
                themeId: 2805,
                name: `創新冒險王`,
                image: image50,
                note: '星際特攻出動！',
                intro: `教育元宇宙由我來開啟ᕕ ( ᐛ ) ᕗ`,
            },
            2806: {
                themeId: 2806,
                name: `永續司令官`,
                image: image51,
                note: '未來守護者變身！',
                intro: `我們的城市，由我來守護！`,
            },
            2807: {
                themeId: 2807,
                name: `未來建築師`,
                image: image52,
                note: '永續未來的科學！',
                intro: `自己的家、自己的未來，自己造！`,
            },
            2808: {
                themeId: 2808,
                name: `明天藝術家`,
                image: image53,
                note: '藝術大師華麗登場！',
                intro: '每天都要漂漂亮亮(⁎⁍̴̛ᴗ⁍̴̛⁎)',
            },
            2809: {
                themeId: 2809,
                name: `城市領航者`,
                image: image54,
                note: '航向偉大的航道！（上飛船）',
                intro: `飛向宇宙，浩瀚無垠～`,
            },
            2810: {
                themeId: 2810,
                name: `未來大明星`,
                image: image55,
                note: '媽，我上電視了！（上電視）',
                intro: '人人都說，成名只需要15秒，看來我是成名了 (´･ω･`)`',
            },
        },
    },
};

export default data;

export const physicalIdList = Object.keys(data).filter(
    (id) => id !== '5' && id !== '6' && data[id].type === 'physical',
);
export const couponIdList = Object.keys(data).filter((id) => data[id].type === 'coupon');
export const otherIdList = Object.keys(data).filter((id) => data[id].type === 'other');
