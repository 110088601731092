import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { setEnteringView, startTransition } from 'models/mainSite/actions';
import { openNotification, openModal, closeModal } from 'models/modal/actions';

import storage from 'utils/storage';
import { wrapFetch, wrapAuthFetch } from 'utils/api';

import type { CharacterType } from 'data/character';

import type { State } from '../reducers';

export const getUserData = createAsyncThunk('user/getData', async (_, { rejectWithValue }) => {
    const { success, msg, user: userData } = await wrapAuthFetch('/user', { method: 'GET' });

    if (!success) {
        return rejectWithValue(msg);
    }

    const ticketTotalResult = await wrapFetch('/total_used_tickets', {
        method: 'GET',
    });

    if (!ticketTotalResult.success) {
        return rejectWithValue(ticketTotalResult.errorMsg);
    }

    const progress = Math.round((ticketTotalResult.total_used_tickets / 4000) * 100);
    let progressType = 0;

    if (progress >= 100) {
        progressType = 100;
    } else if (progress >= 80) {
        progressType = 80;
    } else if (progress >= 60) {
        progressType = 60;
    } else if (progress >= 40) {
        progressType = 40;
    } else if (progress >= 20) {
        progressType = 20;
    }

    const solveEvent = {
        progressType,
        solve20: userData.events.indexOf(9920) !== -1,
        solve40: userData.events.indexOf(9940) !== -1,
        solve60: userData.events.indexOf(9960) !== -1,
        solve80: userData.events.indexOf(9980) !== -1,
        solve100: userData.events.indexOf(99100) !== -1,
    };

    const {
        success: pointsSuccess,
        msg: pMsg,
        user_ticket: redeemPoints,
    } = await wrapAuthFetch('/user/points', { method: 'GET' });

    if (!pointsSuccess) {
        return rejectWithValue(pMsg);
    }

    return {
        userId: userData.user_code,
        characterType: userData.character,
        name: userData.nickname,
        activeTheme: userData.active_theme,
        themes: userData.themes,
        totalPoints: userData.points,
        redeemPoints,
        availableTicketCount: userData.available_tickets_count,
        gifts: userData.gifts
            .map(({ gift_id, theme_id, code }) => ({
                giftId: gift_id,
                themeId: theme_id,
                code,
            }))
            .filter(({ giftId }) => giftId !== 28),
        shippingInfo: {
            name: userData.shipping_info.name,
            phone: userData.shipping_info.phone,
            email: userData.shipping_info.contact_email,
            dist: userData.shipping_info.dist,
            city: userData.shipping_info.city,
            postalCode: userData.shipping_info.postal_code,
            address: userData.shipping_info.address,
        },
        events: userData.events,
        solveEvent,
    };
});

export const updateUserData = createAction('user/updateData');

export const setCharacterType = createAction<CharacterType>('user/setCharacterType');

export const setName = createAction<string>('user/setName');

export const signup = createAsyncThunk(
    'user/signup',
    async (
        { characterType, name }: { characterType: CharacterType; name: string },
        { getState, dispatch, rejectWithValue },
    ) => {
        const {
            auth: { token },
        } = getState() as State;

        const { success } = await wrapFetch('/user', {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: JSON.stringify({
                nickname: name,
                character: characterType,
            }),
        });

        if (!success) {
            return rejectWithValue('signup failed');
        }

        const { success: setEventSuccess } = await wrapFetch('/user/points', {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: JSON.stringify({
                event: '0',
                points: '0',
            }),
        });

        if (!setEventSuccess) {
            return rejectWithValue('set event failed');
        }

        storage.setItem('token', token);

        dispatch(setEnteringView('complete'));
        dispatch(
            startTransition({
                nextPath: '/town',
            }),
        );

        return true;
    },
);

export const getPointDetail = createAsyncThunk(
    'user/getPointDetail',
    async (_, { rejectWithValue }) => {
        const {
            success,
            msg,
            total_points: totalPoints,
            user_ticket: redeemPoints,
        } = await wrapAuthFetch('/user/points', { method: 'GET' });

        if (!success) {
            return rejectWithValue(msg);
        }

        return {
            totalPoints,
            redeemPoints,
        };
    },
);

export const getPoint = createAsyncThunk(
    'user/getPoint',
    async (
        { points, eventId, msg }: { points: number; eventId?: number; msg: string },
        { dispatch, rejectWithValue },
    ) => {
        const {
            success,
            msg: errorMsg,
            total_points: totalPoints,
        } = await wrapAuthFetch('/user/points', {
            method: 'POST',
            body: JSON.stringify({
                event: `${eventId}`,
                points: points === 0 ? '0' : points,
            }),
        });

        if (!success) {
            return rejectWithValue(errorMsg);
        }

        dispatch(
            openNotification({
                type: 'points',
                message: msg,
            }),
        );

        return {
            totalPoints,
            eventId,
        };
    },
);

export const getTicket = createAsyncThunk(
    'user/getTicket',
    async (pointNum, { dispatch, rejectWithValue }) => {
        const {
            success,
            msg: errorMsg,
            user_ticket: redeemPoints,
        } = await wrapAuthFetch('/user/ticket', {
            method: 'POST',
            body: JSON.stringify({
                point: pointNum,
            }),
        });

        if (!success) {
            return rejectWithValue(errorMsg);
        }

        dispatch(
            openNotification({
                type: 'default',
                message: '兌換成功',
            }),
        );

        return {
            redeemPoints,
        };
    },
);

export const updateCloth = createAsyncThunk(
    'user/updateCloth',
    async (
        { clothType, clothId }: { clothType: 'theme' | 'character'; clothId: number },
        { dispatch, rejectWithValue },
    ) => {
        const { success, msg: errorMsg } = await wrapAuthFetch('/user/theme', {
            method: 'PUT',
            body: JSON.stringify({
                theme: clothType === 'character' ? 1 : clothId,
            }),
        });

        if (!success) {
            return rejectWithValue(errorMsg);
        }

        dispatch(
            openNotification({
                type: 'default',
                message: '更換成功',
            }),
        );

        return {
            clothType,
            clothId,
        };
    },
);

export const useTicket = createAsyncThunk(
    'user/ticket/apply',
    async (_, { dispatch, rejectWithValue }) => {
        const {
            success,
            msg: errorMsg,
            available_tickets_count: availableTicketsCount,
            gift: { gift_id: giftId, gift_theme_id: themeId, code },
        } = await wrapAuthFetch('/user/ticket/apply', {
            method: 'POST',
            body: JSON.stringify({}),
        });

        if (!success) {
            return rejectWithValue(errorMsg);
        }

        if (giftId === 28) {
            const { success: themeSuccess, msg: themeErrorMsg } = await wrapAuthFetch(
                '/user/theme',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        theme: themeId + 2800,
                    }),
                },
            );

            if (!themeSuccess) {
                return rejectWithValue(themeErrorMsg);
            }
        }

        dispatch(
            openModal({
                type: 'giftNew',
                data: {
                    giftId,
                    themeId: giftId === 28 ? themeId + 2800 : themeId,
                    code: giftId === 24 ? 'educate15' : code,
                },
            }),
        );

        return {
            availableTicketsCount,
            giftId,
            themeId,
        };
    },
);

export const getTotalUsedTicket = createAsyncThunk(
    '/user/getSiteTotal',
    async (_, { rejectWithValue, getState }) => {
        const {
            success,
            msg: errorMsg,
            total_used_tickets: total,
        } = await wrapFetch('/total_used_tickets', {
            method: 'GET',
        });

        if (!success) {
            return rejectWithValue(errorMsg);
        }

        const progress = Math.round((total / 4000) * 100);
        let progressType = 0;

        if (progress >= 100) {
            progressType = 100;
        } else if (progress >= 80) {
            progressType = 80;
        } else if (progress >= 60) {
            progressType = 60;
        } else if (progress >= 40) {
            progressType = 40;
        } else if (progress >= 20) {
            progressType = 20;
        }

        const {
            user: { events },
        } = getState();

        return {
            total,
            solveEvent: {
                progressType,
                solve20: events.indexOf(9920) !== -1,
                solve40: events.indexOf(9940) !== -1,
                solve60: events.indexOf(9960) !== -1,
                solve80: events.indexOf(9980) !== -1,
                solve100: events.indexOf(99100) !== -1,
            },
        };
    },
);

export const updateBagView = createAction<'bag' | 'form' | 'info' | 'complete'>(
    'user/updateBagView',
);

export const resetUser = createAction('user/reset');

export const updateShippingInfo = createAsyncThunk(
    'user/updateShippingInfo',
    async (
        { name, phone, email, dist, city, postalCode, address },
        { dispatch, rejectWithValue },
    ) => {
        const result = await wrapAuthFetch('/user/info', {
            method: 'PUT',
            body: JSON.stringify({
                name,
                phone,
                contact_email: email,
                dist,
                city,
                postal_code: postalCode,
                address,
            }),
        });

        if (!result.success) {
            return rejectWithValue(result.errorMsg);
        }

        dispatch(
            openNotification({
                type: 'default',
                message: '儲存成功',
            }),
        );
        dispatch(updateBagView('bag'));

        return {
            name: result.name,
            phone: result.phone,
            email: result.contact_email,
            city: result.city,
            dist: result.dist,
            postalCode: result.postal_code,
            address: result.address,
        };
    },
);

export const solveEvent = createAsyncThunk(
    'user/solveEvent',
    async (type, { getState, dispatch, rejectWithValue }) => {
        const {
            auth: { loginType },
        } = getState();

        if (loginType !== 'facebook') {
            dispatch(
                openModal({
                    type: 'needLogin',
                    data: {
                        solveEvent: true,
                    },
                }),
            );
        } else {
            await wrapAuthFetch('/user/points', {
                method: 'POST',
                body: JSON.stringify({
                    event: type === 100 ? 99100 : 9900 + type,
                    points: '0',
                }),
            });

            return type;
        }

        return null;
    },
);
