// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__sponsor-trend___2D5R1 {\n}\n", "",{"version":3,"sources":["webpack://./src/layouts/SponsorTrend/index.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".sponsor-trend {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsor-trend": "index__sponsor-trend___2D5R1",
	"sponsorTrend": "index__sponsor-trend___2D5R1"
};
export default ___CSS_LOADER_EXPORT___;
