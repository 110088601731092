import classnames from 'classnames';

import npc from 'images/common/npc.png';

import styles from './index.css';

export interface NpcMessageProp {
    className?: string;
    message: string;
}

const NpcMessage: React.FC<NpcMessageProp> = ({ className, message }) => (
    <div className={classnames(styles.npcMessage, className)}>
        <div className={styles.avatar}>
            <img src={npc} alt="avatar" />
        </div>
        <div
            className={styles.text}
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {message}
        </div>
    </div>
);

export default NpcMessage;
