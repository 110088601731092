import { useState, useEffect } from 'react';
import { useMedia as useNewBreakpoint } from 'react-use';

type Media = 'desktop' | 'phone';

const useMedia: () => Media = () => {
    const [stageMedia, setStageMedia] = useState<Media>('desktop');

    const matchDesktop = useNewBreakpoint('(min-width: 900px)');

    useEffect(() => {
        if (matchDesktop) {
            setStageMedia('desktop');
        } else {
            setStageMedia('phone');
        }
    }, [matchDesktop]);

    return stageMedia;
};

export default useMedia;
