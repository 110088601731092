import { createReducer } from '@reduxjs/toolkit';

import { fetchMessages, likeMessage, getLastCreate } from './actions';

interface Message {
    messageId: number;
    likes: number;
    author: string;
    authorCharacterType: number;
    authorActiveTheme: number;
    createTime: string;
    content: string;
    liked: boolean;
}

export interface State {
    loading: boolean;
    currentPage: number;
    totalPage: number;
    messageCount: number;
    messages: number[];
    messagesData: { [key: string]: Message };
    lastCreateTime: null | string;
}

const initialState: State = {
    loading: false,
    currentPage: 0,
    totalPage: 0,
    messageCount: 0,
    messages: [],
    messagesData: {},
    lastCreateTime: null,
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchMessages.fulfilled, (state, action) => {
            state.currentPage = action.payload.page;
            state.totalPage = action.payload.totalPage;
            state.messageCount = action.payload.messageCount;

            state.loading = false;

            if (action.payload.page === 1) {
                state.messages = action.payload.messages;
                state.messagesData = action.payload.messagesData;
            } else {
                state.messages = [...state.messages, ...action.payload.messages];
                state.messagesData = { ...state.messagesData, ...action.payload.messagesData };
            }
        })
        .addCase(fetchMessages.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchMessages.rejected, (state) => {
            state.loading = false;
        })
        .addCase(likeMessage.fulfilled, (state, action) => {
            state.messagesData[action.payload.messageId].liked = action.payload.like;

            if (action.payload.like) {
                state.messagesData[action.payload.messageId].likes += 1;
            } else {
                state.messagesData[action.payload.messageId].likes -= 1;
            }
        })
        .addCase(getLastCreate.fulfilled, (state, action) => {
            state.lastCreateTime = action.payload;
        });
});

export default reducer;
