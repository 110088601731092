import img1 from 'images/events/5-1.png';
import img2 from 'images/events/5-2.png';
import img3 from 'images/events/5-3.png';
import img4 from 'images/events/5-4.png';

import type { EventData } from './types';

const data: EventData = {
    id: 5,
    category: 'shortTalk',
    title: '遊戲式學習在教育上的應用',
    contentTitle: '遊戲式學習在教育上的應用',
    date: '12/1',
    startTime: '14:00',
    endTime: '15:30',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '「遊戲式學習」以學習者為中心，在遊戲中提升自主學習的內在動機與樂趣。本場邀請致力於遊戲式學習的企業，分享各種具創意且成功的遊戲學習應用方案，不論是科學與魔法的結合、用遊戲學程式、是助教也是玩伴的教育機器人，或是高度互動共玩的益智玩具等，讓教師或家長在有效的輔助下，輕鬆的讓孩子擁有如同玩遊戲般雀躍的學習體驗。',
    youtubeLiveId: 'jXnRU_xWsEE',
    youtubeVideoId: 'jXnRU_xWsEE',
    sponsor: '本場活動指導單位，國家發展委員會、Startup Island TAIWAN ',
    agenda: [
        {
            title: '開場',
            startTime: '14:00',
            endTime: '14:05',
        },
        {
            title: '遊戲式學習的應用分享及對教學的輔助',
            startTime: '14:05',
            endTime: '15:05',
            speaker: [
                {
                    name: '郭柳宗',
                    intro: '女媧創造創辦人',
                },
                {
                    name: '蔡蓬淵',
                    intro: '塊樂坊程式創辦人',
                },
                {
                    name: '周代祥',
                    intro: '菁英魔法學院創辦人',
                },
                {
                    name: '徐煊博',
                    intro: '程式老爹負責人',
                },
            ],
        },
        {
            title: 'QA 時間',
            startTime: '15:05',
            endTime: '15:30',
        },
    ],
    speakerImages: [img1, img2, img3, img4],
};

export default data;
