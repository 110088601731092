import image7_1 from 'images/power-up/7-1.png';
import image7_2 from 'images/power-up/7-2.png';
import image7_3 from 'images/power-up/7-3.png';
import image7_4 from 'images/power-up/7-4.png';
import image7_5 from 'images/power-up/7-5.png';
import image7_6 from 'images/power-up/7-6.png';

// 44
// 66
export default [
    {
        no: 1,
        layer: 1,
        path: '/power-up/intro',
        leave: false,
        src: image7_1,

        title: '永續燃料補給站',
        enTitle: '',
        width: 390,
        height: 375,
        position: [2094, 1172],
        anchorType: 'dotOrange',

        anchorPosition: [-190, 30],
    },
    {
        no: 2,

        layer: 3,
        path: '/power-up/education',
        leave: false,
        src: image7_2,

        title: 'SDGs兒童永續書房@天下',
        enTitle: '',
        width: 332,
        height: 360,
        position: [363, 654],
        anchorType: 'dotOrange',

        anchorPosition: [0, 50],
    },
    {
        no: 3,

        layer: 2,
        path: '/power-up/environment',
        leave: false,
        src: image7_3,

        title: '環境永續 - 主題選書',
        enTitle: '',
        width: 639,
        height: 753,
        position: [629, 27],
        anchorType: 'dotOrange',

        anchorPosition: [0, 210],
    },
    {
        no: 4,

        layer: 6,
        path: '/power-up/economic',
        leave: false,
        src: image7_5,

        title: '',
        enTitle: '經濟發展 - 主題選書',
        width: 373,
        height: 313,
        position: [1211, 1206],
        anchorType: 'dotOrange',

        anchorPosition: [0, 50],
    },
    {
        no: 5,

        layer: 5,
        path: '/power-up/social',
        leave: false,

        src: image7_4,

        title: '社會公平 - 主題選書',
        enTitle: '',
        width: 833,
        height: 596,
        position: [1202, 413],
        anchorType: 'dotOrange',

        anchorPosition: [20, 150],
    },
    {
        no: 6,

        layer: 6,
        link: 'https://shopping.parenting.com.tw/campaigns/teacher/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        leave: false,
        src: image7_6,

        title: '永續x教育精選書展',
        enTitle: '',
        width: 705,
        height: 504,
        position: [2042, 582],
        anchorType: 'dotOrange',

        anchorPosition: [0, 100],
    },
];
