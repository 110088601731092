import image1 from 'images/gifts/1-rank.png';
import image2 from 'images/gifts/2-rank.png';
import image3 from 'images/gifts/3-rank.png';
import image4 from 'images/gifts/4-rank.png';
import image5 from 'images/gifts/5-rank.png';
import image6 from 'images/gifts/6-rank.png';

export default {
    1: {
        giftId: 1,
        type: 'rank',
        name: 'Apple iPhone 13',
        amount: 1,
        image: image1,
        intro: 'iPhone13採用 A15 Bionic 仿生晶片，搭配最先進的雙相機系統，超廣角鏡頭加上新的感光元件，增加相機日夜拍攝的表現，且電池續航力最長可增加2.5 小時，給您最有感的升級體驗。',
    },
    2: {
        giftId: 2,
        type: 'rank',
        name: '煙波大飯店台南館豪華家庭房住宿券',
        amount: 3,
        image: image2,
        intro: `運用現代時尚的設計手法，絨質地板及可供靈活運用的臥榻，營造悠然度假自在感，讓您與家人、好友隨心共享美好假期。
        *本房型房最多入住4人。住宿券為平日住宿券，平假日之定義及使用期限及限制詳如券上所載。相關權益及服務以煙波大飯店台南館公告為主。`,
    },
    3: {
        giftId: 3,
        type: 'rank',
        name: `Kobo Libra2 \n7吋電子書閱讀器`,
        amount: 1,
        image: image3,
        link: {
            text: '商品連結',
            url: 'https://24h.pchome.com.tw/books/prod/DJBO00-A900BVXY8?gclid=Cj0KCQiAys2MBhDOARIsAFf1D1cPhPDP2qy_nGrQNFukTg0FWmlOd8Dor7XwTnTdi3VyV9FMPPqT9RAaAi-qEALw_wcB&utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `．7 吋最新 E Ink 防眩光螢幕，解析度 1264×1680（300ppi），搭配實體翻頁鍵與螢幕四面旋轉功能
        ．支援藍牙裝置，配對藍牙耳機或喇叭聆聽有聲書
        ．儲存空間 32GB，可容納多達 24,000 本電子書或 150 本有聲書＊依照購買書籍容量，儲放本數有所不同
        ．內建 ComfortLight PRO 功能，可自動調整螢幕亮度與色溫
        ．IPX8 高階防水功能（(水深2m/ 最長60分鐘)
        ．支援OverDrive 與 Pocket 功能"`,
    },
    4: {
        giftId: 4,
        type: 'rank',
        name: '綠藤生機-永續保養體驗組',
        amount: 1,
        image: image4,
        intro: `綠藤相信「對的，不用多」保養從減法開始。極境雙藻復原精華，原料源自極端環境，打造根基於復原的細緻明亮，更通過肌膚敏感性權威考驗；奇蹟辣木油，以麻省理工獨家冷壓技術，打造只有一個成分的油保養經典。除了透過公平貿易協同打造非洲最大有機辣木生態圈外，瓶身為50%再生玻璃，持續挑戰讓肌膚保養更為永續的可能。`,
    },
    5: {
        giftId: 5,
        type: 'rank',
        name: 'Kobo Nia \n6吋電子書閱讀器',
        amount: 2,
        image: image5,
        intro: `．6吋HD Carta E Ink 防眩光螢幕，解析度 1024x758（212ppi）
        ．重量僅172克，輕薄好攜帶
        ．8GB儲存空間可收藏6000本書＊依照購買書籍容量，儲放本數有所不同
        ．內建 ComfortLight，可調整螢幕亮度
        ．支援OverDrive 與 Pocket 功能`,
    },
    6: {
        giftId: 6,
        type: 'rank',
        name: 'REX拼布恐龍盒（三角龍）',
        amount: 2,
        image: image6,
        link: {
            text: '商品連結',
            url: 'https://shopping.parenting.com.tw/products/3450?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        },
        intro: `創意手作 x 永續時尚
        臺灣首度大規模庫存布料回收：「想像力計畫」
        拼布恐龍盒裡面有：1隻環保PVC材質白色恐龍、10片精選機能布塊、1罐保麗龍膠、3枝大豆蠟筆
        創意大爆發！來幫恐龍穿新衣！`,
    },
};
