import { useRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { gsap, Linear } from 'gsap';

import styles from './index.css';

export interface LoadingTextProp {
    className?: string;
    active?: boolean;
    onDestroy?: () => void;
}

const LoadingText: React.FC<LoadingTextProp> = ({
    className,
    active = true,
    onDestroy = () => {},
}) => {
    const masterTimeline = useRef<gsap.core.Timeline | null>(null);
    const textTimeline = useRef<gsap.core.Timeline | null>(null);
    const dotsTimeline = useRef<gsap.core.Timeline | null>(null);
    const fadeoutTimeline = useRef<gsap.core.Timeline | null>(null);

    const endDelay = useRef<number | null>(null);

    const root = useRef<HTMLDivElement>(null);
    const letterL = useRef<HTMLSpanElement>(null);
    const letterO = useRef<HTMLSpanElement>(null);
    const letterA = useRef<HTMLSpanElement>(null);
    const letterD = useRef<HTMLSpanElement>(null);
    const letterI = useRef<HTMLSpanElement>(null);
    const letterN = useRef<HTMLSpanElement>(null);
    const letterG = useRef<HTMLSpanElement>(null);
    const dot1 = useRef<HTMLSpanElement>(null);
    const dot2 = useRef<HTMLSpanElement>(null);
    const dot3 = useRef<HTMLSpanElement>(null);

    const [animeComplete, setAnimeComplete] = useState(true);

    const setup = useCallback(() => {
        masterTimeline.current = gsap.timeline({
            paused: true,
        });

        textTimeline.current = gsap
            .timeline({
                defaults: {
                    ease: Linear.easeNone,
                },
            })
            .timeScale(1.5)
            .to(letterL.current, {
                opacity: 1,
            })
            .to(
                letterO.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                letterA.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                letterD.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                letterI.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                letterN.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                letterG.current,
                {
                    opacity: 1,
                },
                '>',
            );

        dotsTimeline.current = gsap
            .timeline({
                defaults: {
                    ease: Linear.easeNone,
                },
            })
            .yoyo(true)
            .repeat(-1)
            .to(dot1.current, {
                opacity: 1,
            })
            .to(
                dot2.current,
                {
                    opacity: 1,
                },
                '>',
            )
            .to(
                dot3.current,
                {
                    opacity: 1,
                    onComplete: () => {
                        if (endDelay.current) {
                            window.clearTimeout(endDelay.current);
                            endDelay.current = null;
                        }

                        endDelay.current = window.setTimeout(() => {
                            setAnimeComplete(true);
                            endDelay.current = null;
                        }, 500);
                    },
                },
                '>',
            );

        fadeoutTimeline.current = gsap
            .timeline({
                paused: true,
                onComplete: onDestroy,
                defaults: {
                    ease: Linear.easeNone,
                },
            })
            .timeScale(1.5)
            .to(root.current, {
                opacity: 0,
            });

        masterTimeline.current.add(textTimeline.current).add(dotsTimeline.current, '>');
    }, []);

    const destroy = useCallback(() => {
        if (masterTimeline.current) {
            masterTimeline.current.kill();
            masterTimeline.current = null;
        }

        if (fadeoutTimeline.current) {
            fadeoutTimeline.current.kill();
            fadeoutTimeline.current = null;
        }

        if (endDelay.current) {
            window.clearTimeout(endDelay.current);
            endDelay.current = null;
        }
    }, []);

    useEffect(() => {
        setup();

        return destroy;
    }, []);

    useEffect(() => {
        if (active && animeComplete) {
            if (masterTimeline.current) {
                masterTimeline.current.play();
                setAnimeComplete(false);
            }
        }

        if (!active && animeComplete) {
            if (endDelay.current) {
                window.clearTimeout(endDelay.current);
                endDelay.current = null;
            }

            endDelay.current = window.setTimeout(() => {
                if (masterTimeline.current) {
                    masterTimeline.current.pause();
                }

                if (fadeoutTimeline.current) {
                    fadeoutTimeline.current.play();
                }

                endDelay.current = null;
            }, 500);
        }
    }, [active, animeComplete]);

    return (
        <div ref={root} className={classnames(styles.loadingText, className)}>
            <span ref={letterL}>L</span>
            <span ref={letterO}>o</span>
            <span ref={letterA}>a</span>
            <span ref={letterD}>d</span>
            <span ref={letterI}>i</span>
            <span ref={letterN}>n</span>
            <span ref={letterG}>g</span>
            <span ref={dot1} className={styles.dot1}>
                .
            </span>
            <span ref={dot2}>.</span>
            <span ref={dot3}>.</span>
        </div>
    );
};

export default LoadingText;
