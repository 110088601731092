import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter } from 'connected-react-router';
import InApp from 'detect-inapp';

import store, { history } from 'models/store';
import routes from 'routes';

import { loadFacebookLibrary } from 'utils/external';

import App from 'layouts/App';

import 'normalize.css';
import 'swiper/swiper.min.css';

import './global.css';

const renderApp = () => {
    loadFacebookLibrary();

    const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

    if (/Line/.test(navigator.userAgent)) {
        location.href = `${location.href}${
            location.search ? '&openExternalBrowser=1' : '?openExternalBrowser=1'
        }`;
    } else if (inapp.isInApp) {
        const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i);
        const isAndroid = navigator.userAgent.match(/Android/i);

        if (isIos) {
            window.location.href = window.location.href.replace('https://', 'googlechrome://');
        }

        if (isAndroid) {
            window.location.href = window.location.href.replace(
                'https://',
                'googlechrome://navigate?url=',
            );
        }
    }

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App>
                    <Suspense fallback={<></>}>{renderRoutes(routes)}</Suspense>
                </App>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('app'),
    );
};

// force browser reload when using browser back button to the app
window.addEventListener('pageshow', (event) => {
    const historyTraversal =
        event.persisted ||
        (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);

    if (historyTraversal) {
        window.location.reload();
    } else {
        renderApp();
    }
});
