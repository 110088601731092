import { useState, useEffect, useRef } from 'react';
import { useList } from 'react-use';

type UsePreloadImages = (imageUrls: string[]) => { completed: boolean; progress: number };

const usePreloadImages: UsePreloadImages = (imageUrls = []) => {
    const [imageList, { push, set }] = useList<HTMLImageElement>([]);
    const loadedCountRef = useRef<number>(0);
    const [loadingCount, setLoadingCount] = useState(0);

    useEffect(() => {
        const onload = () => {
            const nextCount = loadedCountRef.current + 1;
            loadedCountRef.current = nextCount;
            setLoadingCount(nextCount);
        };

        const onError = () => {
            const nextCount = loadedCountRef.current + 1;
            loadedCountRef.current = nextCount;
            setLoadingCount(nextCount);
        };

        imageUrls.forEach((url) => {
            const image = new Image();
            image.src = url;
            image.addEventListener('load', onload);
            image.addEventListener('error', onError);
            push(image);
        });

        return () => {
            imageList.forEach((image) => {
                image.removeEventListener('load', onload);
                image.removeEventListener('error', onError);
                loadedCountRef.current = 0;
                set([]);
            });
        };
    }, []);

    return {
        completed: imageUrls.length !== 0 && loadingCount === imageUrls.length,
        progress: imageUrls.length !== 0 ? (loadingCount / imageUrls.length) * 100 : 0,
    };
};

export default usePreloadImages;
