import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/sponsor-main-8.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface Sponsor8Prop {
    className?: string;
}

const Sponsor8: React.FC<Sponsor8Prop> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 8, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsor8, className)}
            title="陽光伏特家"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    陽光伏特家是臺灣第一間全民電廠及綠電售電業者，致力讓民眾在能源轉型的道路上，找到簡單參與的方式與經濟價值。
                    2016年創立全民電廠平台，讓民眾輕鬆出資太陽能板，從中獲得經濟收益，促成能源轉型，留給下一代永續不竭的能源。
                    目前已經有超過3萬名民眾參與，完成300座全民電廠，邀請你認識陽光伏特家，攜手為孩子打造更永續的未來。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="https://sunnyfounder.com/individual/civic-influence?utm_medium=referral&utm_source=edu.parenting.com.tw&utm_campaign=parenting_cw"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '陽光伏特家 --- 了解更多',
                            });
                        }}
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(2101) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={2101}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default Sponsor8;
