import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { history } from 'models/store';

import { wrapFetch, wrapAuthFetch } from 'utils/api';
import storage from 'utils/storage';
import { FBResponse } from 'utils/external';

import {
    setEnteringView,
    startTransition,
    setFirstVisit,
    setLoading,
    clearRedirectPath,
} from 'models/mainSite/actions';
import { getUserData, resetUser } from 'models/user/actions';

import type { State } from '../reducers';

import type { LoginType } from './types';

const { FACEBOOK_ID } = process.env;

const facebookNativeLogin = () => {
    return new Promise<FBResponse>((resolve, reject) => {
        if (!window.isFbSDKInit) {
            window.FB.init({
                appId: FACEBOOK_ID,
                xfbml: true, // parse social plugins on this page
                version: 'v12.0', // use graph api version 12
            });
            window.isFbSDKInit = true;
        }

        window.FB.login(
            (response: FBResponse) => {
                if (response.status === 'connected') {
                    resolve(response);
                } else {
                    reject(response);
                }
            },
            { scope: 'email' },
        );
    });
};

const typeMap: Record<LoginType, number> = {
    facebook: 1,
    visitor: 2,
    enVisitor: 3,
    notLogin: -1,
};

export const login = createAsyncThunk<
    { type: LoginType; token?: string },
    { type: LoginType; callback?: () => void }
>('auth/login', async ({ type, callback = () => {} }, { getState, dispatch, rejectWithValue }) => {
    let jwtToken = '';
    let accessToken = '';

    if (type === 'facebook') {
        const auth = await facebookNativeLogin();

        if (auth.authResponse == null) {
            return rejectWithValue('facebook login failed');
        }
        const { accessToken: fbToken } = auth.authResponse;
        accessToken = fbToken;
    }

    const result = await wrapFetch('/login', {
        method: 'POST',
        body: JSON.stringify({
            type: typeMap[type],
            code: accessToken,
        }),
    });

    const { success: loginSuccess, token: tokenFromApi } = result;

    if (!loginSuccess) {
        return rejectWithValue('jwt login failed');
    }

    const {
        mainSite: { redirectPath },
    } = getState();

    if (type === 'facebook') {
        jwtToken = tokenFromApi;

        const { success: getUserSuccess, user: userData } = await wrapFetch('/user', {
            method: 'GET',
            headers: {
                authorization: tokenFromApi,
            },
        });

        if (!getUserSuccess) {
            return rejectWithValue('get user data failed');
        }

        if (userData.events.length === 0) {
            dispatch(setEnteringView('characterSelect'));
            dispatch(setFirstVisit(true));
        } else {
            storage.setItem('token', jwtToken);
            dispatch(getUserData());
            dispatch(setEnteringView('complete'));
            dispatch(
                startTransition({
                    nextPath: redirectPath || '/town',
                }),
            );
        }
    } else if (type === 'visitor') {
        dispatch(setEnteringView('complete'));
        dispatch(
            startTransition({
                nextPath: '/town',
            }),
        );
        dispatch(setFirstVisit(true));
    } else {
        // enVistor
        dispatch(setEnteringView('complete'));
        dispatch(
            startTransition({
                nextPath: '/startup/en',
            }),
        );
        dispatch(setFirstVisit(true));
    }

    dispatch(clearRedirectPath());

    callback();

    return { type, token: jwtToken };
});

export const logout = createAsyncThunk('auth/logout', async (_, { getState, dispatch }) => {
    const {
        auth: { loginType },
    } = getState() as State;

    dispatch(resetUser());

    if (loginType === 'facebook') {
        if (!window.isFbSDKInit) {
            window.FB.init({
                appId: FACEBOOK_ID,
                xfbml: true, // parse social plugins on this page
                version: 'v12.0', // use graph api version 12
            });
            window.isFbSDKInit = true;
        }

        window.FB.logout();
        storage.removeItem('token');
    }

    dispatch(setEnteringView('relogin'));
    history.push('/');

    // dispatch(
    //     startTransition({
    //         nextPath: '/',
    //     }),
    // );

    // dispatch(setLoading(false));
});

export const setLoginType = createAction<LoginType>('auth/setLoginType');
