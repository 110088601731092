import useRedux from 'utils/hooks/useRedux';

import { login, logout, setLoginType } from './actions';

export const useAuth = () =>
    useRedux((state) => state.auth, {
        login,
        logout,
        setLoginType,
    });
