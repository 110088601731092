import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/sponsor-main-6.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface Sponsor6Prop {
    className?: string;
}

const Sponsor6: React.FC<Sponsor6Prop> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 6, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsor6, className)}
            title="好鄰居超人學校"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    Good Neighbors (好鄰居)
                    是以人為本的國際救援及發展非政府組織，並獲得聯合國經濟及社會理事會全面諮詢地位服務。我們的工作分佈於45個國家，進行社區發展工作，特別致力保護兒童權利。
                    台灣好鄰居於2019年成立，在台灣透過進班教學和超人學校數位學習平台，致力於落實兒童的權利。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="http://www.gnsuperman.school/#/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(1901) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={1901}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default Sponsor6;
