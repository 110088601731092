import { useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { isWindows } from 'react-device-detect';
import { gsap, Power2 } from 'gsap';
import { useHistory } from 'react-router-dom';

import Back from 'images/icons/arrow-forward.inline.svg';
import Logo from 'images/common/logo.inline.svg';

import styles from './index.css';

export interface ModalBaseRightProp {
    className?: string;
    topClassName?: string;
    childrenClassName?: string;
    logo?: boolean;
    title?: string;
    enTitle?: string;
    fixTop?: boolean;
    small?: boolean;
    active?: boolean;
    timeScale?: number;
    onCloseFinish?: () => void;
    disabled?: boolean;
    disabledText?: string;
}

const ModalBaseRight: React.FC<ModalBaseRightProp> = ({
    className,
    topClassName,
    childrenClassName,
    active = true,
    logo = false,
    title,
    enTitle,
    small = false,
    menu = false,
    fixTop = true,
    timeScale = 0.8,
    onCloseFinish = () => {},
    children,
    disabled = false,
    disabledText = '',
}) => {
    const {
        location: { pathname },
    } = useHistory();

    const isEn = pathname.includes('/en');

    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const maskRef = useRef<HTMLDivElement>(null);

    const openTimeline = useRef<gsap.core.Timeline | null>(null);
    const closeTimeline = useRef<gsap.core.Timeline | null>(null);

    const setup = useCallback(() => {
        openTimeline.current = gsap
            .timeline({
                paused: true,
                defaults: {
                    ease: Power2.easeOut,
                },
            })
            .timeScale(timeScale)
            .fromTo(maskRef.current, { opacity: 0 }, { opacity: 0.9 })
            .fromTo(modalRef.current, { translateX: '100%' }, { translateX: 0 }, '<');

        closeTimeline.current = gsap
            .timeline({
                paused: true,
                ease: Power2.easeOut,
                onComplete: onCloseFinish,
            })
            .timeScale(timeScale)
            .to(maskRef.current, { opacity: 0 })
            .to(modalRef.current, { translateX: '100%' }, '<');
    }, []);

    const destroy = useCallback(() => {
        if (openTimeline.current) {
            openTimeline.current.kill();
            openTimeline.current = null;
        }

        if (closeTimeline.current) {
            closeTimeline.current.kill();
            closeTimeline.current = null;
        }
    }, []);

    const open = useCallback(() => {
        if (openTimeline.current) {
            openTimeline.current.play();
        }
    }, []);

    const close = useCallback(() => {
        if (closeTimeline.current) {
            closeTimeline.current.play();
        }
    }, []);

    useEffect(() => {
        setup();

        return destroy;
    }, []);

    useEffect(() => {
        if (active) {
            open();
        } else {
            close();
        }
    }, [active]);

    return (
        <div ref={containerRef} className={classnames(styles.modalBaseRight, className)}>
            <div
                ref={maskRef}
                className={styles.mask}
                onClick={close}
                role="presentation"
                onKeyPress={() => {}}
            />
            <div
                ref={modalRef}
                className={classnames(
                    styles.modalWrapper,
                    small && styles.small,
                    menu && styles.menu,
                    isWindows && styles.customScrollBar,
                    disabled && styles.disabled,
                )}
            >
                <div className={classnames(fixTop && styles.fixTop, styles.top, topClassName)}>
                    {logo ? (
                        <div className={styles.logo}>
                            <Logo />
                        </div>
                    ) : (
                        <div className={styles.title}>
                            <span className={styles.text}>{title}</span>
                            <span className={classnames(styles.text, styles.en)}> {enTitle}</span>
                        </div>
                    )}
                    <div
                        className={styles.back}
                        onClick={close}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => {}}
                    >
                        <Back />
                        <span className={styles.text}>{isEn ? 'back' : '返回'}</span>
                    </div>
                </div>
                <div
                    className={classnames(
                        styles.container,

                        childrenClassName,
                    )}
                >
                    {children}

                    {disabled && <div className={styles.disabled}>{disabledText}</div>}
                </div>
            </div>
        </div>
    );
};

export default ModalBaseRight;
