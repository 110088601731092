import { createReducer } from '@reduxjs/toolkit';

import { focusMap, clearMapFocus } from './actions';

import { MapType } from './types';

interface MapData {
    focus: {
        focusing: boolean;
        x?: number;
        y?: number;
        xOffset?: number;
        yOffset?: number;
        redirect?: string;
    };
}

export type State = Record<MapType, MapData>;

const initialState: State = {
    main: {
        focus: {
            focusing: false,
            x: undefined,
            y: undefined,
            xOffset: undefined,
            yOffset: undefined,
        },
    },
    impactHub: {
        focus: {
            focusing: false,
            x: undefined,
            y: undefined,
            xOffset: undefined,
            yOffset: undefined,
        },
    },
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(focusMap, (state, action) => {
            state[action.payload.type].focus = {
                focusing: true,
                x: action.payload.x,
                y: action.payload.y,
                xOffset: action.payload.xOffset,
                yOffset: action.payload.yOffset,
            };
        })
        .addCase(clearMapFocus, (state, action) => {
            state[action.payload].focus = {
                focusing: false,
                x: undefined,
                y: undefined,
                xOffset: undefined,
                yOffset: undefined,
            };
        });
});

export default reducer;
