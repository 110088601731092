import { useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import EventCard from 'components/molecules/EventCard';

import { eventList } from 'data/events';

import mainImage from 'images/events/main-image.png';

import type { EventData } from 'data/events/types';

import styles from './index.css';

export interface EventListProp {
    className?: string;
}

const EventList: React.FC<EventListProp> = ({ className }) => {
    const history = useHistory();

    const openComplete = useOpenBuilding('main', 2);

    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const [liveEvents, setListEvents] = useState<EventData[]>([]);
    const [upcomingEvents, setUpcomingEvents] = useState<EventData[]>([]);
    const [pastEvents, setPastEvents] = useState<EventData[]>([]);

    const onClickEvent = useCallback((id) => {
        history.push(`/town/events/${id}`);
    }, []);

    useEffect(() => {
        const now = DateTime.now();

        // date for testing
        // const now = DateTime.fromObject(
        //     {
        //         year: 2021,
        //         month: 12,
        //         day: 5,
        //         hour: 11,
        //         minute: 0,
        //     },
        //     { zone: 'UTC+8' },
        // );

        const nextLiveEvents: EventData[] = [];
        const nextUpcomingEvents: EventData[] = [];
        const nextPastEvents: EventData[] = [];

        eventList.forEach((eventData) => {
            const { date, startTime, endTime } = eventData;

            const [monthStr, dayStr] = date.split('/');
            const [startHourStr, startMinuteStr] = startTime.split(':');
            const [endHourStr, endMinuteStr] = endTime.split(':');

            const month = parseInt(monthStr, 10);
            const day = parseInt(dayStr, 10);
            const startHour = parseInt(startHourStr, 10);
            const startMinute = parseInt(startMinuteStr, 10);
            const endHour = parseInt(endHourStr, 10);
            const endMinute = parseInt(endMinuteStr, 10);

            const startDatetime = DateTime.fromObject(
                {
                    year: 2021,
                    month,
                    day,
                    hour: startHour,
                    minute: startMinute,
                },
                { zone: 'UTC+8' },
            );

            const endDatetime = DateTime.fromObject(
                {
                    year: 2021,
                    month,
                    day,
                    hour: endHour,
                    minute: endMinute,
                },
                { zone: 'UTC+8' },
            );

            if (now < startDatetime) {
                nextUpcomingEvents.push(eventData);
            } else if (now >= startDatetime && now <= endDatetime) {
                nextLiveEvents.push(eventData);
            } else {
                nextPastEvents.push(eventData);
            }
        });

        setListEvents(nextLiveEvents);
        setUpcomingEvents(nextUpcomingEvents);
        setPastEvents(nextPastEvents);

        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (isSetup && openComplete) {
            setIsReady(true);
        }
    }, [isSetup, openComplete]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.eventList, className)}
            childrenClassName={styles.container}
            title="未來加速會展中心"
            enTitle="Speed up"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <img className={styles.mainImage} src={mainImage} alt="main" />
            <div className={styles.intro}>
                <div className={styles.content}>
                    當我們對當前的世界變化無感，我們就不會意識到「問題」，更遑論要提起步伐、舉起雙手開始改變。在這座未來永續城市中，啟動城市進化的動力，正是來自這座《未來加速會展中心》。這裡，我們持續拋出對世界的問題、不斷總結全球的趨勢與變化、堅持找出更好的行動案例。這裡就是城市奔向未來的加速器，沒有這些感受、視野、資訊的灌注，改變的開始就會再慢一點、未來的到來就會再慢一點。
                    當你不知道要往哪裡前進、當你還沒意識到變化、當你想要感受現正進行中的脈動，就來未來加速會展中心，一起Speed
                    up我們奔向未來的速度，讓更好的未來來得更快。
                </div>
                <div className={styles.divider} />
            </div>
            <div className={styles.title}>節目列表</div>
            {liveEvents.length > 0 && (
                <section className={styles.eventSection}>
                    <div className={styles.sectionTitle}>正在直播</div>
                    <div className={styles.eventWrapper}>
                        {liveEvents.map((event) => {
                            const [month, day] = event.date.split('/');

                            return (
                                <EventCard
                                    key={event.id}
                                    className={styles.event}
                                    timeType="live"
                                    eventType={event.category}
                                    month={month}
                                    day={day}
                                    title={event.contentTitle || event.title}
                                    startTime={event.startTime}
                                    endTime={event.endTime}
                                    intro={event.intro}
                                    onClick={() => onClickEvent(event.id)}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
            {upcomingEvents.length > 0 && (
                <section className={styles.eventSection}>
                    <div className={styles.sectionTitle}>即將開始的節目</div>
                    <div className={styles.eventWrapper}>
                        {upcomingEvents.map((event) => {
                            const [month, day] = event.date.split('/');

                            return (
                                <EventCard
                                    key={event.id}
                                    className={styles.event}
                                    timeType="upcoming"
                                    eventType={event.category}
                                    month={month}
                                    day={day}
                                    title={event.title}
                                    startTime={event.startTime}
                                    endTime={event.endTime}
                                    intro={event.intro}
                                    onClick={() => onClickEvent(event.id)}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
            {pastEvents.length > 0 && (
                <section className={styles.eventSection}>
                    <div className={styles.sectionTitle}>精彩回顧</div>
                    <div className={styles.eventWrapper}>
                        {pastEvents.map((event) => {
                            const [month, day] = event.date.split('/');

                            const nextSpeakers: string[] = [];
                            event?.agenda.forEach(({ speaker }) => {
                                speaker?.forEach(({ name }) => {
                                    if (nextSpeakers.indexOf(name.split('（')[0]) === -1) {
                                        nextSpeakers.push(name.split('（')[0]);
                                    }
                                });
                            });

                            // remove duplicate
                            nextSpeakers.filter((item, pos) => {
                                return nextSpeakers.indexOf(item) === pos;
                            });

                            const slicedSpeakers = nextSpeakers.slice(0, 4);

                            return (
                                <EventCard
                                    key={event.id}
                                    className={styles.event}
                                    timeType="past"
                                    eventType={event.category}
                                    month={month}
                                    day={day}
                                    title={event.title}
                                    startTime={event.startTime}
                                    endTime={event.endTime}
                                    intro={event.intro}
                                    speakers={`${slicedSpeakers.join('、')}${
                                        nextSpeakers.length > 4 ? '...' : ''
                                    }`}
                                    onClick={() => onClickEvent(event.id)}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
        </ModalBaseRight>
    ) : null;
};

export default EventList;
