import hsbcone from 'images/hsbc/hsbcone.png';
import hsbctwo from 'images/hsbc/hsbctwo.png';
import hsbcthree from 'images/hsbc/hsbcthree.png';
import hsbcfour from 'images/hsbc/hsbcfour.png';

export default [
    {
        no: 1,
        layer: 4,
        path: '/hsbc/about',
        leave: false,
        src: hsbcone,

        title: '關於滙豐（台灣）',
        enTitle: '',
        width: 591,
        height: 469,
        position: [972, 315],
        anchorType: 'dotOrange',

        anchorPosition: [4, -49],
    },
    {
        no: 3,
        layer: 1,
        path: '/hsbc/story',

        leave: false,
        src: hsbctwo,

        title: '幸福城市夢想家',

        enTitle: '',
        width: 403,
        height: 274,
        position: [1109, 1226],
        anchorType: 'dotOrange',

        anchorPosition: [46, -49],
    },
    {
        no: 2,
        layer: 3,
        path: '/hsbc/origin',

        leave: false,
        src: hsbcthree,

        title: '「希望閱讀．添翼計畫」緣起',
        enTitle: '',
        width: 268,
        height: 287,
        position: [1623, 838],
        anchorType: 'dotOrange',

        anchorPosition: [0, -67],
    },
    {
        no: 4,
        layer: 2,
        path: '/hsbc/achievement',
        leave: false,
        src: hsbcfour,

        title: '我們的成果',
        enTitle: '',
        width: 304,
        height: 329,
        position: [2011, 477],
        anchorType: 'dotOrange',

        anchorPosition: [16, -69],
    },
];
