import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/sponsor-main-5.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface Sponsor5Prop {
    className?: string;
}

const Sponsor5: React.FC<Sponsor5Prop> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 5, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsor5, className)}
            title="雲門舞集舞蹈教室"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    雲門教室深信：身體學會的，誰也拿不走！為了幫助每個人認識自己，雲門以長年創造與積累的舞蹈藝術經驗，開創出「生活律動」教學系統，期盼人人從肢體律動中舞出自己，是雲門教室23年不變的堅持。面對多變世界，透過「動身體，做自己」每一回合蛻變，擁有對生命的熱情，勇敢樂觀擁抱未知，正是未來世代不可或缺的大能力。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="https://bit.ly/3bV5uHK?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '雲門舞集舞蹈教室 --- 了解更多',
                            });
                        }}
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(1801) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={1801}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default Sponsor5;
