import img1 from 'images/events/9-1.png';
import img2 from 'images/events/9-2.png';

import type { EventData } from './types';

const data: EventData = {
    id: 9,
    category: 'shortTalk',
    title: '讓題目與學習走出課本──從 108 課綱到 SDGs 的教學新典範',
    contentTitle: '讓題目與學習走出課本──從 108 課綱到 SDGs 的教學新典範',
    date: '12/5',
    startTime: '10:30',
    endTime: '11:30',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '2030 聯合國永續發展目標 （SDGs）已成為各界高度關注的關鍵字，回歸教育現場，我們又該如何正確理解 SDGs 與教育之間的關聯？從 108 課綱到 SDGs，從願景到實踐，與全球同步，啟動永續教育典範轉移，引領孩子向真實世界學習，在課堂中實踐，培養孩子成為世界公民。',
    youtubeLiveId: 'ONbcxs61wAc',
    youtubeVideoId: 'ONbcxs61wAc',
    sponsor: '',
    agenda: [
        {
            title: '開場引言',
            startTime: '10:30',
            endTime: '10:35',
        },
        {
            title: '從 108 課綱到 SDGs 的教學案例分享',
            startTime: '10:35',
            endTime: '11:05',
            speaker: [
                {
                    name: '何昕家',
                    intro: '臺中科技大學通識教育中心副教授',
                },
                {
                    name: '汪大久',
                    intro: '私立明道中學校長',
                },
            ],
        },
        {
            title: '與談及 Q&A',
            startTime: '11:05',
            endTime: '11:30',
            speaker: [
                {
                    name: '賓靜蓀',
                    intro: '親子天下媒體中心總主筆',
                },
                {
                    name: '何昕家',
                    intro: '臺中科技大學通識教育中心副教授',
                },
                {
                    name: '汪大久',
                    intro: '臺中市私立明道中學校長',
                },
            ],
        },
    ],
    speakerImages: [img1, img2],
};

export default data;
