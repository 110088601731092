import { RefObject, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

interface Options {
    delay?: number;
    paused?: boolean;
    timeScale?: number;
    translateY?: number;
    onComplete?: () => void;
    onFadeoutComplete?: () => void;
    fadeOut?: boolean;
}

const defaultOptions: Options = {
    delay: 0.5,
    paused: false,
    timeScale: 1,
    onComplete: () => {},
    onFadeoutComplete: () => {},
    fadeOut: false,
};

function useFadeIn<T extends HTMLElement = HTMLElement>(
    elements: { ref: RefObject<T>; time?: string }[],
    options?: Options,
): { fadeIn: () => void; fadeOut: () => void } {
    const {
        delay,
        paused,
        timeScale = 1,
        translateY,
        onComplete,
        onFadeoutComplete,
        fadeOut,
    } = {
        ...defaultOptions,
        ...options,
    };
    const timeline = useRef<gsap.core.Timeline | null>(null);
    const fadeoutTimeline = useRef<gsap.core.Timeline | null>(null);

    useEffect(() => {
        elements.forEach(({ ref, time = '>' }, index) => {
            if (index === 0 && ref.current) {
                timeline.current = gsap.timeline({ onComplete, paused }).timeScale(timeScale);

                if (fadeOut) {
                    fadeoutTimeline.current = gsap
                        .timeline({ onComplete: onFadeoutComplete, paused: true })
                        .timeScale(timeScale);
                }
            }

            if (timeline.current && ref.current) {
                if (paused) {
                    if (index === 0) {
                        timeline.current.to(ref.current, {
                            opacity: 1,
                            ...(translateY && { translateY: `${0}px` }),
                            delay,
                        });
                    } else {
                        timeline.current.to(
                            ref.current,
                            { opacity: 1, ...(translateY && { translateY: `${0}px` }) },
                            time,
                        );
                    }
                } else {
                    /* eslint-disable no-lonely-if */
                    if (index === 0) {
                        timeline.current.fromTo(
                            ref.current,
                            { opacity: 0, ...(translateY && { translateY: `${translateY}px` }) },
                            { opacity: 1, ...(translateY && { translateY: `${0}px` }), delay },
                        );
                    } else {
                        timeline.current.fromTo(
                            ref.current,
                            { opacity: 0, ...(translateY && { translateY: `${translateY}px` }) },
                            { opacity: 1, ...(translateY && { translateY: `${0}px` }) },
                            time,
                        );
                    }
                    /* eslint-enable no-lonely-if */
                }
            }

            if (fadeOut && fadeoutTimeline.current) {
                fadeoutTimeline.current.to(
                    ref.current,
                    {
                        opacity: 0,
                    },
                    '<',
                );
            }
        });

        return () => {
            if (timeline.current) {
                timeline.current.kill();
            }

            if (fadeoutTimeline.current) {
                fadeoutTimeline.current.kill();
            }
        };
    }, []);

    return {
        fadeIn: () => {
            timeline.current?.play();
        },
        fadeOut: () => {
            fadeoutTimeline.current?.play();
        },
    };
}

export default useFadeIn;
