import { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import { useHistory } from 'react-router-dom';
import { useAuth } from 'models/auth';
import { useRedirectPath } from 'models/mainSite';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';

import needLogin from 'images/common/need-login.png';

import styles from './index.css';

export interface ModalNeedLoginProp {
    className?: string;
    active?: boolean;
    onCloseFinish?: () => void;
}

const ModalNeedLogin: React.FC<ModalNeedLoginProp> = ({ className, onCloseFinish = () => {} }) => {
    const {
        location: { pathname },
        replace,
    } = useHistory();
    const [, { logout }] = useAuth();
    const [, { setRedirectPath }] = useRedirectPath();
    const [disabled, setDisabled] = useState(false);

    const [active, setActive] = useState(true);

    const loginDelay = useRef<number | null>(null);

    useEffect(() => {
        return () => {
            if (loginDelay.current) {
                window.clearTimeout(loginDelay.current);
            }
        };
    }, []);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalNeedLogin, className)}
            active={active}
            bottomBtn
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.content}>
                <div className={styles.title}>登入領取專屬角色</div>
                <div className={styles.subtitle}>登入以顯示專角色屬資訊、積分、背包獎勵</div>
                <div className={styles.image}>
                    <img src={needLogin} alt="need login" />
                </div>
                <Button3D
                    className={classnames(styles.btn, disabled && styles.disabled)}
                    onClick={() => {
                        setRedirectPath(pathname);
                        setDisabled(true);
                        logout();
                        setActive(false);
                    }}
                    delayLong
                >
                    登入
                </Button3D>
            </div>
        </ModalBaseCenter>
    );
};

export default ModalNeedLogin;
