import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCloth, useUserData } from 'models/user';
import { useModal } from 'models/modal';

import { useMedia } from 'utils/hooks';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import ArrowBtn from 'components/atoms/ArrowBtn';
import Progress from 'components/atoms/Progress';
import Button3D from 'components/atoms/Button3D';
import CurvedContainer from 'components/atoms/CurvedContainer';

import icon from 'images/icons/clothes.png';
import Info from 'images/icons/item-info.inline.svg';

import styles from './index.css';

interface Cloth {
    id: number;
    name: string;
    avatar: string;
}

export interface ModalClosetLayoutProp {
    className?: string;
    activeId: number;
    data: Cloth[];
    onSelect?: (activeId: number) => void;
    onCloseFinish?: () => void;
}

export const ModalClosetLayout: React.FC<ModalClosetLayoutProp> = ({
    className,
    activeId,
    data = [],
    onSelect = () => {},
    onCloseFinish = () => {},
}) => {
    const media = useMedia();
    const [
        {
            giftDetail: { active: giftDetailActive },
        },
        { openModal },
    ] = useModal();

    const [active, setActive] = useState(true);
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [selectId, setSelected] = useState(media === 'phone' ? activeId : undefined);

    const [page, setPage] = useState(0);

    useEffect(() => {
        return () => {
            if (swiper) {
                swiper.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (media === 'phone' && !selectId) {
            setSelected(activeId);
        }
    }, [media, selectId]);

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [media, swiper]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalCloset, className)}
            active={active}
            icon={icon}
            title="更衣櫃"
            subtitle="新的裝飾可以在扭轉塔中取得唷！盡情累積積分吧！"
            bottomBtn
            onCloseFinish={onCloseFinish}
            canClose={!giftDetailActive}
        >
            <div className={styles.content}>
                <div className={styles.slider}>
                    <Swiper
                        slidesPerView={1}
                        centeredSlides
                        onSwiper={(s) => setSwiper(s)}
                        onSlideChange={(e) => {
                            setPage(e.activeIndex);
                        }}
                        breakpoints={{
                            900: {
                                slidesPerView: data.length === 2 ? 2 : 3,
                                centeredSlides: data.length === 1,
                            },
                        }}
                    >
                        {data.map(({ id, name, avatar }) => (
                            <SwiperSlide key={id} className={styles.slide}>
                                <div
                                    className={classnames(
                                        styles.item,
                                        id === selectId && styles.active,
                                    )}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={() => {}}
                                    onClick={() => {
                                        if (media === 'desktop') {
                                            setSelected(id);
                                        }
                                    }}
                                >
                                    <CurvedContainer
                                        className={styles.mask}
                                        size="xs"
                                        borderColor="--accent-lightgreen"
                                        filledColor="rgba(2, 233, 184, 0.3)"
                                        transparent
                                        onClick={() => {}}
                                    />
                                    <Info
                                        className={styles.info}
                                        onClick={() => {
                                            openModal({
                                                type: 'giftDetail',
                                                data: {
                                                    giftId: 28,
                                                    themeId: id,
                                                },
                                            });
                                        }}
                                    />
                                    <div className={styles.avatar}>
                                        <img src={avatar} alt="avatar" />
                                    </div>
                                    <div className={styles.name}>{name}</div>
                                    {id === activeId && (
                                        <div className={styles.active}>正在穿戴</div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {((media === 'phone' && data.length > 1) ||
                    (media === 'desktop' && data.length > 3)) && (
                    <>
                        <ArrowBtn
                            className={classnames(styles.action, styles.prev)}
                            disabled={page <= 0}
                            type="prev"
                            onClick={() => {
                                if (swiper) {
                                    if (media === 'phone') {
                                        setSelected(data[swiper.activeIndex - 1].id);
                                    }

                                    swiper.slidePrev();
                                }
                            }}
                        />
                        <ArrowBtn
                            className={classnames(styles.action, styles.next)}
                            disabled={
                                media === 'phone'
                                    ? page >= data.length - 1
                                    : page >= data.length - 2
                            }
                            type="next"
                            onClick={() => {
                                if (swiper) {
                                    if (media === 'phone') {
                                        setSelected(data[swiper.activeIndex + 1].id);
                                    }

                                    swiper.slideNext();
                                }
                            }}
                        />
                    </>
                )}
                {((media === 'phone' && data.length > 1) ||
                    (media === 'desktop' && data.length > 3)) && (
                    <Progress
                        className={styles.progress}
                        value={page + 1}
                        max={media === 'phone' ? data.length : data.length - 2}
                    />
                )}
            </div>
            <div className={styles.btnWrapper}>
                <Button3D
                    className={styles.btn}
                    disabled={!selectId}
                    onClick={() => {
                        if (selectId) {
                            onSelect(selectId);
                            setActive(false);
                        }
                    }}
                >
                    穿戴上
                </Button3D>
            </div>
        </ModalBaseCenter>
    );
};

export interface ModalClosetProp {
    className?: string;
    onCloseFinish?: () => void;
}

const ModalCloset: React.FC<ModalClosetProp> = ({ className, onCloseFinish }) => {
    const [{ data, activeClothId: activeId }, { updateCloth }] = useCloth();
    const [, { getUserData }] = useUserData();

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <ModalClosetLayout
            className={className}
            onCloseFinish={onCloseFinish}
            activeId={activeId}
            data={data}
            onSelect={(selectedId) => {
                updateCloth({
                    clothType: selectedId > 2800 ? 'theme' : 'character',
                    clothId: selectedId,
                });
            }}
        />
    );
};

export default ModalCloset;
