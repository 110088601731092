import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import image from 'images/mainTown/sponsor-main-7.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface Sponsor6Prop {
    className?: string;
}

const Sponsor6: React.FC<Sponsor6Prop> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 8, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsor6, className)}
            title="傑可達數位"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    JECTOR於2001年開始致力於推展數位教學輔助設備，一路由資訊融入教學至前瞻智慧教室建置，是台灣在地教育品牌。2021年5月5日JECTOR成為友達集團一份子，以開創視界實現顯示大未來為目標，由台灣出發秉持「誠信、當責、創新、熱情」的企業精神，強化市場整合方案及產品垂直整合，致力成為全球顯示方案解決專家。
                    <br />
                    <br />
                    <a
                        className={articleStyles.link}
                        href="http://www.jectordigital.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'clicklink',
                                eventlable: '傑可達數位 --- 了解更多',
                            });
                        }}
                    >
                        了解更多
                    </a>
                </div>
            </div>
            {events.indexOf(2001) === -1 && (
                <div className={articleStyles.btnWrapper}>
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={100}
                        text="領取積分"
                        eventId={2001}
                        msg=""
                    />
                </div>
            )}
        </ModalBaseRight>
    ) : null;
};

export default Sponsor6;
