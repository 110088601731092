import { use100vh } from 'react-div-100vh';
import { isMobile } from 'react-device-detect';

import { useEntering, useFirstVisit } from 'models/mainSite';
import { useModal, useNotification } from 'models/modal';

import Transition from 'components/organisms/Transition';
import StarBackground from 'components/atoms/StarBackground';
import LoadingText from 'components/atoms/LoadingText';
import ModalExploreInfo from 'components/organisms/ModalExploreInfo';
import ModalUserPoints from 'components/organisms/ModalUserPoints';
import ModalNeedLogin from 'components/organisms/ModalNeedLogin';
import Notification from 'components/atoms/Notification';
import CookiePolicy from 'components/atoms/CookiePolicy';
import ModalCloset from 'components/organisms/ModalCloset';
import ModalRecommand from 'components/organisms/ModalRecommand';
import ModalGiftDetail from 'components/organisms/ModalGiftDetail';
import ModalGiftList from 'components/organisms/ModalGiftList';
import ModalBag from 'components/organisms/ModalBag';
import ModalUserTicket from 'components/organisms/ModalUserTicket';
import ModalGiftNew from 'components/organisms/ModalGiftNew';
import ModalMessageBoard from 'components/organisms/ModalMessageBoard';
import ModalMessageDetail from 'components/organisms/ModalMessageDetail';
import ModalSendMessage from 'components/organisms/ModalSendMessage';
import ModalRank from 'components/organisms/ModalRank';
import ModalFaq from 'components/organisms/ModalFaq';
import ModalPronunce from 'components/organisms/ModalPronunce';
import ModalProfile from 'components/organisms/ModalProfile';

import styles from './index.css';

const App: React.FC = ({ children }) => {
    const height100vh = use100vh();

    const [{ view }] = useEntering();
    const [
        { active: notificationActive, type: noType, message: noMessage },
        { closeNotification },
    ] = useNotification();
    const [, { setFirstVisit }] = useFirstVisit();

    const [
        {
            exploreInfo: { active: exploreInfoActive },
            userPoints: { active: userPointsActive },
            needLogin: { active: needLoginActive },
            userCloset: { active: userClosetActive },
            giftDetail: { active: giftDetailActive },
            giftList: { active: giftListActive },
            giftNew: { active: giftNewActive },
            userBag: { active: userBagActive },
            userTicket: { active: userTicketActive },
            recommend: { active: recommendActive },
            messages: { active: messagesActive },
            messagesDetail: { active: messagesDetailActive },
            messagesSend: { active: messagesSendActive },
            rank: { active: rankActive },
            faq: { active: faqActive },
            pronounce: { active: pronounceActive },
            profile: { active: profileActive },
        },
        { closeModal },
    ] = useModal();

    return (
        <div
            className={styles.appRoot}
            style={{ height: isMobile ? height100vh || '100vh' : '100vh' }}
        >
            <StarBackground />
            {view === 'loading' && (
                <div className={styles.loading}>
                    {/* Loading */}
                    <LoadingText className={styles.loading} />
                </div>
            )}
            {/* <div className={classnames(styles.whiteBg, background === 'white' && styles.active)} /> */}
            {children}

            {faqActive && (
                <ModalFaq
                    onCloseFinish={() => {
                        closeModal('faq');
                    }}
                />
            )}
            {rankActive && (
                <ModalRank
                    onCloseFinish={() => {
                        closeModal('rank');
                    }}
                />
            )}
            {userPointsActive && (
                <ModalUserPoints
                    onCloseFinish={() => {
                        closeModal('userPoints');
                    }}
                />
            )}
            {userClosetActive && (
                <ModalCloset
                    onCloseFinish={() => {
                        closeModal('userCloset');
                    }}
                />
            )}
            {userBagActive && (
                <ModalBag
                    onCloseFinish={() => {
                        closeModal('userBag');
                    }}
                />
            )}
            {messagesActive && (
                <ModalMessageBoard
                    onCloseFinish={() => {
                        closeModal('messages');
                    }}
                />
            )}
            {messagesDetailActive && (
                <ModalMessageDetail
                    onCloseFinish={() => {
                        closeModal('messagesDetail');
                    }}
                />
            )}
            {messagesSendActive && (
                <ModalSendMessage
                    onCloseFinish={() => {
                        closeModal('messagesSend');
                    }}
                />
            )}
            {giftListActive && (
                <ModalGiftList
                    onCloseFinish={() => {
                        closeModal('giftList');
                    }}
                />
            )}
            {giftDetailActive && (
                <ModalGiftDetail
                    onCloseFinish={() => {
                        closeModal('giftDetail');
                    }}
                />
            )}
            {userTicketActive && (
                <ModalUserTicket
                    onCloseFinish={() => {
                        closeModal('userTicket');
                    }}
                />
            )}
            {giftNewActive && (
                <ModalGiftNew
                    onCloseFinish={() => {
                        closeModal('giftNew');
                    }}
                />
            )}
            {exploreInfoActive && (
                <ModalExploreInfo
                    onCloseFinish={() => {
                        closeModal('exploreInfo');
                        setFirstVisit(false);
                    }}
                />
            )}
            {pronounceActive && (
                <ModalPronunce
                    onCloseFinish={() => {
                        closeModal('pronounce');
                    }}
                />
            )}
            {profileActive && (
                <ModalProfile
                    onCloseFinish={() => {
                        closeModal('profile');
                    }}
                />
            )}
            {needLoginActive && (
                <ModalNeedLogin
                    onCloseFinish={() => {
                        closeModal('needLogin');
                    }}
                />
            )}
            {recommendActive && (
                <ModalRecommand
                    onCloseFinish={() => {
                        closeModal('recommend');
                    }}
                />
            )}
            {notificationActive && (
                <Notification
                    type={noType}
                    message={noMessage}
                    onCloseComplete={() => {
                        closeNotification();
                    }}
                />
            )}
            {view !== 'loading' && <Transition />}
            {view !== 'loading' && <CookiePolicy />}
        </div>
    );
};

export default App;
