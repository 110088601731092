const insertScript = (id: string, src: string, options = { async: true, defer: true }) => {
    if (document.getElementById(id)) {
        return 1;
    }

    const jss: HTMLElement | null = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');

    js.id = id;
    js.src = src;

    if (options.async) {
        js.async = true;
    }

    if (options.defer) {
        js.defer = true;
    }

    if (jss.parentNode === null) {
        throw Error('jss.parentNode is null');
    }

    jss.parentNode.insertBefore(js, jss);
    return 0;
};

export interface FBResponse {
    status: string;
    authResponse: { accessToken: string } | null;
}

declare global {
    interface Window {
        fbAsyncInit: () => void;
        isFbSDKInit: boolean;

        FB: {
            init: (p: { [key: string]: any }) => void;
            login: (f: (r: FBResponse) => void, s?: { [key: string]: any }) => void;
            logout: (f?: (r: FBResponse) => void, s?: { [key: string]: any }) => void;
        };

        auth2: any;
    }
}

window.fbAsyncInit = window.fbAsyncInit || {};
window.isFbSDKInit = false;

const { FACEBOOK_ID } = process.env;

export const loadFacebookLibrary = () => {
    return new Promise<void>((resolve) => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: FACEBOOK_ID,
                cookie: true,
                xfbml: true, // parse social plugins on this page
                version: 'v12.0', // use graph api version 12
            });
            window.isFbSDKInit = true;
            resolve();
        };

        const result = insertScript('facebook-jssdk', '//connect.facebook.net/zh_TW/sdk.js');

        // Aready insert
        if (result === 1) {
            resolve();
        }
    });
};
