import { useState } from 'react';
import classnames from 'classnames';
import Lottie from 'lottie-react';

import { useSolveEvent, useEvent } from 'models/user';
import { useMapFocus } from 'models/map';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';
import ButtonPoint from 'components/atoms/ButtonPoint';

import main from 'images/mainTown/modal-good-20.png';

import articleStyles from 'layouts/articleStyles.css';
import styles from './index.css';

import fireworks from './fireworks.json';

export interface ModalRecommandLayoutProp {
    className?: string;
    type: 20 | 40 | 60 | 80 | 100;
    onCloseFinish: () => void;
}

export const ModalRecommandLayout: React.FC<ModalRecommandLayoutProp> = ({
    className,
    type,
    onCloseFinish = () => {},
}) => {
    const [active, setActive] = useState(true);
    const [, { focusMap }] = useMapFocus('main');
    const [{ events }] = useEvent();

    return (
        <ModalBaseCenter
            className={classnames(styles.modalPronunce, className)}
            containerClass={styles.modal}
            active={active}
            title="站長公告"
            bottomBtn
            onCloseFinish={onCloseFinish}
        >
            <Lottie
                // lottieRef={lottieRef}
                className={styles.lottie}
                animationData={fireworks}
                autoplay
                loop
            />
            <div className={styles.intro}>
                <div className={styles.image}>
                    <img src={main} alt="main" />
                </div>

                <div
                    className={articleStyles.content}
                    style={{ lineHeight: 1.5, textAlign: 'center' }}
                >
                    <br />
                    感謝市民們的努力，我們成功扭轉了環境的損毀！
                    <br />
                    <span className={articleStyles.green}>進度 {type}%</span>
                </div>
            </div>
            <div className={styles.btnWrapper}>
                {(type !== 100 || (type === 100 && events.indexOf(99101) !== -1)) && (
                    <Button3D
                        className={styles.btn}
                        spaceTop={30}
                        spaceBottom={30}
                        onClick={() => {
                            setActive(false);

                            const positionX = 400;
                            const positionY = 800;

                            focusMap({
                                x: positionX,
                                xOffset: window.innerWidth / 2,
                                y: positionY,
                                yOffset: window.innerHeight / 2,
                            });
                        }}
                    >
                        查看修復狀況
                    </Button3D>
                )}
                {type === 100 && events.indexOf(99101) === -1 && (
                    <ButtonPoint
                        // className={styles.btn}
                        points={5000}
                        text="領取獎勵"
                        eventId={99101}
                    />
                )}
            </div>
        </ModalBaseCenter>
    );
};

export interface ModalRecommandProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalRecommand: React.FC<ModalRecommandProp> = ({ className, onCloseFinish = () => {} }) => {
    const [{ progressType }] = useSolveEvent();

    return (
        <ModalRecommandLayout
            className={classnames(styles.modalRecommand, className)}
            type={progressType}
            onCloseFinish={onCloseFinish}
        />
    );
};

export default ModalRecommand;
