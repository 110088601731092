import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { wrapFetch } from 'utils/api';

import type { EnteringView, AssetType } from './types';

export const setFirstVisit = createAction<boolean>('mainSite/setFirstVisit');

export const setAssetsLoaded = createAction<{ type: AssetType }>('mainSite/setAssetsLoaded');

export const setLoading = createAction<boolean>('mainSite/setLoading');

export const setHideUI = createAction<boolean>('mainSite/setHideUI');

export const setEnteringView = createAction<EnteringView>('mainSite/setEnteringView');

export const startTransition = createAction<{ nextPath?: string }>('mainSite/startTransition');

export const endTransition = createAction('mainSite/endTransition');

export const setRedirectPath = createAction<string>('mainSite/setRedirectPath');

export const clearRedirectPath = createAction('mainSite/clearRedirectPath');

export const getRank = createAsyncThunk('user/getRank', async (_, { rejectWithValue }) => {
    const result = await wrapFetch('/ranks', { method: 'GET' });

    if (!result.success) {
        return rejectWithValue(result.msg);
    }

    return {
        ranks: result.ranks.map((data, index) => ({
            rank: index + 1,
            characterType: data.active_theme > 2800 ? data.active_theme : data.active_character,
            name: data.nickname,
            points: data.user_points,
        })),
    };
});

export const setSoundOn = createAction<boolean>('mainSite/setSoundOn');

export const setMapReady = createAction<boolean>('mainSite/setMapReady')
