import { createReducer } from '@reduxjs/toolkit';

import type { CharacterType } from 'data/character';

import {
    getUserData,
    setCharacterType,
    setName,
    getPoint,
    getTicket,
    getPointDetail,
    updateCloth,
    useTicket,
    getTotalUsedTicket,
    updateBagView,
    updateShippingInfo,
    solveEvent,
    resetUser,
} from './actions';

export interface Gift {
    giftId: number;
    themeId: number;
}
export interface State {
    userId: string;
    characterType: CharacterType;
    name: string;
    activeTheme: number;
    themes: number[];
    totalPoints: number;
    redeemPoints: number[];
    availableTicketCount: number;
    gifts: Gift[];
    shippingInfo: {
        name: string;
        phone: string;
        email: string;
        dist: string;
        city: string;
        postalCode: string;
        address: string;
    };
    events: number[];
    siteTotalUsedTicket: number;
    bagView: 'bag' | 'form' | 'info' | 'complete';
    solveEvent: {
        pronounceOpened: boolean;
        progressType: 0 | 20 | 40 | 60 | 80 | 100;
        solve20: boolean;
        solve40: boolean;
        solve60: boolean;
        solve80: boolean;
        solve100: boolean;
    };
}

const defaultUserData = {
    userId: '',
    characterType: 1,
    name: '',
    activeTheme: 1,
    themes: [],
    totalPoints: 0,
    redeemPoints: [],
    availableTicketCount: 0,
    gifts: [],
    shippingInfo: {
        name: '',
        phone: '',
        email: '',
        dist: '',
        city: '',
        postalCode: '',
        address: '',
    },
    events: [],
    siteTotalUsedTicket: 0,
    bagView: 'bag',
    solveEvent: {
        pronounceOpened: false,
        progressType: 0,
        solve20: false,
        solve40: false,
        solve60: false,
        solve80: false,
        solve100: false,
    },
};

const initialState: State = {
    userId: '',
    characterType: 1,
    name: '',
    activeTheme: 1,
    themes: [],
    totalPoints: 0,
    redeemPoints: [],
    availableTicketCount: 0,
    gifts: [],
    shippingInfo: {
        name: '',
        phone: '',
        email: '',
        dist: '',
        city: '',
        postalCode: '',
        address: '',
    },
    events: [],
    siteTotalUsedTicket: 0,
    bagView: 'bag',
    solveEvent: {
        pronounceOpened: false,
        progressType: 0,
        solve20: false,
        solve40: false,
        solve60: false,
        solve80: false,
        solve100: false,
    },
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getUserData.fulfilled, (state, action) => ({
            ...state,
            ...action.payload,
        }))
        .addCase(setCharacterType, (state, action) => {
            state.characterType = action.payload;
        })
        .addCase(setName, (state, action) => {
            state.name = action.payload;
        })
        .addCase(getPoint.fulfilled, (state, action) => {
            state.totalPoints = action.payload.totalPoints;
            if (action.payload.eventId) {
                state.events = [...state.events, action.payload.eventId];
            }
        })
        .addCase(getTicket.fulfilled, (state, action) => {
            state.redeemPoints = action.payload.redeemPoints;
            state.availableTicketCount += 1;
        })
        .addCase(getPointDetail.fulfilled, (state, action) => {
            state.redeemPoints = action.payload.redeemPoints;
            state.totalPoints = action.payload.totalPoints;
        })
        .addCase(updateCloth.fulfilled, (state, action) => {
            if (action.payload.clothType === 'character') {
                state.activeTheme = 1;
            } else {
                state.activeTheme = action.payload.clothId;
            }
        })
        .addCase(useTicket.fulfilled, (state, action) => {
            state.availableTicketCount = action.payload.availableTicketsCount;
            state.gifts = [
                ...state.gifts,
                {
                    giftId: action.payload.giftId,
                    themeId: action.payload.themeId,
                },
            ];
        })
        .addCase(getTotalUsedTicket.fulfilled, (state, action) => {
            state.siteTotalUsedTicket = action.payload.total;
            state.solveEvent = action.payload.solveEvent;
            state.solveEvent.pronounceOpened = true;
        })
        .addCase(updateBagView, (state, action) => {
            state.bagView = action.payload;
        })
        .addCase(updateShippingInfo.fulfilled, (state, action) => {
            state.shippingInfo = action.payload;
        })
        .addCase(solveEvent.fulfilled, (state, action) => {
            const type = action.payload;

            if (type) {
                state.solveEvent[`solve${type}`] = true;
                state.events.push(type === 100 ? 99100 : 9900 + type);
            }
        })
        .addCase(resetUser, (state) => {
            state.userId = defaultUserData.userId;
            state.characterType = defaultUserData.characterType;
            state.name = defaultUserData.name;
            state.activeTheme = defaultUserData.activeTheme;
            state.themes = defaultUserData.themes;
            state.totalPoints = defaultUserData.totalPoints;
            state.redeemPoints = defaultUserData.redeemPoints;
            state.availableTicketCount = defaultUserData.availableTicketCount;
            state.gifts = defaultUserData.gifts;
            state.shippingInfo = defaultUserData.shippingInfo;
            state.events = defaultUserData.events;
            state.siteTotalUsedTicket = defaultUserData.siteTotalUsedTicket;
            state.solveEvent = defaultUserData.solveEvent;
        });
});

export default reducer;
