import { createReducer } from '@reduxjs/toolkit';

import { login, logout, setLoginType } from './actions';

import { LoginType } from './types';

export interface State {
    loginType: LoginType;
    token: string;
}

const initialState: State = {
    loginType: 'notLogin',
    token: '',
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(login.fulfilled, (state, action) => {
            state.loginType = action.payload.type;

            if (action.payload.type === 'facebook' && action.payload.token) {
                state.token = action.payload.token;
            }
        })
        .addCase(logout.fulfilled, (state) => {
            state.loginType = 'notLogin';
            state.token = '';
        })
        .addCase(setLoginType, (state, action) => {
            state.loginType = action.payload;
        });
});

export default reducer;
