import characterAvatar1 from 'images/icons/character-1.png';
import characterAvatar2 from 'images/icons/character-2.png';
import characterAvatar3 from 'images/icons/character-3.png';
import characterAvatar4 from 'images/icons/character-4.png';
import characterAvatar5 from 'images/icons/character-5.png';

import character1 from 'images/common/character-1.png';
import character2 from 'images/common/character-2.png';
import character3 from 'images/common/character-3.png';
import character4 from 'images/common/character-4.png';
import character5 from 'images/common/character-5.png';

import characterMotion1 from 'images/common/character-1.gif';
import characterMotion2 from 'images/common/character-2.gif';
import characterMotion3 from 'images/common/character-3.gif';
import characterMotion4 from 'images/common/character-4.gif';
import characterMotion5 from 'images/common/character-5.gif';

import theme2801 from 'images/common/theme-2801.png';
import theme2802 from 'images/common/theme-2802.png';
import theme2803 from 'images/common/theme-2803.png';
import theme2804 from 'images/common/theme-2804.png';
import theme2805 from 'images/common/theme-2805.png';
import theme2806 from 'images/common/theme-2806.png';
import theme2807 from 'images/common/theme-2807.png';
import theme2808 from 'images/common/theme-2808.png';
import theme2809 from 'images/common/theme-2809.png';
import theme2810 from 'images/common/theme-2810.png';

interface CharacterData {
    id: number;
    name: string;
    mainImage: string;
    avatar: string;
    motion: string;
    introTitle: string;
    intro: string;
}

export type CharacterType = 1 | 2 | 3 | 4 | 5;

export const characterData: { [key: number]: CharacterData } = {
    1: {
        id: 1,
        name: '商務媒合家',
        mainImage: character1,
        avatar: characterAvatar1,
        motion: characterMotion1,
        introTitle: '最強商務媒合與資源串連夥伴',
        intro: `商務媒合家 Invest for Innovation！
                獨特精準的眼光、強大的連結力，願意為每一個能讓未來更好的創意助力！`,
    },
    2: {
        id: 2,
        name: '未來探險家',
        mainImage: character2,
        avatar: characterAvatar2,
        motion: characterMotion2,
        introTitle: '不願意被定義的新人類',
        intro: `未來探險家 Explore for Future!
                擁有滿滿的好奇心、總是願意探索全新可能，相信自己獨特的選擇能夠讓未來有所不同！`,
    },
    3: {
        id: 3,
        name: '探索教育家',
        mainImage: character3,
        avatar: characterAvatar3,
        motion: characterMotion3,
        introTitle: '最熱情的教育工作夥伴',
        intro: `探索教育家 EDU for the NEXT!
                擁有最大的教育熱情、最開放的想像力，每天都在為下一代打造著更好的未來！`,
    },
    4: {
        id: 4,
        name: '創新促進家',
        mainImage: character4,
        avatar: characterAvatar4,
        motion: characterMotion4,
        introTitle: '最 ROCK 的創新創業夥伴',
        intro: `創新促進家 Rock for Tomorrow!
                擁有最獨特的感受力、最創新的實踐力，相信未來是可以自己打造出來的！`,
    },
    5: {
        id: 5,
        name: '永續支持家',
        mainImage: character5,
        avatar: characterAvatar5,
        motion: characterMotion5,
        introTitle: '最共好的永續發展推動夥伴',
        intro: `永續支持家 Support for Better World！
                對變化擁有超強感知、總是能找到問題的另類解方，每天都在為所有人「共同的未來」努力！`,
    },
};

export type ThemeType = 2801 | 2802 | 2803 | 2804 | 2805 | 2806 | 2807 | 2808 | 2809 | 2810;

interface ThemeData {
    id: number;
    name: string;
    avatar: string;
    introTitle: string;
    intro: string;
}

export const themeData: Record<ThemeType, ThemeData> = {
    2801: {
        id: 2801,
        name: '未來創新小金童',
        avatar: theme2801,
        introTitle: '閃亮亮變身！',
        intro: '我整個人都金光閃閃～',
    },
    2802: {
        id: 2802,
        name: '未來永續偶像',
        avatar: theme2802,
        introTitle: '萬人迷94我！',
        intro: '是可愛又迷人的...正派角色！',
    },
    2803: {
        id: 2803,
        name: '永續模範生',
        avatar: theme2803,
        introTitle: '城市新星就此誕生！',
        intro: '我就棒 ʕ •ᴥ•ʔ',
    },
    2804: {
        id: 2804,
        name: '城市創新官CIO',
        avatar: theme2804,
        introTitle: '永續未來城的榮耀！',
        intro: '對我來說，輕輕鬆鬆～',
    },
    2805: {
        id: 2805,
        name: '創新冒險王',
        avatar: theme2805,
        introTitle: '星際特攻出動！',
        intro: '教育元宇宙由我來開啟ᕕ ( ᐛ ) ᕗ',
    },
    2806: {
        id: 2806,
        name: '永續司令官',
        avatar: theme2806,
        introTitle: '未來守護者變身！',
        intro: '我們的城市，由我來守護！',
    },
    2807: {
        id: 2807,
        name: '未來建築師',
        avatar: theme2807,
        introTitle: '永續未來的科學！',
        intro: '自己的家、自己的未來，自己造！',
    },
    2808: {
        id: 2808,
        name: '明天藝術家',
        avatar: theme2808,
        introTitle: '藝術大師華麗登場！',
        intro: '每天都要漂漂亮亮(⁎⁍̴̛ᴗ⁍̴̛⁎)',
    },
    2809: {
        id: 2809,
        name: '城市領航者',
        avatar: theme2809,
        introTitle: '航向偉大的航道！',
        intro: '飛向宇宙，浩瀚無垠～',
    },
    2810: {
        id: 2810,
        name: '未來大明星',
        avatar: theme2810,
        introTitle: '媽，我上電視了！',
        intro: '人人都說，成名只需要15秒，看來我是成名了 (´･ω･`)',
    },
};
