import event1 from './1';
import event2 from './2';
import event3 from './3';
import event4 from './4';
import event5 from './5';
import event6 from './6';
import event7 from './7';
import event8 from './8';
import event9 from './9';
import event10 from './10';
import event11 from './11';

import type { EventData } from './types';

export const eventList: EventData[] = [
    event1,
    event2,
    event3,
    event4,
    event5,
    event6,
    event7,
    event8,
    event9,
    event10,
    event11,
];

export const eventData: { [key: string]: EventData } = {
    '1': event1,
    '2': event2,
    '3': event3,
    '4': event4,
    '5': event5,
    '6': event6,
    '7': event7,
    '8': event8,
    '9': event9,
    '10': event10,
    '11': event11,
};
