import { useState } from 'react';
import classnames from 'classnames';
import { useModal } from 'models/modal';
import { useSolveEvent } from 'models/user';
import { useAuth } from 'models/auth';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import Button3D from 'components/atoms/Button3D';

import icon from 'images/icons/explore-icon.png';
import info1 from 'images/common/explore-1.png';
import info2 from 'images/common/explore-2.png';
import info3 from 'images/common/explore-3.png';
import info4 from 'images/common/explore-4.png';

import styles from './index.css';

export interface ModalExploreInfoProp {
    className?: string;
    active?: boolean;
    onCloseFinish?: () => void;
}

const ModalExploreInfo: React.FC<ModalExploreInfoProp> = ({
    className,
    onCloseFinish = () => {},
}) => {
    const [step, setStep] = useState(1);
    const [active, setActive] = useState(true);
    const [{ loginType }] = useAuth();

    const [
        {
            exploreInfo: {
                data: { checkProgress },
            },
        },
        { openModal },
    ] = useModal();

    const [{ progressType, solve20, solve40, solve60, solve80, solve100 }] = useSolveEvent();

    return (
        <ModalBaseCenter
            className={classnames(styles.modalExploreInfo, className)}
            active={active}
            bottomBtn
            buttonText="略過說明"
            icon={icon}
            title="探索須知"
            onCloseFinish={() => {
                onCloseFinish();

                if (checkProgress && loginType === 'facebook') {
                    if (
                        (progressType >= 20 && !solve20) ||
                        (progressType >= 40 && !solve40) ||
                        (progressType >= 60 && !solve60) ||
                        (progressType >= 80 && !solve80) ||
                        (progressType >= 100 && !solve100)
                    ) {
                        openModal({
                            type: 'pronounce',
                        });
                    }
                }
            }}
        >
            <div className={styles.main}>
                {step === 1 && <img className={styles.image} src={info1} alt="info" />}
                {step === 2 && <img className={styles.image} src={info2} alt="info" />}
                {step === 3 && <img className={styles.image} src={info3} alt="info" />}
                {step === 4 && <img className={styles.image} src={info4} alt="info" />}
                <div className={styles.text}>
                    {step === 1 &&
                        'Hi~首次登入永續未來城的市民們。你可以通過拖曳畫面來探索這座屬於未來的城市，城市中標有紅、黃兩色大頭針的設施，都可以一探究竟關於永續x教育的祕密。'}
                    {step === 2 &&
                        '每次的探索、瀏覽、互動都能領取你專屬的積分。達到階段積分獎勵標準就可「領取扭蛋券」至《未來扭轉塔》扭出你的專屬好禮！'}
                    {step === 3 &&
                        '每一次的扭蛋除了換取好禮，更可以為城市帶來改變！越多人參與、扭轉的次數越多，我們就可以真正帶來改變！'}
                    {step === 4 &&
                        '行動所帶來的積分，除了兌換禮物，還會有總積分排名。趕快開始你的永續未來排位戰吧！'}
                </div>
                <Button3D
                    className={styles.btn}
                    spaceTop={36}
                    spaceBottom={41}
                    onClick={() => {
                        if (step < 4) {
                            setStep(step + 1);
                        } else {
                            setActive(false);
                        }
                    }}
                >
                    {step < 4 ? '下一則' : '出發'}
                </Button3D>
            </div>
        </ModalBaseCenter>
    );
};

export default ModalExploreInfo;
