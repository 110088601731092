import { useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { numberWithCommas } from 'utils/helper';
import { useOpenBuilding } from 'utils/hooks';

import { useNotification, useModal } from 'models/modal';
import { useTicket } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import NpcMessage from 'components/atoms/NpcMessage';
import ButtonTicket from 'components/atoms/ButtonTicket';
import TicketProgress from 'components/molecules/TicketProgress';
import ButtonRound from 'components/atoms/ButtonRound';

import CurvedButton from 'components/atoms/CurvedButton';

import main from 'images/mainTown/twist-now-hero.png';
import land from 'images/common/land.png';
import UserIcon from 'images/icons/user.inline.svg';
import ArrowLine from 'images/common/arrow-line.inline.svg';

import articleStyles from 'layouts/articleStyles.css';

import styles from './index.css';
import { openModal } from 'models/modal/actions';

export interface TwistNowProp {
    className?: string;
}

const TwistNow: React.FC<TwistNowProp> = ({ className }) => {
    const history = useHistory();
    const [, { openNotification }] = useNotification();
    const [, { openModal }] = useModal();
    const [{ siteTotalUsedTicket }, { getTotalUsedTicket }] = useTicket();

    const openComplete = useOpenBuilding('main', 4);

    // return openComplete ? (

    useEffect(() => {
        getTotalUsedTicket();
    }, []);

    return (
        <ModalBaseRight
            className={classnames(styles.twistNow, className)}
            childrenClassName={styles.container}
            title="未來扭轉塔"
            enTitle="Twist Now!"
            onCloseFinish={() => {
                history.push('/town');
            }}
            // disabled
            // disabledText="12/2正式啟用，市民們加緊累積積分，就有機會抽中好禮喔！"
        >
            <ButtonRound
                className={styles.allGiftBtn}
                text="查看所有獎品"
                onClick={() => {
                    openModal({ type: 'giftList' });
                }}
            />
            <img className={styles.main} src={main} alt="main" />
            <NpcMessage className={styles.npcMsg} message="「你的每一個動作，都有意義。」" />
            <div
                className={classnames(articleStyles.section, styles.section)}
                style={{ marginTop: 40 }}
            >
                <div className={articleStyles.content}>
                    在永續未來城市中的每個市民的每個行動，都能累積讓城市加速通往永續未來的積分。每一個積分，就是足以產生改變的能量。當能量續滿，來到未來扭轉塔，你就可以換得扭轉的機會。每一次的扭轉，不僅能獲得屬於市民的獨特回饋，這個扭轉的能量會投入拯救扭轉這個城市已經破敗的一隅，讓這個曾經多樣燦爛的角落，有機會再次被看見。
                    <br />
                    <br />
                    所以，每一次的扭轉力量看似雖小，但正是這裡的每個「一點」力量，彙總起來，才真正成為能夠扭轉未來的創變之力！現在就跟著我們一起扭轉現況、創造未來！Twist
                    NOW!
                    <br />
                </div>
            </div>

            <div className={styles.action}>
                <ButtonTicket className={styles.btn} />
                <div className={styles.note}>
                    <UserIcon className={styles.icon} />
                    <span className={styles.text}>
                        共 {numberWithCommas(siteTotalUsedTicket)} 位探險者一同扭轉了危機
                    </span>
                </div>
            </div>
            <div className={styles.content}>
                <TicketProgress />
                <div className={styles.story}>
                    <ArrowLine className={styles.line} />
                    <img className={styles.land} src={land} alt="land" />

                    <div className={styles.title}>從一座被濫墾伐跟被亂倒廢棄物的森林開始</div>
                    <div className={styles.storyContent}>
                        在探訪每個主題時還可以累積你的共好積分，換取你的永續好禮！幫助這片被汙染的土地復甦，給瀕臨絕種的動物們一個美麗家園。
                        一起成為改變世界的行動者吧！
                    </div>
                    <CurvedButton
                        className={styles.btn}
                        size="xs"
                        onClick={() => {
                            history.push('/town/play-for-power');
                        }}
                    >
                        前往了解
                    </CurvedButton>
                </div>
            </div>
        </ModalBaseRight>
    );
    // ) : null;
};

export default TwistNow;
