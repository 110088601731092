import useRedux from 'utils/hooks/useRedux';

import { fetchMessages, likeMessage, sendMessage, getLastCreate } from './actions';

export const useMessages = () =>
    useRedux((state) => state.message, {
        fetchMessages,
        likeMessage,
        sendMessage,
        getLastCreate,
    });
