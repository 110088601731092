import classnames from 'classnames';
import { isMobile } from 'react-device-detect';

import type { MouseEvent } from 'react';

import CurvedAngle from 'components/atoms/CurvedAngle';

import { color as getColorCode } from 'utils/helper';

import styles from './index.css';

export interface CurvedButtonProp {
    className?: string;
    childrenClassName?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    borderWidth?: number;
    borderColor?: string;
    filledColor?: string;
    transparent?: boolean;
    curve?: number;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseOver?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseMove?: (event: MouseEvent<HTMLDivElement>) => void;
}

const ANGLE_SIZE_MAP = {
    xs: 6,
    sm: 9,
    md: 12,
    lg: 30,
};

const CurvedContainer: React.FC<CurvedButtonProp> = ({
    className,
    childrenClassName,
    size = 'sm',
    borderWidth = 1,
    borderColor = '--primary-light',
    filledColor = '',
    transparent = false,
    curve,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onMouseMove = () => {},
    children,
}) => (
    <div
        className={classnames(styles.curvedContainer, styles[size], className)}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        onClick={onClick}
        onFocus={() => {}}
        onMouseOver={(event) => {
            if (!isMobile) {
                onMouseOver(event);
            }
        }}
        onMouseEnter={(event) => {
            if (!isMobile) {
                onMouseEnter(event);
            }
        }}
        onMouseLeave={(event) => {
            if (!isMobile) {
                onMouseLeave(event);
            }
        }}
        onMouseMove={(event) => {
            if (!isMobile) {
                onMouseMove(event);
            }
        }}
    >
        <CurvedAngle
            className={classnames(styles.corner, styles.leftTop)}
            direction="leftTop"
            size={curve || ANGLE_SIZE_MAP[size]}
            borderWidth={borderWidth}
            borderColor={borderColor}
            filledColor={filledColor}
        />
        <CurvedAngle
            className={classnames(styles.corner, styles.leftBottom)}
            direction="leftBottom"
            size={curve || ANGLE_SIZE_MAP[size]}
            borderWidth={borderWidth}
            borderColor={borderColor}
            filledColor={filledColor}
        />
        <CurvedAngle
            className={classnames(styles.corner, styles.rightTop)}
            direction="rightTop"
            size={curve || ANGLE_SIZE_MAP[size]}
            borderWidth={borderWidth}
            borderColor={borderColor}
            filledColor={filledColor}
        />
        <CurvedAngle
            className={classnames(styles.corner, styles.rightBottom)}
            direction="rightBottom"
            size={curve || ANGLE_SIZE_MAP[size]}
            borderWidth={borderWidth}
            borderColor={borderColor}
            filledColor={filledColor}
        />

        {filledColor && transparent && (
            <div className={styles.center} style={{ backgroundColor: getColorCode(filledColor) }} />
        )}

        <div
            className={classnames(styles.border, transparent && styles.transparent, styles.top)}
            style={{
                ...(filledColor && { backgroundColor: getColorCode(filledColor) }),
                borderWidth,
                borderTopColor: getColorCode(borderColor),
            }}
        />
        <div
            className={classnames(styles.border, transparent && styles.transparent, styles.bottom)}
            style={{
                ...(filledColor && { backgroundColor: getColorCode(filledColor) }),
                borderWidth,
                borderBottomColor: getColorCode(borderColor),
            }}
        />
        <div
            className={classnames(styles.border, transparent && styles.transparent, styles.left)}
            style={{
                ...(filledColor && { backgroundColor: getColorCode(filledColor) }),
                borderWidth,
                borderLeftColor: getColorCode(borderColor),
            }}
        />
        <div
            className={classnames(styles.border, transparent && styles.transparent, styles.right)}
            style={{
                ...(filledColor && { backgroundColor: getColorCode(filledColor) }),
                borderWidth,
                borderRightColor: getColorCode(borderColor),
            }}
        />

        <div className={classnames(styles.children, childrenClassName)}>{children}</div>
    </div>
);

export default CurvedContainer;
