// ref: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router-config/react-router-config-tests.tsx

import { renderRoutes } from 'react-router-config';

import type { RouteConfig, RouteConfigComponentProps } from 'react-router-config';

import mainRoutes from './Main';
import eventRoutes from './Event';
import notFoundRoutes from './NotFound';

const Root = ({ route }: RouteConfigComponentProps) => (
    <>
        {/* child routes won't render without this */}
        {renderRoutes(route && route.routes)}
    </>
);

const routes: RouteConfig[] = [
    {
        component: Root,
        routes: [...eventRoutes, ...mainRoutes, ...notFoundRoutes],
    },
];

export default routes;
