// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__arrow-btn___1UNyb {\n    display: -webkit-box;\n    display: flex;\n    -webkit-box-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n            align-items: center;\n    width: 38px;\n    height: 38px;\n    border-radius: 38px\n}\n\n.index__arrow-btn___1UNyb.index__next___1bfSC {\n        -webkit-transform: rotate(180deg);\n                transform: rotate(180deg);\n    }\n\n.index__arrow-btn___1UNyb.index__disabled___IcsPn {\n        pointer-events: none;\n        opacity: 0.2;\n    }\n\n@media (hover: hover) {\n\n    .index__arrow-btn___1UNyb {\n        cursor: pointer;\n        -webkit-transition: background-color 0.15s linear;\n        transition: background-color 0.15s linear\n}\n\n        .index__arrow-btn___1UNyb:hover {\n            background-color: rgba(2, 233, 184, 0.4);\n        }\n    }\n", "",{"version":3,"sources":["webpack://./src/components/atoms/ArrowBtn/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,aAAa;IACb,wBAAuB;YAAvB,uBAAuB;IACvB,yBAAmB;YAAnB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ;AAmBJ;;AAjBI;QACI,iCAAyB;gBAAzB,yBAAyB;IAC7B;;AAEA;QACI,oBAAoB;QACpB,YAAY;IAChB;;AAEA;;IAjBJ;QAkBQ,eAAe;QACf,iDAAyC;QAAzC;AAMR;;QAJQ;YACI,wCAAwC;QAC5C;IACJ","sourcesContent":[".arrow-btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 38px;\n    height: 38px;\n    border-radius: 38px;\n\n    &.next {\n        transform: rotate(180deg);\n    }\n\n    &.disabled {\n        pointer-events: none;\n        opacity: 0.2;\n    }\n\n    @media (hover: hover) {\n        cursor: pointer;\n        transition: background-color 0.15s linear;\n\n        &:hover {\n            background-color: rgba(2, 233, 184, 0.4);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow-btn": "index__arrow-btn___1UNyb",
	"arrowBtn": "index__arrow-btn___1UNyb",
	"next": "index__next___1bfSC",
	"disabled": "index__disabled___IcsPn"
};
export default ___CSS_LOADER_EXPORT___;
