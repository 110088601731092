import img1 from 'images/events/7-1.png';
import img2 from 'images/events/7-2.png';

import type { EventData } from './types';

const data: EventData = {
    id: 7,
    category: 'shortTalk',
    title: '從「小」開始，在生活中實踐 SDGs 永續行動',
    contentTitle: '從「小」開始，在生活中實踐 SDGs 永續行動',
    date: '12/2',
    startTime: '20:00',
    endTime: '21:00',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '很多人聽到永續行動，可能直覺連接開題龐大的各種行動，好像不能站上講台宣講就不算造成改變，所以會覺得環境、永續未來離我們特別遙遠。在這場分享中，邀請用「簡單綠能學」驅動企業與家庭個人展開拯救環境行動的DOMI綠然能源，以及長期通過設計思考帶領孩子用全球思維展開在地行動的郭至和老師，來與大家分享我們如何從「小」開始、從生活開始培養永續視野、持續永續未來的行動。',
    youtubeLiveId: 'Zy6HJlPuS4Q',
    youtubeVideoId: 'Zy6HJlPuS4Q',
    sponsor: '',
    agenda: [
        {
            title: '開場引言',
            startTime: '20:00',
            endTime: '20:05',
        },
        {
            title: '生活中的永續視野培養及實踐案例分享',
            startTime: '20:05',
            endTime: '20:35',
            speaker: [
                {
                    name: '胡德琦',
                    intro: 'DOMI 綠然能源創辦人',
                },
                {
                    name: '郭至和',
                    intro: '臺中市建功國小教師',
                },
            ],
        },
        {
            title: '與談及 Q&A',
            startTime: '20:35',
            endTime: '21:00',
            speaker: [
                {
                    name: '許家齊',
                    intro: '親子天下媒體中心資深記者',
                },
                {
                    name: '胡德琦',
                    intro: 'DOMI 綠然能源創辦人',
                },
                {
                    name: '郭至和',
                    intro: '臺中市建功國小教師',
                },
            ],
        },
    ],
    speakerImages: [img1, img2],
};

export default data;
