import ImpactHubone from 'images/impactHub/ImpactHubone.png';
import ImpactHubtwo from 'images/impactHub/ImpactHubtwo.png';
import ImpactHubthree from 'images/impactHub/ImpactHubthree.png';
import ImpactHubfour from 'images/impactHub/ImpactHubfour.png';
import ImpactHubfive from 'images/impactHub/ImpactHubfive.png';
import ImpactHubsix from 'images/impactHub/ImpactHubsix.png';
import ImpactHubseven from 'images/impactHub/ImpactHubseven.png';
import ImpactHubeight from 'images/impactHub/ImpactHubeight.png';
import ImpactHubnight from 'images/impactHub/ImpactHubnight.png';
import ImpactHubten from 'images/impactHub/ImpactHubten.png';
import ImpactHubeleven from 'images/impactHub/ImpactHubeleven.png';

import type { MenuItem } from './types';

const data: MenuItem[] = [
    {
        no: 1,
        layer: 7,

        path: '/impact-hub/intro',
        leave: false,
        src: ImpactHubone,

        title: '續想家',
        enTitle: 'Taiwan2030',
        width: 191,
        height: 363,
        position: [491, 732],
        anchorType: 'dotOrange',

        anchorPosition: [-25, -39],
    },
    {
        no: 2,

        layer: 2,

        path: '/impact-hub/1',
        leave: false,
        src: ImpactHubtwo,

        title: '什麼是聯合國永續發展⽬標呢？',
        enTitle: '',
        width: 239,
        height: 388,
        position: [532, 448],
        anchorType: 'dotOrange',

        anchorPosition: [0, -56],
    },
    {
        no: 3,

        layer: 11,

        path: '/impact-hub/2',
        leave: false,
        src: ImpactHubthree,

        title: '原來擦⼿紙還可以這樣選！你認識FSC™認證嗎？',
        enTitle: '',
        width: 135,
        height: 191,
        position: [885, 385],
        anchorType: 'dotOrange',

        anchorPosition: [-30, -70],
    },
    {
        no: 4,

        layer: 3,

        path: '/impact-hub/3',
        leave: false,
        src: ImpactHubfour,

        title: 'Opps…忘記關緊水龍頭了！',
        enTitle: '',
        width: 374,
        height: 383,
        position: [892, 289],
        anchorType: 'dotOrange',

        anchorPosition: [-59, -49],
    },
    {
        no: 5,

        layer: 4,

        path: '/impact-hub/4',
        leave: false,
        src: ImpactHubfive,

        title: '你知道該如何吃得更永續嗎？',
        enTitle: '',
        width: 403,
        height: 492,
        position: [885, 590],
        anchorType: 'dotOrange',

        anchorPosition: [31, -38],
    },
    {
        no: 8,

        layer: 10,

        path: '/impact-hub/5',
        leave: false,
        src: ImpactHubsix,

        title: '你今天零剩⾷了嗎？吃不下了怎麼辦？',
        enTitle: '',
        width: 384,
        height: 186,
        position: [1225, 821],
        anchorType: 'dotOrange',

        anchorPosition: [-91, 0],
    },
    {
        no: 7,

        layer: 12,

        path: '/impact-hub/6',
        leave: false,
        src: ImpactHubseven,

        title: '⼜忘記隨⼿關燈了嗎？',
        enTitle: '',
        width: 432,
        height: 294,
        position: [1561, 945],
        anchorType: 'dotOrange',

        anchorPosition: [11, 0],
    },
    {
        no: 6,

        layer: 5,

        leave: false,
        src: ImpactHubeight,

        path: '/impact-hub/7',

        title: '當你挑到衣櫃中一間破掉的⽜仔褲時，你會怎麼做呢？',
        enTitle: '',
        width: 395,
        height: 464,
        position: [1472, 604],
        anchorType: 'dotOrange',

        anchorPosition: [40, -20],
    },
    {
        no: 9,

        layer: 6,

        path: '/impact-hub/8',
        leave: false,
        src: ImpactHubnight,

        title: '你真的了解什麼是快時尚產業嗎？',
        enTitle: '',
        width: 256,
        height: 411,
        position: [1891, 592],
        anchorType: 'dotOrange',

        anchorPosition: [4, -20],
    },
    {
        no: 10,

        layer: 8,

        path: '/impact-hub/9',
        leave: false,
        src: ImpactHubten,

        title: '教育翻轉孩童的⼈⽣',
        enTitle: '',
        width: 315,
        height: 400,
        position: [1355, 104],
        anchorType: 'dotOrange',

        anchorPosition: [0, -40],
    },
    {
        no: 11,

        layer: 9,

        path: '/impact-hub/10',
        leave: false,
        src: ImpactHubeleven,

        title: '讓聯合國來教你《Good Life Goals 美好行動目標》',
        enTitle: '',
        width: 239,
        height: 413,
        position: [1652, 211],
        anchorType: 'dotOrange',

        anchorPosition: [6, -29],
    },
];

export default data;
