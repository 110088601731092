import startupone from 'images/startup/startupone.png';
import startuptwo from 'images/startup/startuptwo.png';
import startupthree from 'images/startup/startupthree.png';
import startupfour from 'images/startup/startupfour.png';
import startupfive from 'images/startup/startupfive.png';
import startupsix from 'images/startup/startupsix.png';
import startupseven from 'images/startup/startupseven.png';
import startupeight from 'images/startup/startupeight.png';
import startupnight from 'images/startup/startupnight.png';
import startupten from 'images/startup/startupten.png';
import startupeleven from 'images/startup/startupeleven.png';
import startuptwelve from 'images/startup/startuptwelve.png';

import type { MenuItem } from './types';

const data: MenuItem[] = [
    {
        no: 1,
        layer: 10,
        path: '/startup/intro',
        leave: false,
        src: startupone,

        title: 'Startup Island TAIWAN',
        en: 'Startup Island TAIWAN',
        enTitle: '',
        width: 271,
        height: 220,
        position: [1281, 1013.79],
        anchorType: 'titleLg',

        anchorPosition: [-36, -36],
    },
    {
        no: 2,

        layer: 1,
        path: '/startup/Actura',
        leave: false,
        src: startuptwo,

        title: '璞學智慧',
        en: 'Actura',

        enTitle: '',
        width: 248,
        height: 492,
        position: [208, 519],
        anchorType: 'titleSm',

        anchorPosition: [0, -38],
    },
    {
        no: 3,
        layer: 3,
        path: '/startup/Magicplus',
        leave: false,
        src: startupthree,

        title: '加點魔法',
        en: 'Elite Magic Academy',

        enTitle: '',
        width: 294,
        height: 398,
        position: [598, 331.79],
        anchorType: 'titleSm',

        anchorPosition: [-29, -71],
    },
    {
        no: 4,
        layer: 8,
        path: '/startup/MEandMine',
        leave: false,
        src: startupfour,

        title: 'MEandMine',
        en: 'MEandMine',

        enTitle: '',
        width: 331,
        height: 366,
        position: [456, 737],
        anchorType: 'titleMd',

        anchorPosition: [-40, -40],
    },
    {
        no: 5,
        layer: 9,
        path: '/startup/Snapask',
        leave: false,
        src: startupfive,

        title: 'Snapask',
        en: 'Snapask',

        enTitle: '',
        width: 412,
        height: 364,
        position: [869, 856.25],
        anchorType: 'titleSm',

        anchorPosition: [0, -70],
    },
    {
        no: 6,
        layer: 4,
        path: '/startup/VoiceTube',
        leave: false,
        src: startupsix,

        title: 'VoiceTube',
        en: 'VoiceTube',

        enTitle: '',
        width: 413,
        height: 365,
        position: [1267, 658.71],
        anchorType: 'titleSm',

        anchorPosition: [30, -84],
    },
    {
        no: 7,
        layer: 11,
        path: '/startup/TaipeiCompanionAcademy',
        leave: false,
        src: startupseven,

        title: '台北市陪伴者實驗教育機構',
        en: 'Taipei Companion Academy',

        enTitle: '',
        width: 237,
        height: 311,
        position: [1329, 1218],
        anchorType: 'titleLg',

        anchorPosition: [-21, -49],
    },
    {
        no: 8,
        layer: 12,
        path: '/startup/ChaocuoWaldorfEducation',
        leave: false,
        src: startupeight,

        title: '潮厝',
        en: 'Chaocuo Waldorf Education',

        enTitle: '',
        width: 291,
        height: 364,
        position: [1680, 906.79],
        anchorType: 'titleSm',

        anchorPosition: [-18, -59],
    },
    {
        no: 9,
        layer: -1,
        path: '/startup/XueXueInstitute',
        leave: false,
        src: startupnight,

        title: '臺北市學學實驗教育機構',
        en: 'Xue Xue Institute',

        enTitle: '',
        width: 418,
        height: 468,
        position: [1198, 89],
        anchorType: 'titleLg',

        anchorPosition: [0, 0],
    },
    {
        no: 10,
        layer: 11,
        path: '/startup/SURREAL',
        leave: false,
        src: startupten,

        title: '超現實科技',
        en: 'SURREAL EDUCATION',

        enTitle: '',
        width: 328,
        height: 390,
        position: [2215, 752],
        anchorType: 'titleMd',

        anchorPosition: [-44, -44],
    },
    {
        no: 11,
        layer: 10,
        path: '/startup/MIFLY',
        leave: false,
        src: startupeleven,

        title: '米菲多媒體',
        en: 'MIFLY',

        enTitle: '',
        width: 414,
        height: 390,
        position: [2143, 477],
        anchorType: 'titleMd',

        anchorPosition: [-29, -71],
    },
    {
        no: 12,
        layer: 2,
        path: '/startup/CodingAPEProgrammingSchool',
        leave: false,
        src: startuptwelve,

        title: '猿創力',
        en: 'CodingAPE Programming School',

        enTitle: '',
        width: 263,
        height: 366,
        position: [1831, 365],
        anchorType: 'titleSm',

        anchorPosition: [12, -49],
    },
];

export default data;
