import classnames from 'classnames';
import { useState, useEffect, useCallback } from 'react';

import ModalBaseLarge from 'components/organisms/ModalBaseLarge';
import CurvedButton from 'components/atoms/CurvedButton';
import MessageCard from 'components/atoms/MessageCard';

import { useMessages } from 'models/messages';

import User from 'images/icons/user.inline.svg';

import styles from './index.css';

export interface ModalGiftListProp {
    className?: string;
    onCloseFinish?: () => void;
}

const ModalMessages: React.FC<ModalGiftListProp> = ({
    className,

    onCloseFinish = () => {},
}) => {
    const [active, setActive] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);

    const [
        { loading, currentPage, totalPage, messageCount, messages, messagesData },
        { fetchMessages },
    ] = useMessages();

    useEffect(() => {
        fetchMessages({ page: 1 });
    }, []);

    useEffect(() => {
        if (!loading) {
            setModalLoading(false);
        }
    }, [loading]);

    return (
        <ModalBaseLarge
            active={active}
            className={classnames(styles.modalGiftList, className)}
            hasCloseBtn={false}
            onCloseFinish={onCloseFinish}
            onScrollEnd={() => {
                if (!loading && !modalLoading && totalPage > currentPage) {
                    fetchMessages({ page: currentPage + 1 });
                    setModalLoading(true);
                }
            }}
        >
            <div className={styles.content}>
                <div className={styles.titleGroup}>
                    <div className={styles.title}>遺跡訊息</div>
                    <div className={styles.note}>
                        <User /> 共 {messageCount} 位探險者在這留言
                    </div>
                </div>
                <div className={styles.container}>
                    {messages.map((id) => {
                        const {
                            messageId,
                            authorCharacterType,
                            author,
                            content,
                            createTime,
                            liked,
                            likes,
                        } = messagesData[id];

                        return (
                            <MessageCard
                                key={messageId}
                                messageId={messageId}
                                className={styles.message}
                                characterType={authorCharacterType}
                                name={author}
                                content={content}
                                time={createTime}
                                likes={likes}
                                liked={liked}
                            />
                        );
                    })}
                </div>
                {totalPage > currentPage && (
                    <div className={styles.loading}>正在載入更多訊息 ...</div>
                )}
            </div>
            <CurvedButton
                className={styles.backBtn}
                theme="gray"
                size="small"
                onClick={() => {
                    setActive(false);
                }}
            >
                返回
            </CurvedButton>
        </ModalBaseLarge>
    );
};

// const messages = [
//     {
//         messageId: 1,
//         characterType: 2801,
//         name: 'name',
//         content: 'content',
//         time: '2021-11-26 13:37:42',
//         likes: 9,
//     },
// ];

// const ModalMessages: React.FC<ModalMessagesProp> = ({ className, onCloseFinish = () => {} }) => {
//     return <ModalMessagesLayout messages={messages} onCloseFinish={onCloseFinish} />;
// };

export default ModalMessages;
