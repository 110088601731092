import { useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { gsap, Linear } from 'gsap';

import star from 'images/common/star.png';

import styles from './index.css';

export interface StarBackgroundProp {
    className?: string;
    play?: boolean;
    direction?: 'clockwise' | 'antiClockwise';
}

const StarBackground: React.FC<StarBackgroundProp> = ({
    className,
    play = true,
    direction = 'clockwise',
}) => {
    const rotateTimelineRef = useRef<gsap.core.Timeline | null>(null);
    const starEl = useRef<HTMLDivElement>(null);

    const setup = useCallback(() => {
        rotateTimelineRef.current = gsap
            .timeline({
                repeat: -1,
                paused: true,
                defaults: {
                    ease: Linear.easeNone,
                    onReverseComplete: () => {
                        if (rotateTimelineRef.current) {
                            const tl = rotateTimelineRef.current;
                            tl.totalTime(tl.duration() * 100).resume();
                        }
                    },
                },
            })
            .timeScale(0.012)
            .to(starEl.current, {
                backgroundPositionX: '479px', // (width / height * 1.2)
            });
    }, []);

    const destroy = useCallback(() => {
        if (rotateTimelineRef.current) {
            rotateTimelineRef.current.kill();
        }
    }, []);

    useEffect(() => {
        setup();

        return () => {
            destroy();
        };
    }, []);

    useEffect(() => {
        if (rotateTimelineRef.current) {
            const tl1 = rotateTimelineRef.current;

            if (play) {
                if (direction === 'clockwise') {
                    tl1.play();
                } else if (direction === 'antiClockwise') {
                    tl1.reverse().repeat(-1);
                }
            } else {
                tl1.pause();
            }
        }
    }, [play, direction]);

    return (
        <div className={classnames(styles.starBackground, className)}>
            <div className={styles.mask} />
            <div ref={starEl} className={styles.star} style={{ backgroundImage: `url(${star})` }} />
        </div>
    );
};

export default StarBackground;
