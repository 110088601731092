import { bindActionCreators, ActionCreatorsMapObject } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { State as RootState } from 'models/reducers';

type Selector<TReturnState> = (state: RootState) => TReturnState;

interface DefaultActionMap {
    [key: string]: (...params: any[]) => void;
}

type WrapActionDispatch<TActionMap extends DefaultActionMap> = {
    [key in keyof TActionMap]: (...params: Parameters<TActionMap[key]>) => void;
};

const useRedux = <TReturnState, TActionMap extends DefaultActionMap>(
    selector: Selector<TReturnState>,
    actions: ActionCreatorsMapObject,
): [TReturnState, WrapActionDispatch<TActionMap>] => {
    const state = useSelector(selector);
    const dispatch = useDispatch();
    const boundActions = bindActionCreators(actions, dispatch) as unknown;

    return [state, boundActions as WrapActionDispatch<TActionMap>];
};

export default useRedux;
