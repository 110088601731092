import eventRoutes from './EventContent';

import component from './component';

export default [
    {
        path: '/town/events',
        component,
        routes: [...eventRoutes],
    },
];
