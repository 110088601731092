import { lazy } from 'react';

import notFoundRoutes from 'routes/NotFound';

import enteringRoutes from './Entering';
import townRoutes from './Town';
import impactHubRoutes from './ImpactHub';
import startupRoutes from './Startup';
import seasonRoutes from './SeasonArt';
import hsbcRoutes from './Hsbc';
import futureLiveRoutes from './FutureLive';
import powerUpRoutes from './PowerUp';
import PopWowRoutes from './PopWow';

export default [
    {
        path: ['/'],
        component: lazy(() => import(/* webpackChunkName: 'MainRoot' */ './component')),
        routes: [
            ...enteringRoutes,
            ...townRoutes,
            ...impactHubRoutes,
            ...startupRoutes,
            ...seasonRoutes,
            ...hsbcRoutes,
            ...futureLiveRoutes,
            ...powerUpRoutes,
            ...PopWowRoutes,
            ...notFoundRoutes,
        ],
    },
];
