import useRedux from 'utils/hooks/useRedux';

import { themeData, characterData } from 'data/character';

import {
    getUserData,
    setCharacterType,
    setName,
    signup,
    getPointDetail,
    getPoint,
    getTicket,
    updateCloth,
    useTicket as applyTicket,
    getTotalUsedTicket,
    updateBagView,
    updateShippingInfo,
    solveEvent,
} from './actions';

export const useUserData = () =>
    useRedux((state) => state.user, {
        getUserData,
        getPointDetail,
    });

export const useSignup = () =>
    useRedux(
        (state) => ({
            name: state.user.name,
            characterType: state.user.characterType,
        }),
        {
            setCharacterType,
            setName,
            signup,
        },
    );

export const useEvent = () =>
    useRedux(
        (state) => ({
            events: state.user.events,
        }),
        {
            getPoint,
            getTicket,
        },
    );

type clothType = 'character' | 'theme';
interface ClothData {
    type: clothType;
    id: number;
    name: string;
    avatar: string;
    introTitle: string;
    intro: string;
}

export const useCloth = () =>
    useRedux(
        (state) => {
            const activeType = state.user.activeTheme > 2800 ? 'theme' : 'character';
            const activeClothId =
                state.user.activeTheme > 2800 ? state.user.activeTheme : state.user.characterType;

            const data = [];

            if (activeType === 'character') {
                const userCharacterData = characterData[state.user.characterType];

                data.push({
                    type: 'character',
                    id: userCharacterData.id,
                    name: userCharacterData.name,
                    avatar: userCharacterData.avatar,
                    introTitle: userCharacterData.introTitle,
                    intro: userCharacterData.intro,
                });

                state.user.themes.forEach((themeId) => {
                    const userThemeData = themeData[themeId];

                    data.push({
                        type: 'theme',
                        id: userThemeData.id,
                        name: userThemeData.name,
                        avatar: userThemeData.avatar,
                        introTitle: userThemeData.introTitle,
                        intro: userThemeData.intro,
                    });
                });
            } else {
                const activeThemeData = themeData[state.user.activeTheme];
                data.push({
                    type: 'theme',
                    id: activeThemeData.id,
                    name: activeThemeData.name,
                    avatar: activeThemeData.avatar,
                    introTitle: activeThemeData.introTitle,
                    intro: activeThemeData.intro,
                });
                const userCharacterData = characterData[state.user.characterType];
                data.push({
                    type: 'character',
                    id: userCharacterData.id,
                    name: userCharacterData.name,
                    avatar: userCharacterData.avatar,
                    introTitle: userCharacterData.introTitle,
                    intro: userCharacterData.intro,
                });
                state.user.themes
                    .filter((themeId) => themeId !== state.user.activeTheme)
                    .forEach((themeId) => {
                        const userThemeData = themeData[themeId];
                        data.push({
                            type: 'theme',
                            id: userThemeData.id,
                            name: userThemeData.name,
                            avatar: userThemeData.avatar,
                            introTitle: userThemeData.introTitle,
                            intro: userThemeData.intro,
                        });
                    });
            }

            return {
                activeType,
                activeClothId,
                data,
            };
        },
        { updateCloth },
    );

export const useTicket = () =>
    useRedux(
        (state) => ({
            availableTicketCount: state.user.availableTicketCount,
            siteTotalUsedTicket: state.user.siteTotalUsedTicket,
        }),
        {
            applyTicket,
            getTotalUsedTicket,
        },
    );

export const useBag = () =>
    useRedux(
        (state) => ({
            gifts: state.user.gifts,
            shippingInfo: state.user.shippingInfo,
            bagView: state.user.bagView,
        }),
        {
            updateBagView,
            updateShippingInfo,
        },
    );

export const useSolveEvent = () =>
    useRedux((state) => state.user.solveEvent, {
        solveEvent,
    });
