import { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import type { MouseEvent } from 'react';

import CurvedContainer from 'components/atoms/CurvedContainer';

import styles from './index.css';

export interface CurvedButtonProp {
    className?: string;
    childrenClassName?: string;
    disabled?: boolean;
    outline?: boolean;
    size?: 'normal' | 'small' | 'xs';
    theme?: 'white' | 'gray' | 'blue';
    borderWidth?: number;
    withHover?: boolean;
    immediateDisable?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseOver?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseMove?: (event: MouseEvent<HTMLDivElement>) => void;
}

const CurvedButton: React.FC<CurvedButtonProp> = ({
    className,
    childrenClassName,
    disabled = false,
    outline = false,
    size = 'normal',
    theme = 'white',
    borderWidth,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onMouseMove = () => {},
    withHover = true,
    children,
}) => {
    const [isHover, setHoverState] = useState(false);
    const [filledColor, setFilledColor] = useState<string | undefined>(undefined);
    const [borderColor, setBorderColor] = useState<string>('--primary-light');

    const setDefaultColor = useCallback(() => {
        if (theme === 'white') {
            setBorderColor('--primary-light');
        } else if (theme === 'gray') {
            setBorderColor('--primary-dark-7');
        } else if (theme === 'blue') {
            setBorderColor('--accent-blue');
        }

        if (outline) {
            setFilledColor(undefined);
        } else if (theme === 'white') {
            setFilledColor('--primary-light');
        } else if (theme === 'gray') {
            setFilledColor('--primary-dark-7');
        } else if (theme === 'blue') {
            setFilledColor('--accent-blue');
        }
    }, [outline, theme]);

    const setHoverColor = useCallback(() => {
        if (theme === 'white') {
            setBorderColor('--accent-lightgreen');
        } else if (theme === 'gray') {
            setBorderColor('--accent-lightgreen');
        } else if (theme === 'blue') {
            setBorderColor('--accent-blue');
        }

        if (outline) {
            setFilledColor(undefined);
        } else if (theme === 'white') {
            setFilledColor('--accent-lightgreen');
        } else if (theme === 'gray') {
            setFilledColor('--accent-lightgreen');
        } else if (theme === 'blue') {
            setFilledColor('--accent-blue');
        }
    }, [outline, theme]);

    useEffect(() => {
        if (isHover && withHover) {
            setHoverColor();
        } else {
            setDefaultColor();
        }
    }, [theme, withHover, isHover]);

    return (
        <CurvedContainer
            className={classnames(
                styles.curvedButton,
                disabled && styles.disabled,
                styles[size],
                styles[theme],
                className,
            )}
            childrenClassName={classnames(styles.content, childrenClassName)}
            size="sm"
            borderColor={borderColor}
            filledColor={filledColor}
            borderWidth={borderWidth}
            onClick={onClick}
            onMouseOver={(event) => {
                if (withHover) {
                    setHoverState(true);
                }
                onMouseOver(event);
            }}
            onMouseEnter={(event) => {
                onMouseEnter(event);
            }}
            onMouseLeave={(event) => {
                if (withHover) {
                    setHoverState(false);
                }
                onMouseLeave(event);
            }}
            onMouseMove={(event) => {
                onMouseMove(event);
            }}
        >
            {children}
        </CurvedContainer>
    );
};

export default CurvedButton;
