import classnames from 'classnames';
import { useState, useEffect } from 'react';

import { useModal, useNotification } from 'models/modal';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';

import Back from 'images/icons/arrow-forward.inline.svg';

import prizeData from 'data/prize';
import rankPrizeData from 'data/rankPrize';
import { characterData } from 'data/character';

import styles from './index.css';

export interface ModalGiftDetailProp {
    className?: string;
    onCloseFinish: () => void;
}

const ModalGiftDetail: React.FC<ModalGiftDetailProp> = ({
    className,
    onCloseFinish = () => {},
}) => {
    const [active, setActive] = useState(true);
    const [data, setData] = useState(undefined);
    const [, { openNotification }] = useNotification();

    const [
        {
            giftDetail: {
                data: { rankGiftId, giftId, themeId, newGift, list, code },
            },
        },
    ] = useModal();

    // const giftId = 24;
    // const themeId = undefined;

    useEffect(() => {
        if (rankGiftId) {
            setData(rankPrizeData[rankGiftId]);
        } else if (giftId === 28 && themeId && themeId < 2800) {
            setData({
                image: characterData[themeId].avatar,
                name: characterData[themeId].name,
                note: characterData[themeId].introTitle,
                intro: characterData[themeId].intro,
            });
        } else if ((giftId === 25 || giftId === 27) && themeId) {
            setData(prizeData[giftId].themes[themeId]);
        } else if (giftId === 22 || giftId === 23 || giftId === 24) {
            let intro = prizeData[giftId].intro;

            if (list) {
                intro = prizeData[giftId].intro
                    .replace('恭喜獲得「親子天下線上學校」$100元折價券\n', '')
                    .replace('您的折價券代碼為【oooooo】\n', '')
                    .replace('恭喜獲得「親子天下有聲書」免費聽1個月\n', '')
                    .replace('您的兌換代碼為【oooooo】\n', '');
            } else if (code) {
                intro = prizeData[giftId].intro.replace('oooooo', code);
            }

            setData({ ...prizeData[giftId], intro });
        } else if (giftId) {
            setData(prizeData[giftId]);
        } else {
            setData(undefined);
        }
    }, [giftId, themeId, newGift, list]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalGiftDetail, className)}
            childrenClassName={styles.children}
            active={active}
            onCloseFinish={() => {
                if (newGift) {
                    openNotification({
                        type: 'default',
                        message: '領取成功',
                    });
                }
                onCloseFinish();
            }}
        >
            <div
                className={styles.back}
                onClick={() => {
                    setActive(false);
                }}
                tabIndex={0}
                role="button"
                onKeyPress={() => {}}
            >
                <Back />
                <span className={styles.text}>返回</span>
            </div>
            {data && (
                <div className={styles.content}>
                    <div className={styles.image}>
                        <img src={data.image} alt="preview" />
                    </div>
                    <div className={styles.intro}>
                        <div className={styles.title}>{data.name}</div>
                        {data.note && <div className={styles.note}>{data.note}</div>}
                        {data.link && (
                            <a
                                href={data.link.url}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: 'clicklink',
                                        eventlable: data.link.text,
                                    });
                                }}
                            >
                                {data.link.text}
                            </a>
                        )}
                        <div className={styles.detail}>{data.intro}</div>
                    </div>
                </div>
            )}
        </ModalBaseCenter>
    );
};

export default ModalGiftDetail;
