import classnames from 'classnames';

import { ButtonPointLayout } from 'components/atoms/ButtonPoint';

import { useTicket } from 'models/user';

import styles from './index.css';

export interface ButtonTicketProp {
    className?: string;
    text?: string;
}

const ButtonTicket: React.FC<ButtonTicketProp> = ({ className, text = '我要扭轉' }) => {
    const [{ availableTicketCount }, { applyTicket }] = useTicket();
    return (
        <div
            className={classnames(
                styles.buttonTicket,
                availableTicketCount <= 0 && styles.disabledclassName,
            )}
        >
            <ButtonPointLayout
                className={classnames(styles.btn)}
                type="ticket"
                points={-1}
                disabled={availableTicketCount <= 0}
                onClick={() => {
                    applyTicket();
                }}
            >
                {text}
            </ButtonPointLayout>
            {availableTicketCount <= 0 && <div className={styles.warn}>扭蛋券不足</div>}
        </div>
    );
};

export default ButtonTicket;
