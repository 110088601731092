import classnames from 'classnames';

import { color as getColorCode } from 'utils/helper';

import styles from './index.css';

export interface CurvedAngleProp {
    className?: string;
    size?: number;
    borderWidth?: number;
    borderColor?: string;
    filledColor?: string;
    direction: 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
}

const CurvedAngle: React.FC<CurvedAngleProp> = ({
    className,
    size = 9,
    borderWidth = 1,
    borderColor = 'white',
    filledColor = '',
    direction = 'rightTop',
}) => {
    return (
        <div
            className={classnames(styles.curvedAngle, className)}
            style={{
                width: size,
                height: size,
            }}
        >
            {filledColor && (
                <svg
                    className={classnames(styles.filledSvg, styles[direction])}
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 40 40"
                >
                    <g>
                        <path
                            d="M0.1,0L0.1,0L0.1,0C0.1,0,0.1,0,0.1,0z"
                            fill={getColorCode(filledColor)}
                        />
                        <path
                            d="M40,37.5V0H0.1C21.4,0,38.7,16.6,40,37.5z"
                            fill={getColorCode(filledColor)}
                        />
                    </g>
                </svg>
            )}

            <svg
                className={classnames(styles.borderSvg, styles[direction])}
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 40 40"
            >
                <path
                    d="M0,0c21.9,0,39.6,17,40,40"
                    stroke={getColorCode(borderColor)}
                    strokeWidth={borderWidth * 5}
                    fill="transparent"
                />
            </svg>
        </div>
    );
};

export default CurvedAngle;
