import { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';
import { useDebounce } from 'react-use';
import { useMedia } from 'utils/hooks';

import { useUserData, useEvent } from 'models/user';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import CurvedButton from 'components/atoms/CurvedButton';
import CurvedContainer from 'components/atoms/CurvedContainer';
import ArrowBtn from 'components/atoms/ArrowBtn';

import icon from 'images/icons/gift.png';
import ticket from 'images/icons/coupon.png';
import ArrowLine from 'images/common/arrow-line.inline.svg';
import ArrowLineShort from 'images/common/arrow-line-short.inline.svg';
import Checked from 'images/icons/checked.inline.svg';
import Locked from 'images/icons/locked.inline.svg';

import styles from './index.css';

export interface ModalUserPointsLayoutProp {
    className?: string;
    active?: boolean;
    totalPoints: number;
    redeemPoints: number[];
    onCloseFinish?: () => void;
    onGetTicket?: (activePoint: number) => void;
}
export interface ModalUserPointsProp {
    className?: string;
    active?: boolean;
    onCloseFinish?: () => void;
}

type TicketState = 'used' | 'available' | 'disabled';

const checkTicketState = (
    ticketPoint: number,
    userTotalPoint: number,
    pointUsedList: number[] = [],
) => {
    let state: TicketState = 'disabled';

    if (ticketPoint <= userTotalPoint) {
        if (pointUsedList.indexOf(ticketPoint) === -1) {
            state = 'available';
        } else {
            state = 'used';
        }
    }

    return state;
};

const getCenterPoint = (userTotalPoint: number, redeemList: number[] = []) => {
    if (userTotalPoint <= 1000) {
        return 1000;
    }

    const maxTicket = Math.floor(userTotalPoint / 1000) * 1000;
    const allTicketList = Array.from(
        { length: Math.floor(userTotalPoint / 1000) },
        (_, i) => (i + 1) * 1000,
    );
    const unRedeemList = allTicketList.filter((point) => redeemList.indexOf(point) === -1);

    if (unRedeemList.length > 0) {
        return unRedeemList[0];
    }

    return maxTicket;
};

export const ModalUserPointsLayout: React.FC<ModalUserPointsLayoutProp> = ({
    className,
    totalPoints = 700,
    redeemPoints = [1000, 400],
    active = true,
    onCloseFinish = () => {},
    onGetTicket = () => {},
}) => {
    const [init, setInit] = useState(true);
    const [activePoint, setActivePoint] = useState(getCenterPoint(totalPoints, redeemPoints));
    const [page, setPage] = useState(activePoint / 1000);
    const [contentState, setContentState] = useState<TicketState>(
        checkTicketState(activePoint, totalPoints, redeemPoints),
    );
    const media = useMedia();

    const [{ opacity, translateY }, springApi] = useSpring(() => ({
        opacity: 1,
        translateY: 0,
    }));

    const onClickPrev = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onClickNext = useCallback(() => {
        if (page < 100) {
            setPage(page + 1);
        }
    }, [page]);

    const [, cancel] = useDebounce(
        () => {
            if (init) {
                setInit(false);
            } else {
                springApi.start({
                    opacity: 0,

                    onRest: () => {
                        setActivePoint(page * 1000);

                        springApi.start({
                            from: {
                                opacity: 0,
                                translateY: 10,
                            },
                            to: {
                                opacity: 1,
                                translateY: 0,
                            },
                        });
                    },
                });
            }
        },
        500,
        [page],
    );

    useEffect(() => {
        const nextActive = getCenterPoint(totalPoints, redeemPoints);
        setPage(nextActive / 1000);
        setActivePoint(nextActive);
        setContentState(checkTicketState(nextActive, totalPoints, redeemPoints));
        cancel();
        springApi.start({ opacity: 1, translateY: 0 });
    }, [totalPoints, redeemPoints]);

    useEffect(() => {
        if (activePoint) {
            setContentState(checkTicketState(activePoint, totalPoints, redeemPoints));
        }
    }, [activePoint]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalUserPoints, className)}
            active={active}
            bottomBtn
            icon={icon}
            title="積分獎勵"
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.total}>目前積分: {totalPoints}</div>
            <animated.div
                className={styles.rewardIntro}
                style={{
                    opacity,
                    transform: translateY.to((value) => `translateY(${value}px)`),
                }}
            >
                <div className={styles.content}>
                    <div className={styles.title}>扭蛋券 x 1</div>
                    <div className={styles.intro}>
                        歡迎來到親子天下永續教育年會現場，迎接下個教育轉型的關鍵十年
                    </div>
                    <div className={styles.action}>
                        <CurvedButton
                            className={styles.applyBtn}
                            size="xs"
                            disabled={contentState !== 'available'}
                            onClick={() => {
                                if (contentState === 'available') {
                                    onGetTicket(activePoint);
                                }
                            }}
                        >
                            {contentState === 'used' ? '已領取' : '放入背包'}
                        </CurvedButton>
                        {contentState === 'disabled' && <div className={styles.warn}>積分不足</div>}
                    </div>
                </div>
                {media === 'desktop' ? (
                    <ArrowLine className={styles.bottomLine} />
                ) : (
                    <ArrowLineShort className={styles.bottomLine} />
                )}
            </animated.div>
            <div
                className={styles.progressBar}
                style={{
                    transform: `translateX(${
                        (page - 1) *
                        (media === 'desktop' ? 218 : (window.innerWidth - 32 - 140) / 2) *
                        -1
                    }px)`,
                }}
            >
                <div className={styles.track}>
                    <div className={styles.base} />
                    <div
                        className={styles.active}
                        style={{
                            width:
                                media === 'desktop'
                                    ? (totalPoints / 1000) * 218 + 218 / 2 + 14
                                    : (totalPoints / 1000) * ((window.innerWidth - 32 - 140) / 2) +
                                      35,
                        }}
                    />
                </div>
                {Array.from({ length: 100 }, (_, i) => i + 1).map((key) => {
                    const point = 1000 * key;
                    const ticketState = checkTicketState(1000 * key, totalPoints, redeemPoints);

                    return (
                        <div
                            key={key}
                            className={classnames(
                                styles.breakpoint,
                                ticketState === 'available' && styles.new,
                                ticketState === 'used' && styles.used,
                                ticketState === 'disabled' && styles.disabled,
                                key >= page - 1 && key <= page + 1 && styles.display,
                            )}
                        >
                            <CurvedContainer
                                className={styles.hoverMask}
                                size="xs"
                                borderColor="--accent-lightgreen"
                                filledColor="rgba(2, 233, 184, 0.3)"
                                transparent
                                onClick={() => {
                                    if (point !== activePoint) {
                                        setPage(point / 1000);
                                    }
                                }}
                            />
                            <div className={styles.icon}>
                                <img src={ticket} alt="ticket" />
                                {ticketState === 'used' && <Checked className={styles.checked} />}
                                {ticketState === 'available' && (
                                    <div className={styles.notification} />
                                )}
                                {ticketState === 'disabled' && <Locked className={styles.locked} />}
                            </div>
                            <div className={styles.dot} />
                            <div className={styles.point}>{point}</div>
                        </div>
                    );
                })}
            </div>
            <ArrowBtn
                className={classnames(styles.action, styles.prev)}
                disabled={page <= 1}
                type="prev"
                onClick={onClickPrev}
            />
            <ArrowBtn
                className={classnames(styles.action, styles.next)}
                disabled={page >= 100}
                type="next"
                onClick={onClickNext}
            />
        </ModalBaseCenter>
    );
};

const ModalUserPoints: React.FC<ModalUserPointsProp> = ({
    className,
    active = true,
    onCloseFinish = () => {},
}) => {
    const [{ totalPoints, redeemPoints }, { getPointDetail }] = useUserData();
    const [, { getTicket }] = useEvent();

    useEffect(() => {
        getPointDetail();
    }, []);

    return (
        <ModalUserPointsLayout
            className={className}
            active={active}
            totalPoints={totalPoints}
            redeemPoints={redeemPoints}
            onCloseFinish={onCloseFinish}
            onGetTicket={getTicket}
        />
    );
};

export default ModalUserPoints;
