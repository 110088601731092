import { useState, useEffect } from 'react';

import { useMedia as useNewBreakpoint } from 'react-use';

import type { ScreenSize } from 'utils/stageConfig/types';

const useStageMedia = () => {
    const [stageMedia, setStageMedia] = useState<ScreenSize>('largeDesktop');

    const matchLargeDesktop = useNewBreakpoint('(min-width: 1367px)');
    const matchSmallDesktop = useNewBreakpoint('(min-width: 900px)');

    useEffect(() => {
        if (matchLargeDesktop) {
            setStageMedia('largeDesktop');
        } else if (matchSmallDesktop) {
            setStageMedia('smallDesktop');
        } else {
            setStageMedia('mobile');
        }
    }, [matchLargeDesktop, matchSmallDesktop]);

    return stageMedia;
};

export default useStageMedia;
