// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n    .index__pop-wow___2fhKv .index__green___fuyra {\n        color: #02E9B8;\n    }\n", "",{"version":3,"sources":["webpack://./src/layouts/PopWow/index.css"],"names":[],"mappings":";IACI;QACI,cAA+B;IACnC","sourcesContent":[".pop-wow {\n    & .green {\n        color: var(--accent-lightgreen);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pop-wow": "index__pop-wow___2fhKv",
	"popWow": "index__pop-wow___2fhKv",
	"green": "index__green___fuyra"
};
export default ___CSS_LOADER_EXPORT___;
