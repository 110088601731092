import { useState, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useSpring, animated, config } from 'react-spring';
import Lottie from 'lottie-react';
import { isMobile } from 'react-device-detect';
import useSound from 'use-sound';

import { useSoundOn } from 'models/mainSite';
import CurvedButton from 'components/atoms/CurvedButton';

import type { MouseEvent } from 'react';
import type { LottieRefCurrentProps } from 'lottie-react';

import hoverSound from 'audio/button-hover.mp3';
import selectedSound from 'audio/button-selected.mp3';

import styles from './index.css';
import animeJson from './focus-anime.json';

export interface Button3DProp {
    className?: string;
    spaceTop?: number;
    spaceBottom?: number;
    spaceLeft?: number;
    spaceRight?: number;
    disabled?: boolean;
    theme?: 'blue' | 'white';
    delayLong?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    // nativeOnClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Button3D: React.FC<Button3DProp> = ({
    className,
    spaceTop = 60,
    spaceBottom = 60,
    spaceLeft = 40,
    spaceRight = 40,
    disabled = false,
    theme = 'white',
    onClick = () => {},
    delayLong,
    // nativeOnClick,
    children,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const layer1Ref = useRef<HTMLDivElement>(null);
    const layer2Ref = useRef<HTMLDivElement>(null);
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);

    const [soundOn] = useSoundOn();

    const [playHoverSound, { stop: stopHoverSound }] = useSound(hoverSound, { preload: true });
    const [playClickSound, { stop: stopClickSound }] = useSound(selectedSound, { preload: true });

    const nativeClickTimeout = useRef<number | null>(null);

    const [hovering, setHovering] = useState(false);

    const [{ rotation }, rotateApi] = useSpring(() => ({
        rotation: [0, 0],
        config: config.slow,
    }));

    const [{ offset }, shakeApi] = useSpring(
        () => ({
            offset: 0,
            config: {
                ...config.stiff,
                duration: 300,
            },
        }),
        [],
    );

    const onMouseEnter = useCallback(() => {
        if (lottieRef.current) {
            lottieRef.current.goToAndPlay(0);
        }
    }, []);

    const onMouseMove = useCallback((event: MouseEvent<HTMLDivElement>) => {
        setHovering(true);

        if (ref.current && layer1Ref.current) {
            const { left, width, top, height } = ref.current.getBoundingClientRect();

            const originX = left + Math.floor(width / 2);
            const originY = top + Math.floor(height / 2);

            const nextMouseX = event.clientX - originX;
            const nextMouseY = event.clientY - originY;

            let rotateY = (nextMouseY / layer1Ref.current.offsetHeight / 2) * 40 * -1;
            let rotateX = (nextMouseX / layer1Ref.current.offsetWidth / 2) * 40 * 2;

            if (rotateY > 15) {
                rotateY = 15;
            }
            if (rotateY < -15) {
                rotateY = -15;
            }
            if (rotateX > 20) {
                rotateX = 20;
            }
            if (rotateX < -20) {
                rotateX = -20;
            }

            rotateApi.start({
                rotation: [rotateY, rotateX],
            });
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        rotateApi.start({
            rotation: [0, 0],
        });
        setHovering(false);
    }, []);

    const shake = useCallback(() => {
        if (isMobile && lottieRef.current) {
            lottieRef.current.goToAndPlay(0);
        }

        stopHoverSound();

        if (soundOn) {
            playClickSound();
        }

        // rotateApi.start({
        //     rotation: [0, 0],
        //     immediate: true,
        // });
        // shakeApi.start({
        //     from: { offset: 0 },
        //     to: { offset: 1 },
        //     onRest: onClick,
        // });
    }, [shakeApi, soundOn]);

    useEffect(() => {
        if (nativeClickTimeout.current) {
            window.clearTimeout(nativeClickTimeout.current);
            nativeClickTimeout.current = null;
        }

        return () => {
            if (nativeClickTimeout.current) {
                window.clearTimeout(nativeClickTimeout.current);
                nativeClickTimeout.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (hovering) {
            stopClickSound();
            if (soundOn) {
                playHoverSound();
            }
        } else {
            stopHoverSound();
        }
    }, [hovering, soundOn]);

    return (
        <animated.div
            ref={ref}
            className={classnames(styles.button3D, disabled && styles.disabled, className)}
            onMouseEnter={() => {
                if (!isMobile) {
                    onMouseEnter();
                }
            }}
            onMouseMove={(event) => {
                if (!isMobile) {
                    onMouseMove(event);
                }
            }}
            onMouseLeave={(event) => {
                if (!isMobile) {
                    onMouseLeave(event);
                }
            }}
            style={{
                paddingTop: spaceTop,
                paddingBottom: spaceBottom,
                paddingLeft: spaceLeft,
                paddingRight: spaceRight,

                transform: offset
                    .to({
                        range: [0, 0.25, 0.5, 0.75, 1],
                        output: [0, 3, 3, 2, 0],
                    })
                    .to(
                        (value) =>
                            `translate(${value - Math.random() * value}px, ${
                                value - Math.random() * value
                            }px)`,
                    ),
            }}
        >
            <animated.div
                ref={layer1Ref}
                className={classnames(styles.layer, styles.back, !hovering && styles.hide)}
                style={{
                    transform: rotation.to(
                        (x: number, y: number) =>
                            `perspective(500px) scale(1.05) rotateX(${x}deg) rotateY(${y}deg) translateZ(-30px)`,
                    ),
                    top: spaceTop,
                    left: spaceLeft,
                }}
            >
                <CurvedButton
                    className={styles.backBtn}
                    childrenClassName={styles.backChildren}
                    outline
                    theme={theme}
                    withHover={false}
                />
            </animated.div>
            <animated.div
                ref={layer2Ref}
                className={styles.layer}
                style={{
                    transform: rotation.to(
                        (x: number, y: number) =>
                            `perspective(500px) rotateX(${x}deg) rotateY(${y}deg) translateZ(0)`,
                    ),
                }}
            >
                <CurvedButton
                    childrenClassName={styles.mainChildren}
                    theme={theme}
                    withHover={false}
                    onClick={(event) => {
                        if (nativeClickTimeout.current) {
                            window.clearTimeout(nativeClickTimeout.current);
                            nativeClickTimeout.current = null;
                        }

                        if (onClick) {
                            if (delayLong) {
                                nativeClickTimeout.current = window.setTimeout(() => {
                                    onClick(event);
                                }, 1200);
                            } else {
                                nativeClickTimeout.current = window.setTimeout(() => {
                                    onClick(event);
                                }, 300);
                            }
                        }

                        shake();
                    }}
                >
                    <Lottie
                        lottieRef={lottieRef}
                        className={styles.lottie}
                        animationData={animeJson}
                        autoplay={false}
                        loop={false}
                    />
                    <animated.div
                        className={styles.aboveAnime}
                        style={{
                            opacity: offset.to({
                                range: [0, 0.25, 0.5, 0.75, 1],
                                output: [1, 0.3, 0.9, 0.6, 1],
                            }),
                        }}
                    >
                        {children}
                    </animated.div>
                </CurvedButton>
            </animated.div>
        </animated.div>
    );
};

export default Button3D;
