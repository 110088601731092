import classnames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useModal } from 'models/modal';
import { useTransition } from 'models/mainSite';
import { useAuth } from 'models/auth';
import { useBag } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';

import articleStyles from 'layouts/articleStyles.css';
import styles from './index.css';

export interface ImpactHub1Prop {
    className?: string;
    onCloseFinish?: () => void;
}

const ImpactHub1: React.FC<ImpactHub1Prop> = ({ className, onCloseFinish = () => {} }) => {
    const history = useHistory();
    const [{ loginType }] = useAuth();

    const [active, setActive] = useState(true);
    const [, { startTransition }] = useTransition();
    const [, { openModal }] = useModal();
    const [, { updateBagView }] = useBag();

    return (
        <ModalBaseRight
            active={active}
            className={classnames(styles.modalFaq, className)}
            title="FAQ"
            onCloseFinish={onCloseFinish}
        >
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q1</span>
                    <br />
                    如何登入探索未來城市？
                </div>
                <ol>
                    <li>點擊首頁登入探索，登入FB帳號密碼即可快速登入。</li>
                    <li>若無FB帳號，可點擊訪客登入模式即可進入未來城市進行探索看論壇節目。</li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q2</span>
                    <br />
                    「登入探索」 跟 「訪客進入」 有甚麼差別？
                </div>
                <ol>
                    <li>
                        登入探索可以參與累計積分活動，可參加扭蛋抽獎活動及積分排名，換取永續好禮。
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q3</span>
                    <br />
                    點擊 「訪客進入」 後如果要想累計積分怎麼辦？
                </div>
                <ol>
                    <li>可點擊任何積分按鈕 (如圖)，即可登入探索模式開始累積積分。</li>
                </ol>
                <div className={styles.buttonWrapper}>
                    <div style={{ margin: -30 }}>
                        <ButtonPoint
                            className={articleStyles.pointBtn}
                            points={500}
                            text="領取積分"
                            sendEvent={false}
                            needIcon
                        />
                    </div>
                </div>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q4</span>
                    <br />
                    論壇節目在哪裡看？
                </div>
                <ol>
                    <li>
                        點擊{' '}
                        <span
                            role="presentation"
                            className={articleStyles.link}
                            onClick={() => {
                                const nowTown = history.location.pathname.split('/')[1];
                                const nextTown = 'town';

                                if (nowTown === nextTown) {
                                    history.push('/town/events');
                                } else {
                                    startTransition({ nextPath: '/town/events' });
                                }

                                setActive(false);
                            }}
                        >
                            「未來加速會展中心 Speed up」
                        </span>{' '}
                        ，即可選擇節目觀看。
                    </li>
                    <li>
                        點選右側{' '}
                        <span
                            role="presentation"
                            className={articleStyles.link}
                            onClick={() => {
                                openModal({ type: 'menu' });
                                setActive(false);
                            }}
                        >
                            「菜單」
                        </span>{' '}
                        點選{' '}
                        <span
                            role="presentation"
                            className={articleStyles.link}
                            onClick={() => {
                                const nowTown = history.location.pathname.split('/')[1];
                                const nextTown = 'town';

                                if (nowTown === nextTown) {
                                    history.push('/town/events');
                                } else {
                                    startTransition({ nextPath: '/town/events' });
                                }

                                setActive(false);
                            }}
                        >
                            「前往節目總表」
                        </span>{' '}
                        即可選擇節目觀看。
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q5</span>
                    <br />
                    請問如何累積積分？
                </div>
                <ol>
                    <li>
                        進入未來城市中，每一個體驗皆可累積積分，完成任務點擊 「領取積分按扭」
                        即完成該任務積分累積，每一個任務可以累積一次。
                    </li>
                    <li>積分攻略 -1：留言版留言，可重複累積積分，每分享一次即累計一次。</li>
                    <li>
                        積分攻略 -2：點擊主畫面中左下角 「角色人物」
                        圖像，登入親子天下會員，可獲取高積分。
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q6</span>
                    <br />
                    請問抽到實體獎品如何領取？
                </div>
                <ol>
                    <li>
                        請於獲得獎項三日內完成{' '}
                        <span
                            role="presentation"
                            className={articleStyles.link}
                            onClick={() => {
                                if (loginType === 'facebook') {
                                    updateBagView('form');
                                    openModal({ type: 'userBag' });
                                } else {
                                    openModal({ type: 'needLogin' });
                                }
                                setActive(false);
                            }}
                        >
                            「收件資料」
                        </span>
                        與{' '}
                        <a
                            className={articleStyles.link}
                            href="https://cplink.co/aYsLJ6w4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            「得獎同意書」
                        </a>
                        填寫並檢附身分證正反面影本，供主辦單位辦理中獎所得扣繳作業。
                    </li>
                    <li>
                        未於獲得獎項三日內完成 「收件資料」 與「得獎同意書」填寫者視同放棄獎項。
                    </li>
                    <li>
                        確認同意書內容無誤後，將統一於2022年1月15日前寄出獎品，若1月31日前未收到獎品，請致電客服專線詢問：886-2-2662-0332
                        ；服務時間：週一～週五：9:00~17:30
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q7</span>
                    <br />
                    請問積分排名累計到甚麼時候？會如何公佈前10名？
                </div>
                <ol>
                    <li>積分排名累計至2021年12月19日晚上23：59：59截止。</li>
                    <li>
                        積分排行榜前10名將會截圖存檔，同時後台亦存有數據紀錄，主辦單位將於12月20日於
                        <a
                            className={articleStyles.link}
                            href="https://www.facebook.com/parentingtw?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '親子天下Facebook粉絲團',
                                });
                            }}
                        >
                            「親子天下Facebook粉絲團」
                        </a>
                        及活動網站公佈得獎名單，請得獎者於12月22日前主動私訊聯繫。
                    </li>
                    <li>
                        請於2021年12月26日前完成
                        <a
                            className={articleStyles.link}
                            href="https://cplink.co/aYsLJ6w4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            「得獎同意書」
                        </a>
                        填寫並檢附身分證正反面影本，供主辦單位辦理中獎所得扣繳作業。若2021年12月26日晚上23：59：59前未完成「收件資料」與「得獎同意書」填寫者視同放棄獎項。
                    </li>
                    <li>
                        確認同意書內容無誤後，將統一於2022年1月15日前寄出獎品，若1月31日前未收到獎品，請致電客服專線詢問：
                        886-2-2662-0332 ；服務時間：週一～週五：9:00~17:30
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q8</span>
                    <br />
                    活動到甚麼時候？
                </div>
                <ol>
                    <li>
                        活動將持續進行可隨時進入網站探索未來城市，但扭蛋抽獎活動及積分排名將於2021年12月19日晚上23：59：59結束，歡迎把握時間探索未來城市。
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q9</span>
                    <br />
                    如何領取扭蛋抽獎活動獲得的「親子天下相關折價序號」？
                </div>
                <ol>
                    <li>
                        凡扭蛋抽獎獲得「親子天下有聲故事書-免費聽1個月」、「親子天下線上學校-折價券100元」與「親子天下Shopping-教育年會折價券150元」獎項之得獎者，其獎項之【兌換序號
                        或 折扣碼】與其使用方式請依照下列步驟領取：
                        <br />
                        (1) 請至主畫面左下方點選「背包」按鈕
                        <br />
                        (2) 進入「背包」並從所有獲獎獎項中，找到此三樣獎品
                        <br />
                        (3) 點選任一樣獎品左上方的「獎品說明」按鈕，即可獲得【兌換序號 或
                        折扣碼】與其使用說明。”
                    </li>
                    <li>
                        如操作上有任何問題，歡迎致電客服專線詢問：886-2-2662-0332
                        ；服務時間：週一～週五：9:00~17:30
                    </li>
                </ol>
            </div>
            <div className={styles.question}>
                <div className={styles.title}>
                    <span className={articleStyles.green}>Q10</span>
                    <br />
                    請問線上論壇節目會放多久？
                </div>
                <ol>
                    <li>直播回放會【永久】存放在本網站及親子天下Youtube頻道中。</li>
                </ol>
            </div>
        </ModalBaseRight>
    );
};

export default ImpactHub1;
