import image5_1 from 'images/future-live/5-1.png';
import image5_2 from 'images/future-live/5-2.png';
import image5_3 from 'images/future-live/5-3.png';
import image5_4 from 'images/future-live/5-4.png';

export default [
    {
        no: 1,
        layer: 5,
        path: '/future-live/intro',
        leave: false,
        src: image5_1,

        title: '永續未來媒體中心',
        enTitle: 'Future LIVE',
        width: 614,
        height: 502,
        position: [1264, 1059],
        anchorType: 'dotOrange',

        anchorPosition: [-130, -50],
    },
    {
        no: 2,
        layer: 3,
        // path: 'https://edu.parenting.com.tw/event/SDGs-450?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
        leave: false,
        src: image5_2,

        title: 'SDGs永續x教育專輯',
        enTitle: '',
        width: 363,
        height: 496,
        position: [2181, 700],
        anchorType: 'dotOrange',

        anchorPosition: [0, -50],
        link: 'https://edu.parenting.com.tw/event/SDGs-450?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
    },
    {
        no: 3,
        layer: 4,
        path: '/future-live/live',
        leave: false,
        src: image5_3,

        title: '聽見未來LIVE電台',
        enTitle: '',
        width: 434,
        height: 311,
        // position: [1180, 550],
        position: [1221, 731],
        anchorType: 'dotOrange',

        anchorPosition: [-25, -50],
    },
    {
        no: 4,
        layer: 6,
        path: '/future-live/messages',
        leave: false,
        src: image5_4,

        title: '永續行動留言板',
        enTitle: '',
        width: 161,
        height: 424,
        position: [925, 583],
        anchorType: 'dotOrange',

        anchorPosition: [-10, -50],
    },
];
