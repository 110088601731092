import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { openNotification, openModal } from 'models/modal/actions';

import { getUserData } from 'models/user/actions';

import { wrapFetch, wrapAuthFetch } from 'utils/api';

interface Message {
    messageId: number;
    likes: number;
    author: string;
    authorCharacterType: number;
    authorActiveTheme: number;
    createTime: string;
    content: string;
    liked: boolean;
}

export const fetchMessages = createAsyncThunk(
    'message/fetchMessages',
    async ({ page }: { page: number }, { getState, dispatch, rejectWithValue }) => {
        const {
            auth: { loginType },
        } = getState();

        let messageCount = 0;
        let totalPage = 0;
        let messages = [];
        const messagesData = {};

        if (loginType === 'facebook') {
            const loginResult = await wrapAuthFetch(
                '/message',
                {
                    method: 'GET',
                },
                { page, count: 15 },
            );

            if (!loginResult.success) {
                return rejectWithValue(loginResult.msg);
            }
            messageCount = loginResult.message_count;
            totalPage = loginResult.message_pages;
            loginResult.messages.forEach(
                ({
                    message_id,
                    likes,
                    author,
                    author_active_character,
                    author_active_theme,
                    create_time,
                    content,
                    liked,
                }) => {
                    messages.push(message_id);
                    messagesData[message_id] = {
                        messageId: message_id,
                        likes: likes,
                        author: author,
                        authorCharacterType:
                            author_active_theme > 2800
                                ? author_active_theme
                                : author_active_character,
                        createTime: create_time,
                        content: content,
                        liked: liked,
                    };
                },
            );
        } else {
            const normalResult = await wrapFetch(
                '/message',
                {
                    method: 'GET',
                },
                { page, count: 5 },
            );

            if (!normalResult.success) {
                return rejectWithValue(normalResult.msg);
            }

            messageCount = normalResult.message_count;
            totalPage = normalResult.message_pages;
            normalResult.messages.forEach(
                ({
                    message_id,
                    likes,
                    author,
                    author_active_character,
                    author_active_theme,
                    create_time,
                    content,
                    liked,
                }) => {
                    messages.push(message_id);
                    messagesData[message_id] = {
                        messageId: message_id,
                        likes: likes,
                        author: author,
                        authorCharacterType:
                            author_active_theme > 2800
                                ? author_active_theme
                                : author_active_character,
                        createTime: create_time,
                        content: content,
                        liked: liked,
                    };
                },
            );
        }

        return {
            messageCount,
            totalPage,
            messages,
            messagesData,
            page,
        };
    },
);

export const likeMessage = createAsyncThunk(
    'message/likeMessage',
    async ({ messageId, like }, { getState, dispatch, rejectWithValue }) => {
        const {
            auth: { loginType },
        } = getState();

        if (loginType !== 'facebook') {
            dispatch(
                openModal({
                    type: 'needLogin',
                }),
            );

            return rejectWithValue('not login');
        }

        if (like) {
            const cancelResult = await wrapAuthFetch('/message/like/cancle', {
                method: 'POST',
                body: JSON.stringify({
                    message_id: messageId,
                }),
            });

            if (!cancelResult.success) {
                return rejectWithValue(cancelResult.msg);
            }
        } else {
            const likeResult = await wrapAuthFetch('/message/like', {
                method: 'POST',
                body: JSON.stringify({
                    message_id: messageId,
                }),
            });

            if (!likeResult.success) {
                return rejectWithValue(likeResult.msg);
            }
        }

        return {
            messageId,
            like: !like,
        };
    },
);

export const getLastCreate = createAsyncThunk(
    'message/getLastCreate',
    async (_, { rejectWithValue }) => {
        const result = await wrapAuthFetch('/user/lastMessageTime', {
            method: 'GET',
        });

        if (!result.success) {
            return rejectWithValue(result.msg);
        }

        return result.create_time;
    },
);

export const sendMessage = createAsyncThunk(
    'message/sendMessage',
    async (content, { getState, dispatch, rejectWithValue }) => {
        const {
            auth: { loginType },
        } = getState();

        if (loginType !== 'facebook') {
            dispatch(
                openModal({
                    type: 'needLogin',
                }),
            );

            return rejectWithValue('not login');
        }

        const result = await wrapAuthFetch('/message', {
            method: 'POST',
            body: JSON.stringify({
                content,
            }),
        });

        if (!result.success) {
            return rejectWithValue(result.msg);
        }

        if (result.get_point) {
            dispatch(
                openNotification({
                    type: 'points',
                    message: '留言成功，積分+300',
                }),
            );
        }

        dispatch(getLastCreate());
        dispatch(getUserData());
    },
);
