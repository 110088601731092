import classnames from 'classnames';

import { useTicket } from 'models/user';

import { numberWithCommas } from 'utils/helper';

import styles from './index.css';

const max = 4000;

export interface TicketProgressProp {
    className?: string;
}

const TicketProgress: React.FC<TicketProgressProp> = ({ className }) => {
    const [{ siteTotalUsedTicket: ticketAmount }, {}] = useTicket();

    return (
        <div className={classnames(styles.ticketProgress, className)}>
            <div className={styles.progress}>
                <div
                    className={styles.progressValue}
                    style={{
                        width: `${
                            (ticketAmount / max) * 100 <= 100 ? (ticketAmount / max) * 100 : 100
                        }%`,
                    }}
                />
                <div className={styles.step}>
                    <div className={styles.value}>20%</div>
                    <div className={styles.block} />
                </div>
                <div className={styles.step}>
                    <div className={styles.value}>40%</div>
                    <div className={styles.block} />
                </div>
                <div className={styles.step}>
                    <div className={styles.value}>60%</div>
                    <div className={styles.block} />
                </div>
                <div className={styles.step}>
                    <div className={styles.value}>80%</div>
                    <div className={styles.block} />
                </div>
                <div className={styles.step}>
                    <div className={styles.value}>100%</div>
                    <div className={styles.block} />
                </div>
            </div>
            <div className={styles.progressText}>
                扭轉進度：{numberWithCommas(ticketAmount)} / 4,000
            </div>
        </div>
    );
};

export default TicketProgress;
