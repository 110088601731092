import useRedux from 'utils/hooks/useRedux';

import {
    setAssetsLoaded,
    setLoading,
    setHideUI,
    setEnteringView,
    startTransition,
    endTransition,
    setRedirectPath,
    clearRedirectPath,
    setFirstVisit,
    getRank,
    setSoundOn,
    setMapReady,
} from './actions';

export const useAssetsLoaded = () =>
    useRedux((state) => state.mainSite.assetsLoaded, {
        setAssetsLoaded,
    });

export const useLoading = () =>
    useRedux((state) => state.mainSite.loading, {
        setLoading,
    });

export const useUI = () =>
    useRedux(
        (state) => ({
            hide: state.mainSite.hideUI,
        }),
        {
            hideUI: setHideUI,
        },
    );

export const useEntering = () =>
    useRedux((state) => state.mainSite.entering, {
        setEnteringView,
    });

export const useTransition = () =>
    useRedux((state) => state.mainSite.transition, {
        startTransition,
        endTransition,
    });

export const useRedirectPath = () =>
    useRedux((state) => state.mainSite.redirectPath, {
        setRedirectPath,
        clearRedirectPath,
    });

export const useFirstVisit = () =>
    useRedux((state) => state.mainSite.firstVisit, {
        setFirstVisit,
    });

export const useRankData = () =>
    useRedux((state) => state.mainSite.rankDataList, {
        getRank,
    });

export const useSoundOn = () =>
    useRedux((state) => state.mainSite.soundOn, {
        setSoundOn,
    });

export const useMapReady = () =>
    useRedux((state) => state.mainSite.mapReady, {
        setMapReady,
    });
