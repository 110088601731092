import { useHistory } from 'react-router-dom';
import { useLoading, useEntering } from 'models/mainSite';

const NotFound: React.FC = () => {
    const [, { setLoading }] = useLoading();
    const [, { setEnteringView }] = useEntering();

    setEnteringView('login');
    setLoading(false);

    const history = useHistory();
    history.push('/');

    return <div />;
};

export default NotFound;
