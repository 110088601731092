import classnames from 'classnames';

import styles from './index.css';

export interface ProgressProp {
    className?: string;
    max: number;
    value: number;
}

const Progress: React.FC<ProgressProp> = ({ className, value, max }) => {
    return (
        <div className={classnames(styles.progress, className)}>
            <div className={styles.value} style={{ width: `${(value / max) * 100}%` }} />
            <div className={styles.track} />
        </div>
    );
};

export default Progress;
