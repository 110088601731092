import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DateTime } from 'luxon';

import { useEvent } from 'models/user';
import { useOpenBuilding, useMedia } from 'utils/hooks';
import { useMessages } from 'models/messages';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';
import MessageCard from 'components/atoms/MessageCard';
import ButtonRound from 'components/atoms/ButtonRound';
import ArrowBtn from 'components/atoms/ArrowBtn';

import image1 from 'images/mainTown/next-to.png';
import User from 'images/icons/user.inline.svg';
import star from 'images/common/star-bg.png';

import logo1_1 from 'images/mainTown/logo-1-1.png';
import logo1_2 from 'images/mainTown/logo-1-2.png';
import logo2_1 from 'images/mainTown/logo-2-1.png';
import logo2_2 from 'images/mainTown/logo-2-2.png';
import logo3_1 from 'images/mainTown/logo-3-1.png';
import logo3_2 from 'images/mainTown/logo-3-2.png';
import logo4_1 from 'images/mainTown/logo-4-1.png';
import logo4_2 from 'images/mainTown/logo-4-2.png';
import logo4_3 from 'images/mainTown/logo-4-3.png';
import logo4_4 from 'images/mainTown/logo-4-4.png';
import logo5_1 from 'images/mainTown/logo-5-1.png';
import logo5_2 from 'images/mainTown/logo-5-2.png';
import logo6_1 from 'images/mainTown/logo-6-1.png';
import logo6_2 from 'images/mainTown/logo-6-2.png';
import logo6_3 from 'images/mainTown/logo-6-3.png';
import logo6_4 from 'images/mainTown/logo-6-4.png';
import logo6_5 from 'images/mainTown/logo-6-5.png';
import logo6_6 from 'images/mainTown/logo-6-6.png';
import logo6_7 from 'images/mainTown/logo-6-7.png';
import logo7_1 from 'images/mainTown/logo-7-1.png';
import logo8_1 from 'images/mainTown/logo-8-1.png';
import logo9_1 from 'images/mainTown/logo-9-1.png';
import logo9_2 from 'images/mainTown/logo-9-2.png';
import logo9_3 from 'images/mainTown/logo-9-3.png';
import logo10_1 from 'images/mainTown/logo-10-1.png';
import logo10_2 from 'images/mainTown/logo-10-2.png';
import logo10_3 from 'images/mainTown/logo-10-3.png';
import logo10_4 from 'images/mainTown/logo-10-4.png';

import { useAuth } from 'models/auth';
import { useModal } from 'models/modal';
import Countdown from 'react-countdown';

import 'swiper/swiper.min.css';

import articleStyles from '../articleStyles.css';

import styles from './index.css';

export interface NextToProp {
    className?: string;
}

const NextTo: React.FC<NextToProp> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();
    const [
        { messages, messagesData, lastCreateTime, messageCount },
        { fetchMessages, likeMessage, getLastCreate },
    ] = useMessages();
    const [{ loginType }] = useAuth();
    const [, { openModal }] = useModal();

    const media = useMedia();

    const openComplete = useOpenBuilding('main', 10);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [swiper, setSwiper] = useState(false);
    const [swiper2, setSwiper2] = useState(false);

    const [sendDisabled, setSendDisabled] = useState(true);

    useEffect(() => {
        setIsSetup(true);
        fetchMessages({ page: 1 });

        if (loginType === 'facebook') {
            getLastCreate();
        }
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    useEffect(() => {
        if (lastCreateTime) {
            setSendDisabled(true);
        } else {
            setSendDisabled(false);
        }
    }, [lastCreateTime]);

    // return isReady ? (
    return (
        <ModalBaseRight
            className={classnames(styles.nextTo, className)}
            title="明天轉運站"
            enTitle="Next to"
            active
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image1} alt="main" />
                </div>
                <div className={classnames(articleStyles.title, styles.title)}>
                    未來留言牆 FUTURE WALL
                </div>
                <div className={articleStyles.content}>
                    我們想要什麼樣的現在、希望有什麼樣的未來，不是一個人的一言堂，而是需要不斷地討論、思考、辯證，才有可能找到一個共好的解方。就如同永續不是是非選擇題，未來也不是。永續與未來是一個需要持續辯證的申論題，所以，把你的想法、你的思考或是你的叨叨絮語，都留在這裡吧！每一句話、每一個文字、每一次的分享，都是給予彼此繼續往前的重要力量。{' '}
                </div>
            </div>

            {/* <div className={styles.action}>
                <ButtonPoint
                    className={styles.btn}
                    text="前往留言"
                    sendEvent={false}
                    points={300}
                    needIcon
                    disabled={sendDisabled}
                    onClick={() => {
                        if (loginType === 'facebook') {
                            openModal({
                                type: 'messagesSend',
                            });
                        } else {
                            openModal({
                                type: 'needLogin',
                            });
                        }
                    }}
                />
                {lastCreateTime && (
                    <div className={styles.warn}>
                        <span style={{ marginRight: 4 }}>可再次留言時間到數</span>
                        <Countdown
                            daysInHours
                            date={DateTime.fromFormat(lastCreateTime, 'yyyy-MM-dd hh:mm:ss')
                                .plus({
                                    hours: 1,
                                    minutes: 1,
                                })
                                .toString()}
                            onComplete={() => {
                                setSendDisabled(false);
                            }}
                        />
                    </div>
                )}
                <div className={styles.note}>
                    <User /> 共 {messageCount} 位探險者在這留言
                </div>
            </div> */}

            <div className={styles.starBg} style={{ backgroundImage: `url(${star})` }} />

            <div className={styles.slliderWrapper}>
                {media === 'desktop' && messages.length > 0 && (
                    <Swiper
                        slidesPerView="auto"
                        centeredSlides
                        loop
                        onSwiper={(s) => setSwiper(s)}
                        slidesOffsetBefore={-20}
                        spaceBetween={0}
                    >
                        {messages.slice(0, 4).map((id) => {
                            const {
                                messageId,
                                authorCharacterType,
                                author,
                                content,
                                createTime,
                                likes,
                                liked,
                            } = messagesData[id];

                            return (
                                <SwiperSlide key={messageId} className={styles.slide}>
                                    <MessageCard
                                        messageId={messageId}
                                        characterType={authorCharacterType}
                                        name={author}
                                        content={content}
                                        time={createTime}
                                        likes={likes}
                                        liked={liked}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )}
                {media === 'phone' && messages.length > 0 && (
                    <Swiper
                        slidesPerView={1}
                        centeredSlides
                        loop
                        onSwiper={(s) => setSwiper2(s)}
                        slidesOffsetBefore={0}
                        spaceBetween={40}
                    >
                        {messages.slice(0, 4).map((id) => {
                            const {
                                messageId,
                                authorCharacterType,
                                author,
                                content,
                                createTime,
                                likes,
                                liked,
                            } = messagesData[id];

                            return (
                                <SwiperSlide key={messageId} className={styles.slide}>
                                    <MessageCard
                                        messageId={messageId}
                                        characterType={authorCharacterType}
                                        name={author}
                                        content={content}
                                        time={createTime}
                                        likes={likes}
                                        liked={liked}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )}
                <div className={styles.shadowLeft} />
                <div className={styles.shadowRight} />
                <ArrowBtn
                    className={classnames(styles.action, styles.prev)}
                    type="prev"
                    onClick={() => {
                        if (media === 'desktop' && swiper) {
                            swiper.slidePrev();
                        }

                        if (media === 'phone' && swiper2) {
                            swiper2.slidePrev();
                        }
                    }}
                />
                <ArrowBtn
                    className={classnames(styles.action, styles.next)}
                    type="next"
                    onClick={() => {
                        if (media === 'desktop' && swiper) {
                            swiper.slideNext();
                        }

                        if (media === 'phone' && swiper2) {
                            swiper2.slideNext();
                        }
                    }}
                />
            </div>

            <div className={styles.fakeContent}>
                <div className={styles.note}>
                    <User /> 共 {messageCount} 位探險者在這留言
                </div>
                <ButtonRound
                    className={styles.btn}
                    text="所有留言"
                    onClick={() => {
                        openModal({
                            type: 'messages',
                        });
                    }}
                />
            </div>

            <div className={styles.sponsors}>
                <div className={articleStyles.title}>2021 迎向永續未來的轉運閘口</div>
                <div className={styles.group}>
                    <div className={styles.label}>主辦單位</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.parenting.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 親子天下',
                                });
                            }}
                        >
                            <img src={logo1_1} alt="logo" />
                        </a>
                        <a
                            href="http://reading.cw.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 天下雜誌教育金金會',
                                });
                            }}
                        >
                            <img src={logo1_2} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>指導單位</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.ndc.gov.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 國家發展委員會',
                                });
                            }}
                        >
                            <img src={logo2_1} alt="logo" />
                        </a>
                        <a
                            href="https://www.ndc.gov.tw/Content_List.aspx?n=18A17E168AEA1B0A&utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Startup Island TAIWAN',
                                });
                            }}
                        >
                            <img src={logo2_2} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>共同推動</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://cht.pegatroncorp.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 和碩聯合科技',
                                });
                            }}
                        >
                            <img src={logo3_1} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>補助單位</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.k12ea.gov.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 教育部國民及學前教育署',
                                });
                            }}
                        >
                            <img src={logo3_2} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>策略夥伴 </div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.trendmicro.com/zh_tw/forHome/edu.html?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 趨勢科技',
                                });
                            }}
                        >
                            <img src={logo4_1} alt="logo" />
                        </a>
                        <a
                            href="https://linecorp.com/zh-hant/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- LINE',
                                });
                            }}
                        >
                            <img src={logo4_2} alt="logo" />
                        </a>
                        <a
                            href="https://topic.parenting.com.tw/event/2019/financialedu/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- HSBC滙豐',
                                });
                            }}
                        >
                            <img src={logo4_3} alt="logo" />
                        </a>
                        <a
                            href="https://www.seasonart.org/foundation/index.php?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 四季藝術',
                                });
                            }}
                        >
                            <img src={logo4_4} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>共同策展</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://taipei.impacthub.net/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Impact Hub Taipei',
                                });
                            }}
                        >
                            <img src={logo5_1} alt="logo" />
                        </a>
                        <a
                            href="https://polish-design.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Polish Design',
                                });
                            }}
                        >
                            <img src={logo5_2} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>推動夥伴</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.kumon-km.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- KUMON',
                                });
                            }}
                        >
                            <img src={logo6_1} alt="logo" />
                        </a>
                        <a
                            href="https://www.cgds.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 雲門',
                                });
                            }}
                        >
                            <img src={logo6_2} alt="logo" />
                        </a>
                        <a
                            href="https://heysong.org.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 黑松教育基金會',
                                });
                            }}
                        >
                            <img src={logo6_3} alt="logo" />
                        </a>
                        <a
                            href="https://www.goodneighbors.org.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 台灣好鄰居協會',
                                });
                            }}
                        >
                            <img src={logo6_4} alt="logo" />
                        </a>
                        <a
                            href="https://sunnyfounder.com/individual/civic-influence?utm_medium=referral&utm_source=edu.parenting.com.tw&utm_campaign=parenting_cw
"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 陽光伏特加',
                                });
                            }}
                        >
                            <img src={logo6_5} alt="logo" />
                        </a>
                        <a
                            href="https://www.greenvines.com.tw/pages/2021-the-new-greenvines?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 綠藤生機',
                                });
                            }}
                        >
                            <img src={logo6_6} alt="logo" />
                        </a>
                        <a
                            href="https://www.zeiss.com.tw/vision-care/spectacle-lenses-from-zeiss/myopia-management-lens-solutions.html?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- zeiss',
                                });
                            }}
                        >
                            <img src={logo6_7} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>直播合作夥伴</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="http://www.jectordigital.com/"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 傑可達',
                                });
                            }}
                        >
                            <img src={logo7_1} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>大會指定抗菌品牌</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://www.aquecare.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 天淨',
                                });
                            }}
                        >
                            <img src={logo8_1} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>永續共好夥伴</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://coorlife.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- REX(COOE IMAGINATION)',
                                });
                            }}
                        >
                            <img src={logo9_1} alt="logo" />
                        </a>
                        <a
                            href="https://bit.ly/3coBR1T?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Kobo',
                                });
                            }}
                        >
                            <img src={logo9_2} alt="logo" />
                        </a>
                        <a
                            href="https://www.domiearth.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- DOMI',
                                });
                            }}
                        >
                            <img src={logo9_3} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.label}>媒體夥伴</div>
                    <div className={styles.logoWrapper}>
                        <a
                            href="https://tw.news.yahoo.com/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Yahoo! 新聞',
                                });
                            }}
                        >
                            <img src={logo10_1} alt="logo" />
                        </a>
                        <a
                            href="https://meet.bnext.com.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- Meet 創業小聚',
                                });
                            }}
                        >
                            <img src={logo10_2} alt="logo" />
                        </a>
                        <a
                            href="https://udn.com/news/index?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 聯合新聞網',
                                });
                            }}
                        >
                            <img src={logo10_3} alt="logo" />
                        </a>
                        <a
                            href="https://www.seinsights.asia/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                window.dataLayer.push({
                                    event: 'clicklink',
                                    eventlable: '明天轉運站 --- 社企流',
                                });
                            }}
                        >
                            <img src={logo10_4} alt="logo" />
                        </a>
                    </div>
                </div>
            </div>
        </ModalBaseRight>
    );
    // ) : null;
};

export default NextTo;
