// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__sponsor6___3iGB7 {\n}\n", "",{"version":3,"sources":["webpack://./src/layouts/Sponsor7/index.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".sponsor6 {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsor6": "index__sponsor6___3iGB7"
};
export default ___CSS_LOADER_EXPORT___;
