import artone from 'images/seasonart/artone.png';
import arttwo from 'images/seasonart/arttwo.png';
import artthree from 'images/seasonart/artthree.png';
import artfour from 'images/seasonart/artfour.png';

export default [
    {
        no: 1,
        path: '/season-art/about',
        leave: false,
        src: artone,
        title: '關於四季藝術',
        enTitle: '',
        width: 442,
        height: 294,
        position: [1080, 722],
        anchorType: 'dotOrange',
        anchorPosition: [0, -28],
    },
    {
        no: 2,
        path: '/season-art/1',
        leave: false,
        src: arttwo,
        title: '幼兒藝術創客X光影實驗室',
        enTitle: '',
        width: 398,
        height: 424,
        position: [1548, 696],
        anchorType: 'dotOrange',
        anchorPosition: [0, 0],
    },
    {
        no: 3,
        path: '/season-art/2',
        leave: false,
        src: artthree,
        title: '有愛無礙-用愛轉動生命',
        enTitle: '',
        width: 396,
        height: 294,
        position: [650, 623],
        anchorType: 'dotOrange',
        anchorPosition: [0, -50],
    },
    {
        no: 4,
        path: '/season-art/3',
        leave: false,
        src: artfour,
        title: 'FUN 趣 ESL 創客教育',
        enTitle: '',
        width: 496,
        height: 391,
        position: [832, 1070],
        anchorType: 'dotOrange',
        anchorPosition: [-50, 0],
    },
];
