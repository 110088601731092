// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__app-root___3dXlx {\n    width: 100vw;\n    overflow: hidden\n}\n.index__app-root___3dXlx > .index__entering___3nB79 {\n        position: absolute;\n        top: 0;\n        left: 0;\n    }\n.index__app-root___3dXlx > .index__white-bg___2Ty3f {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        opacity: 0;\n        background-color: #FDF7EC;\n        pointer-events: none;\n        -webkit-transition: opacity 0.3s ease-in-out;\n        transition: opacity 0.3s ease-in-out;\n        z-index: -1\n    }\n.index__app-root___3dXlx > .index__white-bg___2Ty3f.index__active___1r7JI {\n            opacity: 1;\n        }\n", "",{"version":3,"sources":["webpack://./src/layouts/App/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ;AAwBJ;AAtBI;QACI,kBAAkB;QAClB,MAAM;QACN,OAAO;IACX;AAEA;QACI,kBAAkB;QAClB,MAAM;QACN,OAAO;QACP,WAAW;QACX,YAAY;QACZ,UAAU;QACV,yBAAsC;QACtC,oBAAoB;QACpB,4CAAoC;QAApC,oCAAoC;QACpC;IAKJ;AAHI;YACI,UAAU;QACd","sourcesContent":[".app-root {\n    width: 100vw;\n    overflow: hidden;\n\n    & > .entering {\n        position: absolute;\n        top: 0;\n        left: 0;\n    }\n\n    & > .white-bg {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        opacity: 0;\n        background-color: var(--primary-light);\n        pointer-events: none;\n        transition: opacity 0.3s ease-in-out;\n        z-index: -1;\n\n        &.active {\n            opacity: 1;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-root": "index__app-root___3dXlx",
	"appRoot": "index__app-root___3dXlx",
	"entering": "index__entering___3nB79",
	"white-bg": "index__white-bg___2Ty3f",
	"whiteBg": "index__white-bg___2Ty3f",
	"active": "index__active___1r7JI"
};
export default ___CSS_LOADER_EXPORT___;
