// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__button-ticket___37yBz {\n    overflow: visible;\n    display: -webkit-box;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n            flex-direction: column;\n    -webkit-box-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n            align-items: center;\n}\n\n.index__warn___3m51d {\n    font-size: 12px;\n    font-weight: bold;\n    color: #FF4182;\n    text-align: center;\n    margin-top: -20px;\n    margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/atoms/ButtonTicket/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAa;IAAb,aAAa;IACb,4BAAsB;IAAtB,6BAAsB;YAAtB,sBAAsB;IACtB,wBAAuB;YAAvB,uBAAuB;IACvB,yBAAmB;YAAnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAA2B;IAC3B,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".button-ticket {\n    overflow: visible;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.warn {\n    font-size: 12px;\n    font-weight: bold;\n    color: var(--secondary-red);\n    text-align: center;\n    margin-top: -20px;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-ticket": "index__button-ticket___37yBz",
	"buttonTicket": "index__button-ticket___37yBz",
	"warn": "index__warn___3m51d"
};
export default ___CSS_LOADER_EXPORT___;
