import { useCallback } from 'react';
import classnames from 'classnames';

import { useEvent } from 'models/user';
import { useAuth } from 'models/auth';
import { useModal } from 'models/modal';

import Button3D from 'components/atoms/Button3D';

import pointGif from 'images/icons/points-anime.gif';
import couponGif from 'images/icons/coupon-anime.gif';
import couponStill from 'images/icons/coupon-still.png';

import styles from './index.css';

export interface ButtonPointLayoutProp {
    className?: string;
    type: 'ticket' | 'points' | 'none';
    points?: number;
    spaceTop?: number;
    spaceBottom?: number;
    spaceLeft?: number;
    spaceRight?: number;
    eventId?: number;
    disabled?: boolean;
    onClick?: () => void;
}

export const ButtonPointLayout: React.FC<ButtonPointLayoutProp> = ({
    className,
    type = 'points',
    points,
    spaceTop = 30,
    spaceBottom = 30,
    spaceLeft,
    spaceRight,
    disabled = false,
    onClick = () => {},
    children,
    needIcon,
}) => {
    return (
        <Button3D
            className={classnames(styles.buttonPoint, className)}
            theme="blue"
            spaceTop={spaceTop}
            spaceBottom={spaceBottom}
            spaceLeft={spaceLeft}
            spaceRight={spaceRight}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
            <div className={classnames(styles.decorator, styles[type || 'points'])}>
                {(type === 'points' || type === 'ticket') && (
                    <div className={styles.points}>{points}</div>
                )}
                <div className={classnames(styles.icon, styles[type || 'points'])}>
                    {type === 'points' && <img src={pointGif} alt="icon" />}
                    {type === 'ticket' && (
                        <img src={disabled ? couponStill : couponGif} alt="icon" />
                    )}
                </div>
            </div>
        </Button3D>
    );
};

export interface ButtonPointProp {
    className?: string;
    points: number;
    text: string;
    eventId: number;
    msg?: string;
    sendEvent?: boolean;
    onClick?: () => void;
}

const ButtonPoint: React.FC<ButtonPointProp> = ({
    className,
    points,
    text = '',
    eventId,
    msg = '',
    sendEvent = true,
    onClick: onClickProp,
    disabled,
    needIcon,
}) => {
    const [{ loginType }] = useAuth();
    const [{ events }, { getPoint }] = useEvent();
    const [, { openModal }] = useModal();

    const onClick = useCallback(() => {
        if (sendEvent) {
            if (loginType === 'facebook') {
                if (onClickProp) {
                    onClickProp();
                }

                if (eventId && events.indexOf(eventId) === -1) {
                    getPoint({ points, eventId, msg: `${msg || '領取成功'}，積分 +${points}` });
                }

                if (!eventId) {
                    getPoint({ points, msg: `${msg || '領取成功'}，積分 +${points}` });
                }
            } else {
                /* eslint-disable */
                if (onClickProp) {
                    onClickProp();
                } else {
                    openModal({
                        type: 'needLogin',
                    });
                }
                /* eslint-enable */
            }
        } else {
            /* eslint-disable */
            if (onClickProp) {
                onClickProp();
            }
            /* eslint-enable */
        }
    }, [events, onClickProp, sendEvent]);

    return (
        <ButtonPointLayout
            className={classnames(styles.buttonPoint, className)}
            type={(eventId && events.indexOf(eventId) === -1) || needIcon ? 'points' : 'none'}
            points={points}
            onClick={onClick}
            disabled={disabled}
            needIcon={needIcon}
        >
            {text}
        </ButtonPointLayout>
    );
};

export default ButtonPoint;
