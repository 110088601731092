import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent, useTicket } from 'models/user';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';
import TicketProgress from 'components/molecules/TicketProgress';
import Button3D from 'components/atoms/Button3D';

import { useOpenBuilding } from 'utils/hooks';

import image1 from 'images/mainTown/play-1.png';
import image2 from 'images/mainTown/play-2.png';
import image3 from 'images/mainTown/play-3.png';
import imageA from 'images/common/0001.png';
import imageB from 'images/common/0002.png';
import imageC from 'images/common/0003.png';
import imageD from 'images/common/0004.png';
import image4 from 'images/common/ticket-progress.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface PlayForPowerProp {
    className?: string;
}

const PlayForPower: React.FC<PlayForPowerProp> = ({ className }) => {
    const history = useHistory();

    const [{ siteTotalUsedTicket }, { getTotalUsedTicket }] = useTicket();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 8);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        getTotalUsedTicket();

        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    // return isReady ? (
    return (
        <ModalBaseRight
            className={classnames(styles.playForPower, className)}
            title="給力遊樂場"
            enTitle="Play for Power"
            onCloseFinish={() => {
                history.push('/town');
            }}
            // disabled
            // disabledText="12/2 和我們一起見證扭轉未來"
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image1} alt="main" />
                </div>
                <TicketProgress className={styles.progress} />
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.title}>
                    你願意貢獻每一次0.01的力量，讓改變可以成真嗎？
                </div>
                <div className={articleStyles.content}>
                    市民們還記得，在這座永續未來城市中，
                    <span style={{ color: '#02E9B8' }}>
                        你的每一次行動都是實現永續未來的重要力量嗎？
                    </span>
                    城市的一角落，有一個曾經快樂、曾經繁榮、曾經充滿生機的角落，因為城市的發展、人類生存資源的瓜分；因為這塊土地的人們在快速前進的過程中，忘記在這裡還有不同的物種、豐富的生態環境是與我們共存的。原本有許多獨特動物生存的環境、原本充滿豐富地貌景觀的空間，被廢棄的垃圾、遭受污染的空氣與水所取代，代表快樂能量的旋轉木馬也不再轉起。
                    <br />
                    <br />
                    發現這件事情的永續未來城市發展團隊，在城市的中央搭建起巨大的《未來扭轉塔Twist
                    NOW!》，想要鼓勵每一個市民，讓市民們知道他們在城市中的每一次對永續未來行動的選擇、支持，都是一次能夠積累改變能量的機會。在未來扭轉塔中，每有一個市民玩了一次扭蛋，這個扭轉的力量就會灌入樂園中。每一次的微小力量，積累到一定程度，團隊就能幫助這個樂園清除一點垃圾、讓多一點的樹木和乾淨的空氣與水回到這塊土地上。
                    <br />
                    <br />
                    如果你問，要多少力量的積累，樂園才能真正恢復如初？團隊預估至少要 4
                    千次的扭轉力量加成，我們才能重新啟動這個樂園、我們才能讓動物重新回到這塊土地、我們才能讓過往多樣的生態重新再現....我們才能讓我們的土地不是只有人類自己。
                    <br />
                    <br /> 所以，你準備好和我們一起「給」予這「樂園」一點「力量」了嗎？
                </div>
            </div>
            <div className={articleStyles.hr} />
            <div className={articleStyles.section}>
                <div className={articleStyles.title} style={{ textAlign: 'center' }}>
                    Ready to Play for Power!
                    <br />
                    改變就差你，和我們一起見證未來的扭轉
                </div>
                <div className={styles.progressImg}>
                    <img src={image4} alt="progress" />
                    <div className={styles.progressNum}>
                        {Math.round((siteTotalUsedTicket / 4000) * 100)}%
                    </div>
                </div>
                <TicketProgress className={styles.progress} />
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.btnWrapper}>
                    <Button3D
                        className={articleStyles.button3D}
                        onClick={() => {
                            history.push('/town/twist-now');
                        }}
                    >
                        使用扭蛋券，扭轉現況
                    </Button3D>
                </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.title}>不只好玩，真實的困境還需要我們一起努力！</div>
                <div className={articleStyles.content}>
                    在永續未來城市中，SDGs不只是Sustainable Development Goals的縮寫
                    更是一種生活的態度與方式：打開感受（Sense）/勇敢實踐（Do）/培養改變世界的行動者（Generate）。
                    <br />
                    <br />
                    通過給力遊樂場的體驗，我們想要大家用遊戲的方式打開對「SDGs」議題的感受。再通過在城市中對議題的了解探索、分享實踐，能意識到我們的每一個選擇、每一個行動（光是把資訊分享出去也是），都是至關重要的。
                    <br />
                    <br />
                    而給力遊樂場中，這個破敗的角落也不是憑空而生的困境，它正是我們當前世界面臨到的重大環境議題。
                </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.title}>10年消失28個台灣面積的森林</div>
                <div className={articleStyles.content}>
                    你知道嗎？人類為獲取天然資源、為了工業的生產和城市開發等經濟利益，不斷地掠奪自然資源，在2000至2020年，10年間全球共消失了1億公頃（近28個台灣）的森林面積，也導致37,400種物種瀕臨絕種。{' '}
                    <br />
                    <br />
                </div>
                <div className={articleStyles.image}>
                    <img src={imageA} alt="main" />
                    <div className={articleStyles.caption}>劉國泰 攝影</div>
                </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.title}>
                    你知道我們可能馬上要面臨第六次生物大滅絕嗎？
                </div>
                <div className={articleStyles.content}>
                    森林不見了與人類有什麼關係？生物變少了，會怎麼樣嗎？
                    <br />
                    <br />
                    「生物多樣性」是人類生存的重要指標之一，有豐富的生物多樣性，才能有穩定的生態系。近50年來人類的破壞，讓地球多樣的生物形態正以驚人的速度快速減少。全球目前約有100萬物種瀕臨絕種，其嚴重程度相當於6,500萬年前的第五次物種大滅絕。生物多樣性就像保護著地球的安全網一樣，任何一個物種消失都可能影響整個生態系。
                    <br />
                    <br />
                </div>
                <div className={articleStyles.image}>
                    <img src={imageB} alt="main" />
                    <div className={articleStyles.caption}>圖片來源：Shutterstock</div>
                </div>

                <div className={articleStyles.content}>
                    你覺得生態系的崩壞離自己很遠嗎？不，在台灣有60.9%的面積被森林覆蓋，山林間的露營活動也成國人熱門休閒活動之一，不過至2021年5月全台近2000家露營場中，僅不到一成為合法營地。違規設置的露營場不僅造成森林被濫墾濫伐，營地中廢棄物和汙水的汙染，破壞了許多原生動物的家園，更造成台灣獨特物種面臨著絕種危機。{' '}
                </div>
                <br />
                <div className={articleStyles.image}>
                    <img src={imageC} alt="main" />
                    <div className={articleStyles.caption}>圖片來源：Shutterstock</div>
                </div>

                <div className={articleStyles.image}>
                    <img src={imageD} alt="main" />
                    <div className={articleStyles.caption}>
                        圖片為情境示意，非台灣林地真實照片（圖片來源：Shutterstock）
                    </div>
                </div>
            </div>
            <div className={articleStyles.section}>
                <div className={articleStyles.content} style={{ textAlign: 'center' }}>
                    所以 <br />
                    你的每一個選擇都有意義 <br />
                    你的每一次行動都有力量 <br />
                    你的每一回分享都有影響 <br />
                    <br />
                    和我們一起把行動的能量帶進日常 <br /> <br />
                    讓未來真的能不一樣！
                </div>
            </div>
            <div className={articleStyles.btnWrapper}>
                <Button3D
                    className={articleStyles.button3D}
                    onClick={() => {
                        history.push('/town/twist-now');
                    }}
                >
                    使用扭蛋券，扭轉現況
                </Button3D>
                {events.indexOf(801) === -1 && (
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={500}
                        text="領取積分"
                        eventId={801}
                        msg=""
                    />
                )}
            </div>
            <div className={articleStyles.section}>
                <br />
                <div className={articleStyles.caption} style={{ textAlign: 'left' }}>
                    {`資料來源與整理自：親子天下2021年11月號雜誌封面故事<Let’s SDGs 培養改變世界的行動者>、行政院農業委員會「農政與農情」月刊電子書第271期<永續森林經營 維護生態保育>、Greenpeace 綠色和平<與您我存亡息息相關，卻常被忽略的「生物多樣性」>。`}
                </div>
            </div>
        </ModalBaseRight>
    );
    // ) : null;
};

export default PlayForPower;
