import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';

import pointIcon from 'images/icons/points-still.png';
import ticketIcon from 'images/icons/coupon.png';

import styles from './index.css';

export interface NotificationProp {
    className?: string;
    message?: string;
    type?: string;
    onCloseComplete?: () => void;
}

const Notification: React.FC<NotificationProp> = ({
    className,
    type,
    message,
    onCloseComplete = () => {},
}) => {
    const [{ height }, springApi] = useSpring(() => ({
        height: 0,
    }));
    const [open, setOpen] = useState(true);

    useEffect(() => {
        springApi.start({
            height: 115,
            onRest: () => {
                if (open) {
                    setOpen(false);
                    springApi.start({
                        delay: 500,
                        height: 0,
                        onRest: () => {
                            onCloseComplete();
                        },
                    });
                }
            },
        });
    }, []);

    return (
        <div className={classnames(styles.notification, className)}>
            <animated.div className={styles.message} style={{ height }}>
                <div className={styles.content}>
                    {type === 'points' && (
                        <span className={classnames(styles.icon, styles.points)}>
                            <img src={pointIcon} alt="icon" />
                        </span>
                    )}
                    {type === 'ticket' && (
                        <span className={classnames(styles.icon, styles.ticket)}>
                            <img src={ticketIcon} alt="icon" />
                        </span>
                    )}
                    <span className={styles.text}>{message}</span>
                </div>
            </animated.div>
        </div>
    );
};

export default Notification;
