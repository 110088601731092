import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useEvent } from 'models/user';
import { useOpenBuilding } from 'utils/hooks';

import ModalBaseRight from 'components/organisms/ModalBaseRight';
import ButtonPoint from 'components/atoms/ButtonPoint';
import Button3D from 'components/atoms/Button3D';

import image0 from 'images/mainTown/sponsor-3_0.png';
import image1 from 'images/mainTown/sponsor-3_1.png';
import image2 from 'images/mainTown/sponsor-3_2.png';
import image3 from 'images/mainTown/sponsor-3_3.png';
import image4 from 'images/mainTown/sponsor-3_4.png';
import image5 from 'images/mainTown/sponsor-3_5.png';
import image6 from 'images/mainTown/sponsor-3_6.png';
import image7 from 'images/mainTown/sponsor-3_7.png';
import image8 from 'images/mainTown/sponsor-3_8.png';

import articleStyles from '../articleStyles.css';
import styles from './index.css';

export interface Sponsor1Prop {
    className?: string;
}

const Sponsor1: React.FC<Sponsor1Prop> = ({ className }) => {
    const history = useHistory();

    const [{ events }] = useEvent();

    const openComplete = useOpenBuilding('main', 3, true);
    const [isSetup, setIsSetup] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsSetup(true);
    }, []);

    useEffect(() => {
        if (openComplete && isSetup) {
            setIsReady(true);
        }
    }, [openComplete, isSetup]);

    return isReady ? (
        <ModalBaseRight
            className={classnames(styles.sponsor1, className)}
            title="黑松教育基金會"
            onCloseFinish={() => {
                history.push('/town');
            }}
        >
            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image1} alt="main" />
                </div>

                <div className={articleStyles.image}>
                    <img src={image0} alt="logo" />
                </div>

                <div className={articleStyles.content}>
                    我們希望讓孩子們在未來成長為愛護環境、守護土地的重要力量，並能以永續性思維，全面思考人類發展，成為問題解決者、行動者！我們透過發展三大計畫，期望成為學校發展環境教育的最佳夥伴！
                </div>
            </div>

            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image2} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    「愛地兒」除了代表愛地球的孩子，也代表「IDEA—解決問題的想法」，我們希望讓孩子透過行動實踐創意點子，成為守護地球的小尖兵！2015年起辦理，孩子共提出86個創意環境提案，改善水汙染、全球暖化等問題。
                </div>
                <br />
                <br />
                <div className={articleStyles.image}>
                    <img src={image7} alt="main" />
                </div>
            </div>

            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image3} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    以「校園」作為環境教育發展起點，在「綠+校園」中，所有環境行動都由孩子主導！「綠+(plus)」有集結大家的力量以及讓環境變得更好的意涵。2016年起我們陪伴學校發展生態學校，共協助學校取得環保署臺美生態學校認證3面綠旗、18面銀牌、23面銅牌。
                </div>
                <br />
                <br />
                <div className={articleStyles.image}>
                    <img src={image6} alt="main" />
                </div>
            </div>

            <div className={articleStyles.section}>
                <div className={articleStyles.image}>
                    <img src={image4} alt="main" />
                </div>
                <div className={articleStyles.content}>
                    與學校共同尋找在地特色，透過課程發展深耕在地價值。「尋耕」為「尋根」的雙關，期待能帶著孩子尋根，找回與土地的連結，並在未來付諸行動，照顧自己的家鄉。2020年起協助3所學校建構特色課程，其中桃園市新屋區社子國小更獲得桃園市教學卓越獎。
                    <br />
                    <br />
                    <div className={articleStyles.image}>
                        <img src={image8} alt="main" />
                    </div>
                    來到2021年尾聲，邀請大家與我們共享8年的成果！我們將於12月舉辦年會活動，活動消息請鎖定黑松教育基金會官網，歡迎來森！
                </div>
                <br />
                <br />
                <div className={articleStyles.image}>
                    <img src={image5} alt="main" />
                </div>
            </div>

            <div className={articleStyles.btnWrapper}>
                <Button3D
                    className={articleStyles.button3D}
                    onClick={() => {
                        window.open(
                            'https://heysong.org.tw/?utm_source=2021conference.Website&utm_medium=referral&utm_campaign=conferenceonline',
                            '_blank',
                        );
                        window.dataLayer.push({
                            event: 'clicklink',
                            eventlable: '黑松教育基金會 --- 了解更多',
                        });
                    }}
                >
                    了解更多
                </Button3D>
                {events.indexOf(1401) === -1 && (
                    <ButtonPoint
                        className={articleStyles.pointBtn}
                        points={300}
                        text="領取積分"
                        eventId={1401}
                        msg=""
                    />
                )}
            </div>
        </ModalBaseRight>
    ) : null;
};

export default Sponsor1;
