import classnames from 'classnames';

import Arrow from 'images/icons/arrow-left.inline.svg';

import styles from './index.css';

export interface ArrowBtnProp {
    className?: string;
    disabled?: boolean;
    type: 'prev' | 'next';
    onClick?: () => void;
}

const ArrowBtn: React.FC<ArrowBtnProp> = ({ className, disabled, type, onClick = () => {} }) => (
    <button
        className={classnames(
            styles.arrowBtn,
            styles[type],
            disabled && styles.disabled,
            className,
        )}
        type="button"
        onClick={onClick}
    >
        <Arrow />
    </button>
);

export default ArrowBtn;
