import EventListLayout from 'layouts/EventList';

import { renderRoutes } from 'react-router-config';

import type { RouteConfigComponentProps } from 'react-router-config';

const Events = ({ route }: RouteConfigComponentProps) => {
    return (
        <>
            <EventListLayout />
            {/* child routes won't render without this */}
            {renderRoutes(route && route.routes)}
        </>
    );
};

export default Events;
