import img1 from 'images/events/10-1.png';

import type { EventData } from './types';

const data: EventData = {
    id: 10,
    category: 'workshop',
    title: '設計思考 X 創業理財跨領域教案體驗工作坊',
    contentTitle: '設計思考 X 創業理財跨領域教案體驗工作坊',
    date: '12/3',
    startTime: '13:30',
    endTime: '16:35',
    // note: '本場活動採事先報名，未報名者可於活動結束後觀看精彩回顧影片！',
    intro: '課程將以「設計思考」精神和概念為核心，以日常生活問題為出發，透過換位思考、反覆驗證和調整，孕育解方發想創意產品，培養整合跨域資源與邏輯思考的能力。為讓學員理解創業過程中所需的理財概念，課堂將打造群眾募資模擬情境，讓學員從銷售自己的產品過程中，練習探索市場產品的價值與價格，從而思考每樣產品從製造到售出背後的成本、何謂合理價格（低價是否為唯一解方？），「你的每一次消費，都在為你理想中的世界投票」正是呼應世界永續發展 SDGs 的真正實踐。',
    youtubeLiveId: 'Ruqy2nQ99Gs',
    youtubeVideoId: 'Ruqy2nQ99Gs',
    sponsor: '本場活動由策略夥伴 滙豐(台灣)商業銀行 共同倡議',
    agenda: [
        {
            title: '開場',
            startTime: '13:30',
            endTime: '13:45',
        },
        {
            title: '設計思考 X 創業理財',
            startTime: '13:45',
            endTime: '15:25',
            speaker: [
                {
                    name: '林士恩',
                    intro: '奇德王國資深講師',
                },
            ],
        },
        {
            title: '中場休息',
            startTime: '15:25',
            endTime: '15:35',
        },
        {
            title: '小組交流',
            startTime: '15:35',
            endTime: '16:00',
        },
        {
            title: '【成果分享】',
            startTime: '16:00',
            endTime: '16:35',
        },
    ],
    speakerImages: [img1],
};

export default data;
