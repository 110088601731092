import { createReducer } from '@reduxjs/toolkit';

import { openModal, closeModal, openNotification, closeNotification } from './actions';

import type { ModalType, NotificationType } from './types';

export interface State {
    modal: Record<ModalType, { active: boolean; data: { [key: string]: any } }>;
    notification: {
        active: boolean;
        type?: NotificationType;
        message?: string;
    };
}

const initialState: State = {
    modal: {
        menu: {
            active: false,
            data: {},
        },
        exploreInfo: {
            active: false,
            data: {},
        },
        userPoints: {
            active: false,
            data: {},
        },
        userCloset: {
            active: false,
            data: {},
        },
        userBag: {
            active: false,
            data: {},
        },
        userTicket: {
            active: false,
            data: {},
        },
        recommend: {
            active: false,
            data: {},
        },
        giftDetail: {
            active: false,
            data: {},
        },
        giftNew: {
            active: false,
            data: {},
        },
        giftList: {
            active: false,
            data: {},
        },
        needLogin: {
            active: false,
            data: {},
        },
        messages: {
            active: false,
            data: {},
        },
        messagesDetail: {
            active: false,
            data: {},
        },
        messagesSend: {
            active: false,
            data: {},
        },
        rank: {
            active: false,
            data: {},
        },
        faq: {
            active: false,
            data: {},
        },
        pronounce: {
            active: false,
            data: {},
        },
        profile: {
            active: false,
            data: {},
        },
    },
    notification: {
        active: false,
        type: 'default',
        message: '',
    },
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(openModal, (state, action) => {
            state.modal[action.payload.type] = {
                active: true,
                data: action.payload.data || {},
            };
        })
        .addCase(closeModal, (state, action) => {
            state.modal[action.payload] = {
                active: false,
                data: {},
            };
        })
        .addCase(openNotification, (state, action) => {
            state.notification = {
                active: true,
                type: action.payload.type || 'default',
                message: action.payload.message || '',
            };
        })
        .addCase(closeNotification, (state) => {
            state.notification = {
                active: false,
                type: 'default',
                message: '',
            };
        });
});

export default reducer;
