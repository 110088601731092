import { useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { isWindows } from 'react-device-detect';
import { useScroll, useMeasure } from 'react-use';

import { useFadeIn } from 'utils/hooks';

import Back from 'images/icons/arrow-forward.inline.svg';

import styles from './index.css';

export interface ModalBaseLargeProp {
    className?: string;
    childrenClassName?: string;
    active?: boolean;
    hasCloseBtn?: boolean;
    maskOpacity?: number;
    onCloseFinish?: () => void;
    onScrollEnd?: () => void;
}

const ModalBaseLarge: React.FC<ModalBaseLargeProp> = ({
    className,
    childrenClassName,
    active = true,
    hasCloseBtn = true,
    onCloseFinish = () => {},
    onScrollEnd = () => {},
    children,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const { fadeOut } = useFadeIn([{ ref }], {
        timeScale: 2,
        fadeOut: true,
        onFadeoutComplete: onCloseFinish,
    });

    const close = useCallback(() => {
        if (fadeOut) {
            fadeOut();
        }
    }, [fadeOut]);

    useEffect(() => {
        if (!active) {
            close();
        }
    }, [active]);

    const [modalRef, { height }] = useMeasure();
    const { y } = useScroll(ref);

    useEffect(() => {
        if (height && y + window.innerHeight > height - 60) {
            onScrollEnd();
        }
    }, [y, height]);

    return (
        <div
            ref={ref}
            className={classnames(
                styles.modalBaseLarge,
                isWindows && styles.customScrollBar,
                className,
            )}
        >
            <div
                className={styles.mask}
                onClick={close}
                role="presentation"
                onKeyPress={() => {}}
            />
            <div ref={modalRef} className={classnames(styles.modalWrapper, childrenClassName)}>
                {children}
                {hasCloseBtn && (
                    <div
                        className={styles.back}
                        onClick={close}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => {}}
                    >
                        <Back />
                        <span className={styles.text}>返回</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalBaseLarge;
