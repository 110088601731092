interface Data {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}
const data: Data = {};
let isLocalStorageSupport = true;

export const detectLocalStorageSupport = () => {
    try {
        window.localStorage.setItem('test', '1');
        window.localStorage.removeItem('test');
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Does not support localStorage', e);
        isLocalStorageSupport = false;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setItem = (key: string, value: any) => {
    if (isLocalStorageSupport) {
        window.localStorage.setItem(key, value);
        return;
    }

    data[key] = value;
};

const getItem = (key: string) => {
    if (isLocalStorageSupport) {
        return window.localStorage.getItem(key);
    }

    if (Object.hasOwnProperty.call(data, key)) {
        return data[key];
    }

    return null;
};

const removeItem = (key: string) => {
    if (isLocalStorageSupport) {
        window.localStorage.removeItem(key);
        return;
    }

    if (Object.hasOwnProperty.call(data, key)) {
        delete data[key];
    }
};

export default {
    setItem,
    getItem,
    removeItem,
};
