import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useModal } from 'models/modal';
import { useUserData, useBag } from 'models/user';

import { useMedia } from 'utils/hooks';

import ModalBaseCenter from 'components/organisms/ModalBaseCenter';
import ArrowBtn from 'components/atoms/ArrowBtn';
import Progress from 'components/atoms/Progress';
import Button3D from 'components/atoms/Button3D';

import prizeData from 'data/prize';

import icon from 'images/icons/clothes.png';
import Info from 'images/icons/item-info.inline.svg';
import Arrow from 'images/icons/arrow-left.inline.svg';
import Check from 'images/icons/check.inline.svg';

import articleStyles from 'layouts/articleStyles.css';

import styles from './index.css';

interface Gift {
    giftId: number;
    themeId: number;
}

export interface ModalBagLayoutProp {
    className?: string;
    data: Gift[];
    onCloseFinish?: () => void;
    view: 'bag' | 'form' | 'info' | 'complete';
    onChangeView: (view: 'bag' | 'form' | 'info' | 'complete') => void;
}

const titleMap = {
    bag: '我的背包',
    form: '收件資料填寫',
    info: '本活動領獎相關說明',
    complete: '資料確認',
};

const subtitleMap = {
    bag: () => (
        <>
            請於得獎後三天內，完成「收件資訊」與
            <a
                className={articleStyles.link}
                href="https://cplink.co/aYsLJ6w4"
                target="_blank"
                rel="noreferrer"
            >
                「得獎同意書」
            </a>
            填寫！
        </>
    ),
    form: '',
    info: '',
    complete: '這將會是您的探索實體禮品寄送地址！',
};

export const ModalBagLayout: React.FC<ModalBagLayoutProp> = ({
    className,
    data = [],
    shippingInfo,
    view = 'bag',
    onCloseFinish = () => {},
    onChangeView = () => {},
    onSubmit = () => {},
}) => {
    const media = useMedia();
    const [
        {
            giftDetail: { active: giftDetailActive },
        },
        { openModal },
    ] = useModal();

    const [active, setActive] = useState(true);
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [page, setPage] = useState(0);

    const [formName, setFormName] = useState(shippingInfo.name);
    const [formPhone, setFormPhone] = useState(shippingInfo.phone);
    const [formEmail, setFormEmail] = useState(shippingInfo.email);
    const [formCity, setFormCity] = useState(shippingInfo.city);
    const [formDist, setFormDist] = useState(shippingInfo.dist);
    const [formAddress, setFormAddress] = useState(shippingInfo.address);
    const [formConfirm, setFormConfirm] = useState(false);

    useEffect(() => {
        return () => {
            if (swiper) {
                swiper.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [media, swiper]);

    return (
        <ModalBaseCenter
            className={classnames(styles.modalCloset, className)}
            active={active}
            icon={view === 'bag' && icon}
            title={titleMap[view]}
            // subtitle={subtitleMap[view]}
            bottomBtn={view === 'bag'}
            onCloseFinish={() => {
                onCloseFinish();
                onChangeView('bag');
            }}
            canClose={!giftDetailActive}
            mobileAlignTop
        >
            {view !== 'bag' && (
                <Arrow
                    className={styles.back}
                    onClick={() => {
                        if (view === 'info') {
                            onChangeView('form');
                        } else {
                            onChangeView('bag');
                        }
                    }}
                />
            )}
            {view === 'bag' && (
                <div className={classnames(styles.content, styles[view])}>
                    {data.length === 0 && <div className={styles.empty}>目前包包內沒有物品</div>}
                    {data.length > 0 && (
                        <div className={styles.slider}>
                            <Swiper
                                slidesPerView={1}
                                centeredSlides
                                onSwiper={(s) => setSwiper(s)}
                                onSlideChange={(e) => {
                                    setPage(e.activeIndex);
                                }}
                                breakpoints={{
                                    900: {
                                        slidesPerView: data.length === 2 ? 2 : 3,
                                        centeredSlides: data.length === 1,
                                    },
                                }}
                            >
                                {data.map(({ giftId, themeId, code }) => {
                                    let giftData = null;

                                    if (giftId === 25 || giftId === 27) {
                                        giftData = prizeData[giftId].themes[themeId];
                                    } else if (giftId) {
                                        giftData = prizeData[giftId];
                                    }

                                    return giftData ? (
                                        <SwiperSlide
                                            key={`${giftId}-${themeId}`}
                                            className={styles.slide}
                                        >
                                            <div
                                                className={classnames(styles.item)}
                                                role="button"
                                                tabIndex={0}
                                                onKeyPress={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Info
                                                    className={styles.info}
                                                    onClick={() => {
                                                        openModal({
                                                            type: 'giftDetail',
                                                            data: {
                                                                giftId,
                                                                themeId,
                                                                code:
                                                                    giftId === 24
                                                                        ? 'educate15'
                                                                        : code,
                                                            },
                                                        });
                                                    }}
                                                />
                                                <div className={styles.avatar}>
                                                    <img src={giftData.image} alt="avatar" />
                                                </div>
                                                <div className={styles.name}>{giftData.name}</div>
                                            </div>
                                        </SwiperSlide>
                                    ) : null;
                                })}
                            </Swiper>
                        </div>
                    )}
                    {((media === 'phone' && data.length > 1) ||
                        (media === 'desktop' && data.length > 3)) && (
                        <>
                            <ArrowBtn
                                className={classnames(styles.action, styles.prev)}
                                disabled={page <= 0}
                                type="prev"
                                onClick={() => {
                                    if (swiper) {
                                        swiper.slidePrev();
                                    }
                                }}
                            />
                            <ArrowBtn
                                className={classnames(styles.action, styles.next)}
                                disabled={
                                    media === 'phone'
                                        ? page >= data.length - 1
                                        : page >= data.length - 2
                                }
                                type="next"
                                onClick={() => {
                                    if (swiper) {
                                        swiper.slideNext();
                                    }
                                }}
                            />
                        </>
                    )}
                    {((media === 'phone' && data.length > 1) ||
                        (media === 'desktop' && data.length > 3)) && (
                        <Progress
                            className={styles.progress}
                            value={page + 1}
                            max={media === 'phone' ? data.length : data.length - 2}
                        />
                    )}
                </div>
            )}
            {view === 'form' && (
                <div className={classnames(styles.content, styles[view])}>
                    <div className={styles.section}>
                        <div className={styles.label}>收件人資訊</div>
                        <input
                            className={styles.name}
                            type="text"
                            placeholder="收件人"
                            value={formName}
                            onChange={(event) => {
                                setFormName(event.target.value);
                            }}
                        />
                        <input
                            className={styles.phone}
                            type="tel"
                            placeholder="聯繫電話"
                            value={formPhone}
                            onChange={(event) => {
                                setFormPhone(event.target.value);
                            }}
                        />
                        <input
                            className={styles.email}
                            type="text"
                            placeholder="E-mail"
                            value={formEmail}
                            onChange={(event) => {
                                setFormEmail(event.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.section}>
                        <div className={styles.label}>地址</div>
                        <input
                            className={styles.city}
                            type="text"
                            placeholder="縣市"
                            value={formCity}
                            onChange={(event) => {
                                setFormCity(event.target.value);
                            }}
                        />
                        <input
                            className={styles.dist}
                            type="text"
                            placeholder="鄉鎮市區"
                            value={formDist}
                            onChange={(event) => {
                                setFormDist(event.target.value);
                            }}
                        />
                        <input
                            className={styles.address}
                            type="text"
                            placeholder="詳細地址"
                            value={formAddress}
                            onChange={(event) => {
                                setFormAddress(event.target.value);
                            }}
                        />
                    </div>
                    <div className={classnames(styles.section, styles.checkGroup)}>
                        <div
                            className={classnames(formConfirm && styles.active, styles.checkbox)}
                            onClick={() => {
                                setFormConfirm(!formConfirm);
                            }}
                            role="button"
                            onKeyPress={() => {}}
                            tabIndex={0}
                        >
                            {formConfirm && (
                                <div className={styles.inner}>
                                    <Check />
                                </div>
                            )}
                        </div>
                        <div className={styles.text}>
                            我同意親子天下{' '}
                            <a
                                className={styles.link}
                                href="https://cplink.co/Ai2pK5sk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                隱私權同意書
                            </a>{' '}
                            及相關告知事項，及本活動{' '}
                            <span
                                className={styles.link}
                                onClick={() => {
                                    onChangeView('info');
                                }}
                                role="button"
                                onKeyPress={() => {}}
                                tabIndex={0}
                            >
                                領獎相關說明
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {view === 'info' && (
                <div className={classnames(styles.content, styles[view])}>
                    · 本活動之獎品以寄出的實物為準，圖片僅供參考。獎項寄送地址僅限臺澎金馬地區。
                    <br />
                    <br />
                    •若因得獎者未能提供正確的收件資料，致獎項無法寄達而退回時，主辦單位將不另行補寄或提供其他補償。
                    <br />
                    <br />· 凡參與本活動之得獎者，請於得獎後 三天內 填寫
                    <a
                        className={styles.link}
                        href="https://cplink.co/aYsLJ6w4"
                        target="_blank"
                        rel="noreferrer"
                    >
                        「得獎同意書」
                    </a>
                    並檢附身分證正反面影本，供本公司後續辦理中獎所得扣繳作業，經確認同意書內容無誤後，將統一於2022/1/15前寄出獎項。
                    <br />
                    <br />·
                    得獎者個人資料僅供本活動獎項寄送及所得扣繳作業使用，主辦單位不會洩漏、出售得獎者的個人資料給他人。
                </div>
            )}
            {view === 'complete' && (
                <div className={classnames(styles.content, styles[view])}>
                    <div className={styles.resultSection}>
                        <div className={styles.label}>收件人資訊</div>
                        <div className={styles.resultItem}>收件人：{formName}</div>
                        <div className={styles.resultItem}>聯繫電話：{formPhone}</div>
                        <div className={styles.resultItem}>Email：{formEmail}</div>
                    </div>
                    <div className={styles.resultSection}>
                        <div className={styles.label}>地址</div>
                        <div className={styles.resultItem}>
                            {formCity} {formDist}
                        </div>
                        <div className={styles.resultItem}>{formAddress}</div>
                    </div>
                </div>
            )}
            {/* <div className={styles.btnWrapper}>
                <Button3D
                    className={styles.btn}
                    disabled={
                        view === 'form'
                            ? !(
                                  formName &&
                                  formPhone &&
                                  formEmail &&
                                  formCity &&
                                  formDist &&
                                  formAddress &&
                                  formConfirm
                              )
                            : false
                    }
                    onClick={() => {
                        if (view === 'bag') {
                            onChangeView('form');
                        }

                        if (view === 'info') {
                            onChangeView('form');
                        }

                        if (view === 'form') {
                            onChangeView('complete');
                        }

                        if (view === 'complete') {
                            onSubmit({
                                name: formName,
                                phone: formPhone,
                                email: formEmail,
                                dist: formDist,
                                city: formCity,
                                postalCode: '000',
                                address: formAddress,
                            });
                            // setActive(false);
                        }
                    }}
                >
                    {view === 'bag' && shippingInfo.name && '修改收件資料'}
                    {view === 'bag' && !shippingInfo.name && '填寫收件資訊'}
                    {view === 'form' && '完成'}
                    {view === 'info' && '返回'}
                    {view === 'complete' && '儲存'}
                </Button3D>
            </div> */}
        </ModalBaseCenter>
    );
};

export interface ModalClosetProp {
    className?: string;
    onCloseFinish?: () => void;
}

const ModalCloset: React.FC<ModalClosetProp> = ({ className, onCloseFinish }) => {
    const [, { getUserData }] = useUserData();
    const [{ gifts, bagView, shippingInfo }, { updateBagView, updateShippingInfo }] = useBag();

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <ModalBagLayout
            className={className}
            onCloseFinish={onCloseFinish}
            data={gifts}
            view={bagView}
            shippingInfo={shippingInfo}
            onChangeView={(nextView) => {
                updateBagView(nextView);
            }}
            onSubmit={(data) => {
                updateShippingInfo(data);
            }}
        />
    );
};

export default ModalCloset;
