import classnames from 'classnames';
import { useState } from 'react';

import { characterData, themeData } from 'data/character';

import type { CharacterType, ThemeType } from 'data/character';

import LikeFilled from 'images/icons/heart-filled.inline.svg';
import LikeOutline from 'images/icons/heart-outline.inline.svg';

import { useUserData } from 'models/user';
import { useAuth } from 'models/auth';
import { useFadeIn } from 'utils/hooks';
import { useModal } from 'models/modal';
import { useMessages } from 'models/messages';

import styles from './index.css';

export interface MessageCardProp {
    className?: string;
    messageId: string;
    characterType: CharacterType | ThemeType;
    name: string;
    content: string;
    time: string;
    likes: number;
    liked?: boolean;
    onLiked?: (nextLiked: boolean) => void;
    onClick?: () => void;
}

const MessageCard: React.FC<MessageCardProp> = ({
    className,
    messageId,
    characterType,
    name,
    content,
    time,
    likes = 0,
    liked,
    onLiked = () => {},
    onClick = () => {},
}) => {
    const [{ loginType }] = useAuth();
    const [, { openModal, closeModal }] = useModal();
    const [, { likeMessage }] = useMessages();

    return (
        <div
            className={classnames(styles.messageCard, className)}
            role="presentation"
            onClick={() => {
                openModal({
                    type: 'messagesDetail',
                    data: {
                        messageId,
                    },
                });
            }}
        >
            <div
                className={classnames(styles.likeGroup, liked && styles.liked)}
                tabIndex={0}
                role="button"
                onKeyPress={() => {}}
                onClick={(event) => {
                    likeMessage({ messageId, like: liked });
                    // onLiked(!liked);
                    event.stopPropagation();
                }}
            >
                <LikeOutline />
                {liked && <div className={styles.count}>{likes}</div>}
            </div>
            <div className={styles.top}>
                <div className={styles.avatar}>
                    <img
                        src={
                            characterType < 2800
                                ? characterData[characterType].avatar
                                : themeData[characterType].avatar
                        }
                        alt={`character${characterData[characterType]}.id`}
                    />
                </div>
                <div className={styles.text}>
                    <div className={styles.titleGroup}>
                        <div className={styles.character}>
                            {characterType < 2800
                                ? characterData[characterType].name
                                : themeData[characterType].name}
                        </div>
                        <div className={styles.name}>{name}</div>
                    </div>
                    <div className={styles.time}>{time}</div>
                </div>
            </div>
            <div className={styles.content}>{`${content} `}</div>
            {/* <div className={styles.more}>(完整閱讀)</div> */}
        </div>
    );
};

export default MessageCard;
